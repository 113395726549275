import React, { useState } from 'react';
import { Container, Grid, List, Segment } from 'semantic-ui-react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import i18n from '@/i18n';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';



const Footer = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [selectedLang, setSelectedLang] = useState<any>(process.env.REACT_APP_DEFAULT_LANGUAGE ? process.env.REACT_APP_DEFAULT_LANGUAGE : 'en');
	const currentYear = new Date().getFullYear().toString();
	const location = useLocation();
	const history = useHistory();

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const linkProps = !user ? {
		as: NavLink
	} : {
		exact: true,
		as: NavLink
	};

	const baseLink = '/legal';

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		setSelectedLang(lng);
		if (location.pathname === '/support') {
			history.push('/');
		}
	};

	return (
		<div id="footer">
			<Container>
				<Segment padded basic className="footer" >
					<Grid stackable columns='equal'>
						<Grid.Row>
							<Grid.Column className="privacyfooter">
								<List bulleted>
									<List.Item to={`${baseLink}/privacypolicy`} {...linkProps} >{t('footer.privacy')}</List.Item>
								</List>
							</Grid.Column>
							<Grid.Column className="termsfooter">
								<List bulleted>
									<List.Item to={`${baseLink}/terms`} {...linkProps}>{t('footer.terms')}</List.Item>
								</List>
							</Grid.Column>
							<Grid.Column className="amlfooter">
								<List bulleted>
									<List.Item to={`${baseLink}/amlpolicy`} {...linkProps}>{t('footer.aml')}</List.Item>
								</List>
							</Grid.Column>
							{user &&
								<Grid.Column className="supportfooter">
									<List bulleted>
										<List.Item to={'/support'} as={NavLink}>{t('footer.support')}</List.Item>
									</List>
								</Grid.Column>
							}
							<Grid.Column className="switcher">
								<List horizontal divided>
									<List.Item onClick={() => changeLanguage('it')} className={selectedLang === 'it' ? 'active' : ''}><i className="it flag"></i></List.Item>
									<List.Item onClick={() => changeLanguage('en')} className={selectedLang === 'en' ? 'active' : ''}><i className="uk flag"></i></List.Item>
								</List>
							</Grid.Column>

							<Grid.Column floated="right" width={4}>
								<List.Item className="footercopyright">{t('footer.copyright', { year: currentYear })}</List.Item>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</Container>
		</div>
	);
};
export default Footer;
