import React, { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import './Profile.css';
import {
	Segment,
	Container,
	Divider,
	Header,
	Grid,
	Button,
} from 'semantic-ui-react';
import { deleteSecrets, generateSecrets, getSecrets } from '@/features/security/slice';
import SecretList from './SecretList';
import { showException } from '@/features/swal/slice';
import SecretKeyLabel from './Profile/SecretKeyLabel';

const SekretKeys = (): React.ReactElement => {
	const { status } = useSelector(
		(state: RootState) => state.user
	);
	const { secrets } = useSelector(
		(state: RootState) => state.profile
	);
	const dispatch = useDispatch();
	const [enterSecretLabel, setEnterSecretLabel] = useState<boolean>(false);

	useEffect(() => {
		fetchSecrets();
	}, [dispatch]);

	const fetchSecrets = async () => {
		try {
			await dispatch(getSecrets());
		} catch (e) {
			showException(e);
		}
	};

	const generate = async (label: string) => {
		await generateSecrets(label);
		fetchSecrets();
	};

	const removeSecretKey = async (id: number) => {
		await deleteSecrets(id);
		fetchSecrets();
	};

	const onCloseEnterSecretLabelModal = async (label?: string) => {
		setEnterSecretLabel(false);
		if (label) {
			try {
				await generate(label);
			}
			catch (e) {
				showException(e);
			}
		}
	};



	return (
		<Container>
			<SecretKeyLabel
				onClose={onCloseEnterSecretLabelModal}
				open={enterSecretLabel}
			/>
			<Segment className="profilesegment" padded>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header className="profileHeader" >API secrets</Header>
						</Grid.Column>
						<Grid.Column>
							<Button floated='right' primary onClick={() => setEnterSecretLabel(true)}>Generate New Secret Keys</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Divider hidden />
				<Grid.Column >
					<SecretList secrets={secrets} onRemove={removeSecretKey} serverAgent={status.wlpId} />
				</Grid.Column>
			</Segment>
			<Divider hidden />
		</Container >
	);
};

export default SekretKeys;
