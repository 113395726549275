import React from 'react';
import { Container, Divider, Header, List, Message, Segment } from 'semantic-ui-react';
import { CopyBlock, dracula } from 'react-code-blocks';

const ApiAuthDocs = (): React.ReactElement => {

	const signatureCodeBlock = `private createSignature(method, path, body) {
        const ts = Math.floor(Date.now() / 1000);
        const signature = crypto.createHmac('sha256', 'SECRET');
        signature.update(ts + method.toUpperCase() + path);

        if (body !== null) {
            signature.update(JSON.stringify(body));
        }

        return {
            ts, signature
        }
}

async callApi(method, path, body) {
	let config = this.createSignature(method, path, body)
	let options: any = {
			uri: URL + path,
			method: method,
			json: true,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'x-app-access-token': 'TOKEN',
				'x-app-access-ts': config.ts,
				'x-app-access-sig': config.signature.digest('hex').
			},
		};

		if (body !== null) {
			options = { ...options, body }
		}
}`;

	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Authentications</Header>
				<Divider hidden />
				<Divider hidden />
				<Segment>
					<Header as='h4'>Making a request</Header>
					<p>All requests must contain the following headers:</p>
					<List bulleted>
						<List.Item><code className='greyBackground'>x-app-access-token</code> - an App Token provided by Noacco</List.Item>
						<List.Item><code className='greyBackground'>x-app-access-sig</code> - signature of the request in the hex format and <strong>lowercase</strong> (see below)</List.Item>
						<List.Item><code className='greyBackground'>x-app-access-ts</code> - number of seconds since Unix Epoch in UTC</List.Item>
					</List>
					<Message
						icon='info circle'
						content='Your timestamp must be within 1 minute of the API server time. Make sure the time on your servers is correct.'
					/>
				</Segment>
				<Divider hidden />
				<Segment>
					<Header as='h4'>Signing a request</Header>
					<p>The value of the <code className='greyBackground'>x-app-access-sig</code> is generated by a sha256 HMAC algorithm using a secret key (provided upon App Token generation) on the bytes obtained by concatenating the following information:</p>
					<List bulleted>
						<List.Item>A timestamp (value of the <code className='greyBackground'>x-app-access-ts</code> header) taken as a string</List.Item>
						<List.Item>An HTTP method name in upper-case, e.g. <code className='greyBackground'>GET</code> or <code className='greyBackground'>POST</code></List.Item>
						<List.Item>URI of the request without a host name, starting with a slash and including all query parameters, e.g. <code className='greyBackground'>/api/client/user/create/individual</code></List.Item>
						<List.Item>Request body, taken exactly as it will be sent. If there is no request body, e.g., for <code className='greyBackground'>GET</code> requests, don&apos;t include it.</List.Item>
					</List>
				</Segment>
				<Divider hidden />
				<Segment>
					<Header as='h4'>Signature example</Header>
					<CopyBlock
						language='typescript'
						text={signatureCodeBlock}
						theme={dracula}
						wrapLines={true}
						codeBlock
					/>
				</Segment>
			</Segment>
		</Container>
	);
};


export default ApiAuthDocs;
