/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import request from '../../services/request';
import { UserType } from '../user/types';

export const getClientStatistics = async (type: UserType) => {
	const response = await request.get(`/api-portal/statistics/clients/${type}`);
	const { data } = response;
	return data;
};

export const getAccountsStatistics = async () => {
	const response = await request.get('/api-portal/statistics/accounts');
	const { data } = response;
	return data;
};

export const getCardsStatistics = async () => {
	const response = await request.get('/api-portal/statistics/cards');
	const { data } = response;
	return data;
};

export const getTransactionsStatistics = async () => {
	const response = await request.get('/api-portal/statistics/transactions');
	const { data } = response;
	return data;
};
