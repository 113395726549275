import React, { useState } from 'react';
import { RootState } from '@/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import './Profile.css';
import {
	Grid,
	Card,
	Segment,
	Button,
	List,
	Container,
	Header,
	Divider
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import { formatDate, formatDateWithPattern } from '@helpers/date';
import { updateUser } from '@features/user/userSlice';
import { changePhone } from '@features/security/slice';
import { showException } from '@features/swal/slice';
import EditMobile from './Profile/EditMobile';
import KYCForm from '@/components/KYC/KYCForm';

type Props = {
	kycTierVerified: boolean
}


const Profile = ({kycTierVerified} : Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [openKyc, setOpenKyc] = useState<boolean>(false);
	const [countries] = useState(getNames('en'));
	const [editPhone, setEditPhone] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [openKycForm, setOpenKycForm] = useState<boolean>(false);
	const { user } = useSelector(
		(state: RootState) => state.user
	);



	const onCloseEditMobileModal = async (mobileNumber?: string) => {
		setEditPhone(false);
		if (mobileNumber) {
			try {
				await changePhone(mobileNumber);
				dispatch(updateUser({ mobileNumber }));
			}
			catch (e) {
				showException(e);
			}
		}
	};

	return (
		<Container>
			{openKyc && <KYCForm open={openKyc} setOpen={setOpenKyc} />}
			<EditMobile
				onClose={onCloseEditMobileModal}
				open={editPhone}
			/>
			<Segment className="profilesegment" padded>
				<Grid stackable columns={2}  >
					<Grid.Column className="userdatacolumn">
						<Header className="profileHeader" >{t('profile.header')}</Header>
						<Card id="profile-card">
							<Card.Content>
								{user && user.onBehalfOf !== null && <Card.Header>{user.firstName} {user.lastName}</Card.Header>}
								{user && user.onBehalfOf === null && <Card.Header>{user.companyName}</Card.Header>}
							</Card.Content>
							<Divider hidden />
							<Card.Content>
								<List>
									<List.Item >
										<List.Content floated='right' className={user.disabled ? 'color-red' : 'color-green'}>{user.disabled ? t('profile.activedata.inactive') : t('profile.activedata.active')}</List.Content>
										<List.Content>{t('profile.data.status')}</List.Content>
									</List.Item>
									<List.Item>
										<List.Content floated='right'>{user.clientId}</List.Content>
										<List.Content>{t('profile.data.userid')}</List.Content>
									</List.Item>
									<List.Item>
										<List.Content floated='right' className={user.emailConfirmed ? 'color-green' : 'color-red'}>{user.emailConfirmed ? t('profile.activedata.verified') : t('profile.activedata.notverified')}</List.Content>
										<List.Content>{t('profile.data.email')}</List.Content>
									</List.Item>

									{/* <List.Item>
										<List.Content floated='right' className={!user.recoveryEmailConfirmed ? 'color-red' : ''}>{user.recoveryEmail ? user.recoveryEmail : t('profile.activedata.inactive')}</List.Content>
										<List.Content>{t('profile.data.recoveryemail')}</List.Content>
									</List.Item> */}

									<List.Item>
										<List.Content floated='right'>{formatDate(user.createdDate)}</List.Content>
										<List.Content>{t('profile.data.registrationdate')}</List.Content>
									</List.Item>
									<List.Item>
										<List.Content floated='right'>{formatDate(user.previousLoginDate)}</List.Content>
										<List.Content>{t('profile.data.previouslogin')}</List.Content>
									</List.Item>
								</List>
							</Card.Content>
						</Card>
					</Grid.Column>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								{user && user.onBehalfOf !== null && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.name')}</List.Header>
											<List.Description>{user.firstName} {user.lastName}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.birthdate')}</List.Header>
											<List.Description>{formatDateWithPattern(user.birthDate, 'dd/MM/yyyy')}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.country')}</List.Header>
											<List.Description>{countries[alpha3ToAlpha2(user.country)]}</List.Description>
										</List.Content>
									</List.Item>
									<Divider /></div>}
								{user && user.onBehalfOf === null && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.companyName')}</List.Header>
											<List.Description>{user.companyName}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.regNumber')}</List.Header>
											<List.Description>{user.companyRegNumber}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.companyAddress')}</List.Header>
											<List.Description>{user.companyAddress}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.companyCountry')}</List.Header>
											<List.Description>{user.companyCountry}</List.Description>
										</List.Content>
									</List.Item>
									<Divider /></div>}
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">{t('profile.data.email')}</List.Header>
										<List.Description>{user.email}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">{t('profile.data.phonenumber')}</List.Header>
										<List.Description>{user.mobileNumber}	<Button onClick={() => { setEditPhone(true); }} size="small" primary basic icon='edit' className="link-button" /></List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">{t('profile.data.businessVerification')}</List.Header>
										<List.Description>
											{kycTierVerified && <div className="color-green verification-label">Verified</div>}
											{!kycTierVerified && <a className="profileLink" onClick={() => setOpenKycForm(true)}>Verify KYB</a>}
										</List.Description>
									</List.Content>
								</List.Item>
							</List>
						</Segment>
					</Grid.Column>
				</Grid>
				{openKycForm && <KYCForm open={openKycForm} setOpen={setOpenKycForm} />}
			</Segment>
			<Divider hidden />
		</Container >
	);
};

export default Profile;
