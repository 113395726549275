import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import isEmpty from 'is-empty';
import {
	Grid,
	Header,
	Segment,
	Divider,
	Container,
	Image
} from 'semantic-ui-react';
import logo from '@assets/registrationLogo.svg';
import './Login.css';
import { resetPassword, logout } from '../../features/user/userSlice';
import { showInfo, showSuccess, showException } from '../../features/swal/slice';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface ParamTypes {
	code: string
}

const ForgotPassword = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const history = useHistory();
	const { code } = useParams<ParamTypes>();
	const dispatch = useDispatch();

	const initialValues = {
		password: '',
		confirmPassword: ''
	};

	const validationSchema = Yup.object({
		password: Yup.string()
			.min(8, 'Must be at least 8 characters long')
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])/,
				'Must contain uppercase, lowercase and numbers'
			)
			.required(t('form.validator.required')),
		confirmPassword: Yup.string().when('password', {
			is: val => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
				[Yup.ref('password')],
				'Both password need to be the same'
			)
		})
	});

	const { error } = useSelector(
		(state: RootState) => state.user
	);

	const { regionRestricted, registrationDisabledReason, registrationEnabled } = useSelector(
		(state: RootState) => state.status
	);

	const onRegistrationClick = (e) => {
		if (!registrationEnabled) {
			e.preventDefault();
			showInfo({ title: 'swal.REGISTRATION_DISABLED_TITLE', text: 'swal.REGISTRATION_DISABLED_TEXT', textParams: { param_value: registrationDisabledReason, timer: null } });
		}
		if (regionRestricted) {
			e.preventDefault();
			showInfo({ title: 'swal.REGION_RESTRICTED_TITLE', text: 'swal.REGION_RESTRICTED_TEXT' });
		}
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { password } = formData;
			await dispatch(resetPassword({ password, code }));
			await dispatch(logout());
			showSuccess({ title: 'swal.PASSWORD_RESET', text: 'swal.PASSWORD_RESET_TEXT' });
			history.push('/auth');
		}
		catch (e) {
			setSubmitting(false);
			showException(error);
		}
	};

	return (
		<Container fluid className="dark content-wrapper">
			<Grid id="login" verticalAlign="middle" centered stackable divided="vertically">
				<Grid.Column>
					<Segment>
						<Segment basic>
							<Image src={logo} />
							<div className="ui hidden divider"></div>
							<Header size="large">
								<Divider fitted hidden />
								<span className="login-content">{t('resetPassword.title')}</span>
							</Header>
						</Segment>
						<Segment basic>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={submit}
							>
								{({ errors, isSubmitting, dirty }) => (
									<Form
										size="large">
										<Input
											fluid
											name="password"
											placeholder={t('form.placeholder.password')}
											errorPrompt
										/>
										<Input
											fluid
											name="confirmPassword"
											placeholder={t('form.placeholder.confirmpassword')}
											errorPrompt
										/>
										<SubmitButton
											disabled={isSubmitting || !isEmpty(errors) || !dirty}
											primary size="large" type="submit">
											{t('resetPassword.submit')}
										</SubmitButton>
									</Form>)}
							</Formik>
						</Segment>
						<Segment basic>
							<Grid columns={2}>
								<Grid.Column>
									<Link to="/auth">{t('registration.links.login')}</Link>
								</Grid.Column>
								<Grid.Column textAlign="right">
									<Link to="/auth/signup" onClick={onRegistrationClick}>{t('login.register')}</Link>
								</Grid.Column>
							</Grid>
						</Segment>
					</Segment>
				</Grid.Column>
			</Grid>
		</Container>
	);
};

export default ForgotPassword;
