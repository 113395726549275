import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import { HomeIcon, InboxIcon, SupportIcon, UserIcon } from '@services/icons';
import NavbarLogo from './NavbarLogo';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { logout } from '@features/user/userSlice';
import { showException } from '@features/swal/slice';
import { RootState } from '@/rootReducer';



const Navbar = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const signout = async () => {
		try {
			await dispatch(logout());
			return history.push('/auth');
		}
		catch (e) {
			showException(e);
		}
	};

	return (
		<Menu id="navbar" borderless stackable>
			<div className='mobileNavbarLogo'>
				<Menu.Item header>
					<Icon as={NavLink} to="/" className="logo">
						<NavbarLogo />
					</Icon>
				</Menu.Item>
			</div>
			{status && status.loggedIn && (<Menu.Menu position="right">
				<Menu.Item className="navbaritem" name="home" as={NavLink} to="/dashboard" >
					<HomeIcon width="24px" height="24px" />
					{t('navbar.home')}
				</Menu.Item>
				<Menu.Item className="navbaritem" as={NavLink} to="/inbox" name="register" >
					<InboxIcon width="24px" height="24px" />
					{t('navbar.inbox')}
				</Menu.Item>
				<Menu.Item className="navbaritem" as={NavLink} to="/support" name="register">
					<SupportIcon width="24px" height="24px" />
					{t('navbar.support')}
				</Menu.Item>
				<Dropdown className="navbarDropdown" item trigger={(
					<Menu.Item className="navbaritem" >
						<UserIcon /> {t('navbar.account.title')}
					</Menu.Item>)}>
					<Dropdown.Menu>
						<Dropdown.Item as={NavLink} to="/account">{t('navbar.account.details')}</Dropdown.Item>
						<Dropdown.Item onClick={() => signout()}>{t('navbar.account.signout')}</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Menu>)}

		</Menu>
	);
};

export default Navbar;
