import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Divider, Header, Icon, Item, Segment } from 'semantic-ui-react';
import { showException } from '@features/swal/slice';
import { downloadAttachment } from '@features/tickets/slice';
import { Ticket, TicketAttachment, TicketType } from '@features/tickets/types';
import { convertDate } from '@helpers/date';

interface Props {
	message: Ticket
}

const Message = ({ message }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const download = (id: number, attachment: TicketAttachment) => {
		const get = async () => {
			try {
				downloadAttachment(id, attachment);
			} catch (e) {
				showException(e);
			}
		};
		get();
	};

	return (<div id="messageDetails">
		<Container>
			<Header as="h2" content={message.title} />
			{message.ticketMessages.map((item, index) =>
				<Segment key={index}>
					<Item.Group >
						<Item className="message" >
							<Item.Content className="messageContent">
								<Item.Header className="author" content={item.author} />
								<Item.Meta className="content" content={convertDate(item.createdDate, 'dd MMM yyyy, HH:mm')} />
								<Divider />
								<Item.Description className="content" dangerouslySetInnerHTML={{ __html: item.message }} />
								<Divider hidden />
								{message.incidentType === TicketType.INCIDENT && item.authorYou === false && (
									<Button content={t('inbox.reply')} icon="reply" secondary />
								)}
								<Divider hidden/>
								<b>Attachments:</b>
								<Divider hidden />
								{item.ticketAttachments.map(attachment =>
									<>
										<Button basic onClick={() => download(message.id, attachment)} key={attachment.key} ><Icon size="large" name="file outline" />{attachment.name}</Button>
										<br/>
									</>
								)}
							</Item.Content>
						</Item>
					</Item.Group>
				</Segment>
			)}
		</Container>
	</div>);
};



export default Message;
