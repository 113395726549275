import React, { useEffect, useState } from 'react';
import { Container, Header } from 'semantic-ui-react';
import Profile from './MyAccount/Profile';
import '../Dashboard/MyAccount/ProfileTable.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import TeamMembers from './MyAccount/TeamMembers';
import SecretKeys from './MyAccount/SecretKeys';
import ChangePassword from './MyAccount/ChangePassword';
import Webhooks from './MyAccount/Webhooks';
import LoadingPage from '@/pages/LoadingPage';

const Account = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const [kycTierVerified, setKycTierVerified] = useState<boolean>(false);
	const { user, status } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (!user) return;
		const kycTier = user.kycTier || null;
		setKycTierVerified((kycTier >= 2));
	}, [user]);

	if (!user) return <LoadingPage />;
	return (
		<Container>
			<Header className='myAccountHeader'>{t('navbar.account.details')}</Header>
			<Profile kycTierVerified={kycTierVerified} />
			{kycTierVerified && status.wlpId && <TeamMembers />}
			{kycTierVerified && status.wlpId && <SecretKeys />}
			{kycTierVerified && status.wlpId && <Webhooks />}
			<ChangePassword />
		</Container>
	);
};

export default Account;
