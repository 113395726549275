import { showException } from '@/features/swal/slice';
import { Formik } from 'formik';
import { Form, Input, ResetButton, Select, SubmitButton } from 'formik-semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import './Table.css';

export interface SelectOption {
	key: string | number,
	text: string,
	value: string
}

export interface FilterParam {
	field: string,
	label: string,
	type: 'input' | 'select',
	options?: SelectOption[],
	inputType?: string,
}

interface Props {
	filter: FilterParam[],
	handleFilterChange: (filterString: string, clear: boolean) => void
}
const TableFilters = ({ filter, handleFilterChange }: Props): React.ReactElement => {
	const [initialValues, setInitialValues] = useState<any>(null);


	useEffect(() => {
		const initValues = {};
		filter.map(entry => {
			initValues[entry.field] = '';
		});
		setInitialValues(initValues);
	}, []);

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			let filterString = '';
			Object.entries(formData).map(([key, value]) => {
				if (value !== '') {

					if (filterString !== '') {
						filterString += ';';
					}

					filterString = filterString.concat(`${key}=${value.toString().trim()}`);
				}
			});
			if (filterString !== '') {
				handleFilterChange(filterString, false);
			} else {
				handleFilterChange(null, true);
			}
		} catch (err) {
			showException(err);
		} finally {
			setSubmitting(false);
		}
	};

		

	const handleFilterField = (entry) => {
		if (entry.type === 'input') {
			return (
				<Input
					class='field'
					label={entry.label}
					name={entry.field}
					type={entry.inputType ? entry.inputType : ''}
				/>);
		} else if (entry.type === 'select') {
			return (
				<Select
					class='field'
					name={entry.field}
					label={entry.label}
					options={entry.options}
					clearable
					selection
					search
				/>
			);
		}
	};

	return (
		<div>
			<Formik
				initialValues={initialValues}
				onSubmit={submit}
				enableReinitialize
			>{({ isSubmitting }) => (
					<Form >
						<Grid id='filters'>
							<Grid.Row>
								{filter.map(entry =>
									handleFilterField(entry)
								)}
							</Grid.Row>
							<Grid.Row id='buttons'>
								<SubmitButton type='submit'
									disabled={isSubmitting}
									primary>Search</SubmitButton>
								<ResetButton basic onClick={() => handleFilterChange(null, true)} type='reset'>Clear</ResetButton>
							</Grid.Row>
						</Grid>

					</Form>
				)}
			</Formik>
		</div >
	);

};

export default TableFilters;
