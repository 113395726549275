import { showException } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { downloadInvoice, getInvoices } from '@/features/invoices/slice';
import { Invoice } from '@/features/invoices/types';
import NumberFormat from 'react-number-format';
import { formatDateWithPattern } from '@/helpers/date';
import './Invoices.css';


const Invoices = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.invoices
	);

	const paginateInitialState: PaginateParams = { orderBy: 'payBefore', sort: 'DESC', skip: 0, filter: '', take: 10 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);

	const headers: Array<Headers> = [
		{
			field: 'number',
			name: 'Invoice No.',
			position: 1,
			sortable: false
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 2,
			sortable: true
		},
		{
			field: 'paybefore',
			name: 'Pay Before',
			position: 3,
			sortable: false
		},
		{
			field: 'paid',
			name: 'Status',
			position: 4,
			sortable: false
		},
		{
			field: '',
			name: 'Download',
			position: 5,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getInvoices(pagination));
			} catch (e) {
				showException(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const download = (invoice: Invoice) => {
		const get = async () => {
			try {
				downloadInvoice(invoice);
			} catch (e) {
				showException(e);
			}
		};
		get();
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry) => {
				return (
					<Table.Row key={`${entry.number}$`} >
						<Table.Cell width={3}>{entry.number}</Table.Cell>
						<Table.Cell width={3}><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} /></Table.Cell>
						<Table.Cell width={3}>{formatDateWithPattern(entry.paybefore, 'dd/MM/yyyy')}</Table.Cell>
						<Table.Cell width={3}>{entry.paid ? 'Paid' : 'Unpaid'}</Table.Cell>
						<Table.Cell width={1}><Icon className='pdfIcon' size="large" name="file pdf outline" onClick={() => download(entry)} /></Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>You dont have invoices yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Invoices</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					hideItemsPerPage={true}
				/>
			</Segment>
		</Container>
	);
};


export default Invoices;
