import { SocketService } from '@/services/socketService';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Subscription } from 'rxjs/internal/Subscription';
import request from '../../services/request';
import { ChangePasswordPayload, ProfileStore, Secret, TeamMember, Webhook } from './types';


const initialState: ProfileStore = {
	loading: true,
	secrets: [],
	teamMembers: [],
	webhooks: [],
	error: null
};

const slice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setSecrets(state, action: PayloadAction<Array<Secret>>) {
			state.secrets = action.payload;
		},
		setTeamMembers(state, action: PayloadAction<Array<TeamMember>>) {
			state.teamMembers = action.payload;
		},
		setWebhooks(state, action: PayloadAction<Array<Webhook>>) {
			state.webhooks = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		}
	}
});

export const { setSecrets, setTeamMembers, setLoading, setError, setWebhooks } = slice.actions;


export const changePhone = async (newPhoneNumber: string) => {
	const response = await request.post('/api-portal/user/change-phone', { newPhoneNumber });
	const { data } = await response;
	return data;
};

export const changePassword = async (payload: ChangePasswordPayload) => {
	const response = await request.post('/api-portal/user/change-password', payload);
	const { data } = await response;
	return data;
};

export const generateSecrets = async (label: string) => {
	const response = await request.post('/api-portal/user/secrets', {label});
	const { data } = await response;
	return data;
};

export const inviteTeamMember = async (email: string, role = 'DEVELOPER') => {
	const response = await request.post('/api-portal/user/team-member', {email, role});
	const { data } = await response;
	return data;
};

export const deleteTeamMember = async (developerId: number, email: string) => {
	const response = await request.post('/api-portal/user/team-member/delete', {developerId, email});
	const { data } = await response;
	return data;
};

export const revokeTeamMember = async (developerId: number) => {
	const response = await request.get(`/api-portal/user/team-member/revoke/${developerId}`);
	const { data } = await response;
	return data;
};

export const addWebhook = async (url: string, secret: string) => {
	const response = await request.post('/api-portal/webhook', {url, secret});
	const { data } = await response;
	return data;
};

export const putWebhook = async (uuid: string, url: string, secret: string) => {
	const response = await request.put(`/api-portal/webhook/${uuid}`, {url, secret});
	const { data } = await response;
	return data;
};

export const deleteWebhook = async (uuid: string) => {
	const response = await request.delete(`/api-portal/webhook/${uuid}`);
	const { data } = await response;
	return data;
};

export const getSecrets = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api-portal/user/secrets');
			const { data } = response;
			dispatch(setSecrets(data));
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getTeamMembers = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api-portal/user/team-member');
			const { data } = response;
			dispatch(setTeamMembers(data));
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getWebhooks = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api-portal/webhook');
			const { data } = response;
			dispatch(setWebhooks(data));
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const deleteSecrets = async (id: number) => {
	const response = await request.delete(`/api-portal/user/secrets/${id}`);
	const { data } = await response;
	return data;
};

let socketService: SocketService;
let teamMemberSubscriber: Subscription;

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('team-member');
	}
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		try {
			teamMemberSubscriber = socketService.listen('team-member', {}).subscribe(() => {
				dispatch(getTeamMembers());
			});
			
		} catch (e) {
			console.log(e);
			dispatch(setError(e));
		}
	};
};

export const unsubscribe = (): void => {
	teamMemberSubscriber.unsubscribe();
};


export default slice.reducer;
