export interface Status {
	loggedIn: boolean,
	source: string,
	wlpId: string,
	teamMember: boolean,
	sumsubUrl: string,
	sumsubFlow: string
}

export interface MaintenanceMode {
	mode: boolean, reason: string
}

export enum KycStatus {
	CREATED = 'CREATED',
	PENDING = 'PENDING',
	VERIFIED = 'VERIFIED',
	IN_PROGRESS = 'IN_PROGRESS',
	REJECTED = 'REJECTED',
	PAID = 'PAID'
}

export class KycTier {
	tier: number;
	status: KycStatus;
	createdDate: Date;
	updatedDate: Date;
	rejectionReason: string;
	final: boolean;
}

export class BankAccount {
	id?: number;
	refNumber?: string;
	status?: string;
	accountNumber?: string;
	iban?: string;
	bankName?: string;
	bankAddress?: string;
	sortCode?: string;
	swiftBicCode?: string;
	createdDate?: Date;
	updatedDate?: Date;
}

export enum UserType {
	INDIVIDUAL = 'INDIVIDUAL',
	EXTERNAL_INDIVIDUAL = 'EXTERNAL_INDIVIDUAL',
	EXTERNAL_BUSINESS = 'EXTERNAL_BUSINESS',
	BUSINESS = 'BUSINESS',
	EMPLOYEE = 'EMPLOYEE'
}

export interface User {
	id: number;
	email: string;
	kycVerificationStatus?: number;
	emailConfirmed?: boolean;
	emailConfirmationResent?: boolean;
	kycTier?: number;
	disabled?: boolean;
	createdDate?: string;
	previousLoginDate?: string;
	// User profile data
	username?: string;
	firstName?: string;
	lastName?: string;
	birthDate?: string;
	address?: string;
	address2?: string;
	city?: string;
	country?: string;
	state?: string;
	postalCode?: string;
	mobileNumber?: string;
	kyc?: KycTier[];
	clientId?: string;
	companyName?: string;
	companyRegNumber?: string;
	companyAddress?: string;
	companyCountry?: string;
	onBehalfOf: string;
	onBehalfOfCompany: string;
	detained: boolean;
	detainedReason: string;
	type: string;
}

export interface AccountSettings {
	maxCount: number,
	unlimited: boolean
}

export interface UserError {
	error: string,
	error_param: string
}

export interface UserStore {
	user: User,
	status: Status,
	error: UserError,
	maintenanceMode: MaintenanceMode
}

export interface DeviceInfo {
	browser: string,
	platform: string,
	hash: string
}

export interface LoginBody {
	email: string,
	password: string,
	deviceInfo: DeviceInfo
}

export interface ForgotBody {
	email: string
}

export interface ResetBody {
	code: string,
	password: string
}

export interface SignupBody {
	email: string,
	country?: string,
	password: string,
	firstName?: string,
	lastName?: string,
	birthDate?: string,
	mobileNumber: string,
	invitationCode?: string,
	businessOwnerAccount?: boolean,
	companyName?: string,
	companyAddress?: string,
	companyCountry?: string,
	companyRegNumber?: string,
}

export interface Credentials {
	email: string,
	token: string
}
