import React, { useEffect, useState } from 'react';
import { Input, Select } from 'formik-semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { LocalizedCountryNames, GetNameOptions } from 'i18n-iso-countries';
import { Divider, Icon, List } from 'semantic-ui-react';
import areaCodes from './areaCodeMapping.json';
import { useDispatch, useSelector } from 'react-redux';
import { getAllowedCountries } from '@/features/countries/slice';
import { RootState } from '@/rootReducer';

type CompanyProps = {
	contriesList: LocalizedCountryNames<GetNameOptions>,
	validatePassword: (value: string) => void,
	setServiceAllowed: (value: boolean) => void,
	passwordErrors: Array<string>,
	dirty: boolean
}

interface Country {
	code: string,
	label: string
}

const Company = ({ validatePassword, passwordErrors, dirty, contriesList , setServiceAllowed}: CompanyProps) => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [countryOptions] = useState<Array<Country>>(
		Object.keys(contriesList)
			.map($code => ({
				code: $code,
				label: contriesList[$code],
			})));
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [companyCountry, setCompanyCountry] = useState(null);
	const [phone, setPhone] = useState<string>('');

	const allowedCountries = useSelector(
		(state: RootState) => state.allowedCountries
	);
 
	const serviceIsAllowdInCountry = allowedCountries.list.includes(companyCountry);

	useEffect(() => {
		if (companyCountry) {
			const codeMapping = areaCodes.filter(entry => entry.country === contriesList[companyCountry]);
			if (codeMapping.length === 1 && phone === '') {
				setPhone(codeMapping[0].code);
			}
		}
		setServiceAllowed(serviceIsAllowdInCountry);
	}, [companyCountry]);
 

	useEffect(() => {
		if (allowedCountries.list.length === 0) {
			dispatch(getAllowedCountries());
		}
	}, []);
 
	

	return (
		<div>
			<Input
				fluid
				name="email"
				label={t('form.fields.email')}
				placeholder={t('form.placeholder.email')}
				errorPrompt
			/>
			<div>
				<Input
					icon={<Icon name={showPassword ? 'eye slash' : 'eye'} link onClick={() => { setShowPassword(!showPassword); }} />}
					name="password"
					label={t('form.fields.password')}
					placeholder={t('form.placeholder.password')}
					type={showPassword ? 'text' : 'password'}
					errorPrompt
					validate={validatePassword}
				/>
				<Input
					icon={<Icon name={showConfirmPassword ? 'eye slash' : 'eye'} link onClick={() => { setShowConfirmPassword(!showConfirmPassword); }} />}
					name="passwordConfirm"
					label={t('form.fields.confirmpassword')}
					placeholder={t('form.placeholder.confirmpassword')}
					type={showConfirmPassword ? 'text' : 'password'}
					errorPrompt >
				</Input>
			</div>
			<List className="validation-items">
				<List.Header as="h5" className="validationsheader">{t('registration.passwordvalidators.title')}</List.Header>
				<List.Item className={`${(dirty && passwordErrors.indexOf('len') === -1 ? 'passed' : '')}`}><Icon name="check circle outline" /> {t('registration.passwordvalidators.len')}</List.Item>
				<List.Item className={`${(dirty && passwordErrors.indexOf('spec') === -1 ? 'passed' : '')}`}><Icon name="check circle outline" /> {t('registration.passwordvalidators.spec')}</List.Item>
				<List.Item className={`${(dirty && passwordErrors.indexOf('upper') === -1 ? 'passed' : '')}`}><Icon name="check circle outline" /> {t('registration.passwordvalidators.upper')}</List.Item>
			</List>
			<Divider className="validationsdivider" section />
			<Input
				fluid
				name="companyName"
				label={t('form.fields.legalname')}
				errorPrompt
			/>
			<Input
				fluid
				name="companyRegNumber"
				label={t('form.fields.registrationname')}
				errorPrompt
			/>
			<Input
				fluid
				name="companyAddress"
				label={t('form.fields.legaladress')}
				errorPrompt
			/>
			<Select
				fluid
				name="companyCountry"
				label={t('form.fields.country')}
				placeholder={t('form.placeholder.country')}
				onChange={(e, v) => setCompanyCountry(v.value)}
				errorPrompt
				search
				clearable
				options={countryOptions.map(c => ({ key: c.code, value: c.code, text: c.label }))}
			/>
			{
				serviceIsAllowdInCountry ?
					<p className="countrycheck color-green">
						Available services
					</p> :
					<p className="countrycheck color-red">
						No services are available in your country
					</p>
			}
			<Input
				fluid
				name="phoneNumber"
				type="number"
				value={phone}
				icon='plus'
				iconPosition='left'
				onChange={(e, v) => setPhone(v.value)}
				errorPrompt
				label={t('form.fields.phone')} />
		</div>);
};

export default Company;
