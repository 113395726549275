import * as React from "react";

function SvgInboxIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20.5716 6.85735C20.5716 5.9145 19.8001 5.14307 18.8573 5.14307H5.143C4.20014 5.14307 3.42871 5.9145 3.42871 6.85735M20.5716 6.85735V17.1431C20.5716 18.0859 19.8001 18.8574 18.8573 18.8574H5.143C4.20014 18.8574 3.42871 18.0859 3.42871 17.1431V6.85735M20.5716 6.85735L12.0001 12.8574L3.42871 6.85735" />
    </svg>
  );
}

export default SvgInboxIcon;
