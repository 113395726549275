import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './Terms.css';

function Terms(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>Website Terms and Conditions</h1></Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<h3>Overview</h3>
				<p>Please read these Terms and Conditions (the “Terms”) carefully before using the www.cospay.com
					website (“the Website”) operated by COSPAY LIMITED (“COSPAY”, “us”, “we”, or “our”). These
					Terms outline the rules and regulations for the use of the Website.</p>
				<p>These Terms apply to all visitors, users and others who access or use the Website. By accessing or
					using the Website You agree to be bound by these Terms. Your access to and use of the Website is
					conditioned on Your acceptance of and compliance with these Terms. If You disagree with any part
					of the Terms You should not use the Website.</p>


				<h3>Your Account</h3>
				<p>In order to use the servises offered by COSPAY, You may be required to create an account by
					registering Your details on our Website (the “Account”). All information You provide during the
					registration process or any time thereafter must be accurate and truthful. As part of the registration
					process You will need to accept a separate agreement governing the provision of our services to You
					(the “Agreement”). The aforementioned Agreement will supplement these Terms. Depending on the
					type of services you express desire to use, additional terms and conditions may apply.</p>
				<p>You must ensure that information recorded on Your Account is always accurate and up to date and
					we shall not be liable for any loss arising out of Your failure to do so. We may ask You at any time
					to validate the accuracy of Your information or to provide documents or other evidence to support
					this. We may request information from You or otherwise update inaccurate information You have
					provided to us and You should provide us with the requested information/documentation within the
					timeframe reflected in our request.</p>
				<p>You may only open one Account unless We explicitly approve the opening of additional accounts.</p>
				<p>Please be advised that if You violate these Terms (or provisions of the Agreement) we are entitled to
					close, suspend, or limit Your access to Your Account or our services.</p>


				<h3>Security Obligations</h3>
				<p>You are responsible for keeping Your password and Account details safe. You must not disclose
					Your password or Account details to anyone else. If You think that You have lost Your password or
					Account details, that they have been stolen, or that someone has been using Your Account without
					Your permission, You must immediately notify us. You will be held responsible for any losses
					arising from the disclosure of Your details, in cases where You have not notified COSPAY.</p>
				<p>You must not allow any unauthorised third parties to use Your Account without our express written
					authorisation. Failure to comply with this condition will result in the closure of Your Account and
					will render You liable for any financial losses incurred by COSPAY.</p>
				<p>We are entitled to suspend Your Account, if we suspect that unauthorised or fraudulent use of Your
					Account has taken place or have other concerns regarding the security of Your Account. In this case
					we will notify You by email of the suspension, giving our reasons for it, unless this would
					reasonably compromise security measures or would otherwise be unlawful. Where we are able to do
					so, we will endeavour to notify You in advance of the suspension. If this is not possible, we will
					notify You immediately afterwards. As soon as practicable after the reasons for suspending Your
					Account cease to exist, we will reactivate Your Account or issue You with a new password. Possible
					exceptions to this are if we consider You to have been a party to the unauthorised use or to have
					been grossly negligent with the security of Your Account.</p>


				<h3>Governing Law</h3>
				<p>These Terms shall be governed and construed in accordance with the laws of England and Wales,
					without regard to their conflict of law provisions.</p>
				<p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
					rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the
					remaining provisions of these Terms will remain in effect. These Terms constitute the entire
					agreement between You and us regarding Your use of our Website.</p>


				<h3>Personal Data Protection</h3>
				<p>If we collect any personal information from You, we will take all reasonable precautions to keep
					this information secure. Your personal information will only be used for the purposes of fulfilling
					our obligations under these Terms and the Agreement and will not be disclosed to third parties
					except to the extent necessary to provide our services or if we are required to do so by law. Your
					personal information may be used in connection with any investigation into fraudulent activity or
					the misuse of an Account. If You would like a copy of any personal information we hold on You,
					please contact us. You have the right to require us to correct any inaccuracies in the information we
					hold on You.</p>


				<h3>Privacy</h3>
				<p>Your Privacy is extremely important to us and we strongly recommend that You read our Privacy
					Policy in order to understand how we collect, store, use and access Your personal data.</p>

				<h3>Cookies</h3>
				<p>We employ the use of cookies. By using the Website, You consent to the use of cookies in
					accordance with our Cookie Policy which can be found at www.cospay.com/cookies. Cookies are
					used in some areas of our site to enable the functionality of this area and ease of use for those
					people visiting.</p>

				<h3>License</h3>
				<p>Cospay Limited is an E-Money Institution that is licensed and authorized by the Financial Conduct
					Authority to issue e-money (Reference number 900088). As an EMD Agent of Payrnet Limited, an
					Electronic Money Institution authorised by the Financial Conduct Authority (reference number
					900594), Cospay Limited is authorised to provide associated payment services alongside our e-
					money account services Unless otherwise stated, COSPAY LIMITED and/or it’s licensors own the
					intellectual property rights for all material on the Website. All intellectual property rights are
					reserved. You may view pages from the Website for Your own personal use subject to restrictions
					set in these Terms.</p>
				<p>You must not (without our prior express permission):</p>
				<ul>
					<li>Republish material from the Website;</li>
					<li>Sell, rent or sub-license material from the Website;</li>
					<li>Reproduce, duplicate or copy material from the Website;</li>
					<li>Redistribute content from the Website (unless content is specifically made for
						redistribution).</li>
				</ul>

				<h3>Iframes</h3>
				<p>Without prior approval and express written permission, You may not create frames around our the
					Websites or use other techniques that alter in any way the visual presentation or appearance of our
					Website.</p>

				<h3>Reservation of Rights</h3>
				<p>We reserve the right at any time and in its sole discretion to request that You remove all links or any
					particular link to our Website. You agree to immediately remove all links to our Website upon such
					request. We also reserve the right to amend these Terms and its linking policy at any time. By
					continuing to link to our Website, You agree to be bound to and abide by these linking terms and
					conditions.</p>

				<h3>Disclaimer</h3>
				<p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and
					conditions relating to our Website and the use of this Website (including, without limitation, any
					warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of
					reasonable care and skill). This Website is provided “as is” without any representations or
					warranties, express or implied. COSPAY makes no representations or warranties in relation to the
					Website or the information and materials provided on this Website.</p>
				<p>Whilst COSPAY LIMITED will try to ensure that the Website is of highest standard, we do not
					warrant that this Website will be constantly available, or available at all or the information on this
					Website is complete, true, accurate or non-misleading. We shall not be liable for any disruption or
					impairment of our services or for disruptions or impairments of intermediary services on which we
					rely for the performance of our obligations, provided that such disruption or impairment is due to
					abnormal and unforeseeable circumstances beyond our reasonable control or the control of the
					intermediary affected.</p>
				<p>In no event COSPAY LIMITED will be liable for any damages, including, but not limited to direct
					or indirect damages arising from or in connection with the use of the Website. Nothing on this
					website constitutes, or is meant to constitute, advice of any kind. We shall not be liable for any
					indirect or consequential losses including but not limited to loss of profit, loss of business and loss
					of reputation. We shall not be liable for any losses arising from our compliance with legal and
					regulatory requirements.</p>
				<p>Nothing in this disclaimer will:</p>
				<ul>
					<li>limit or exclude our or Your liability for fraud or fraudulent misrepresentation;</li>
					<li>limit any of our or Your liabilities in any way that is not permitted under applicable law;</li>
					<li>or exclude any of our or Your liabilities that may not be excluded under applicable law.</li>
				</ul>
				<p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer:</p>
				<ul>
					<li>are subject to the preceding paragraph;</li>
					<li>and govern all liabilities arising under the disclaimer or in relation to the subject matter of
						this disclaimer, including liabilities arising in contract, in tort (including negligence) and for
						breach of statutory duty.</li>
				</ul>

				<h3>Indemnity</h3>
				<p>You hereby indemnify and undertake to keep COSPAY LIMITED indemnified against any losses,
					damages, costs, liabilities and expenses (including without limitation legal expenses and any
					amounts paid by COSPAY to a third party in settlement of a claim or dispute on the advice of
					COSPAYS’s legal advisers) incurred or suffered by COSPAY and arising out of any breach by You
					of any provision of these Terms, or arising out of any claim that You have breached any provision of
					these Terms.</p>
				<p>You remain liable under these Terms in respect of all charges and other amounts incurred through
					the use of Your Account at any time, irrespective of termination, suspension or closure.</p>

				<h3>Changes</h3>
				<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will
					post these changes on the Website. It is your sole responsibility to periodically check the Term for
					any changes. By continuing to access or use our Website after those changes have been made, you
					agree to be bound by the revised Terms. If you do not agree to the new Terms, please stop using the
					Website.</p>
				<h3>Contact Information</h3>
				<p>If You have any queries regarding any of our Terms or services, please do not hesitate to contact us
					at info@cospay.com.</p>
			</Segment>
		</Container>
	);
}

export default Terms;
