import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { confirmEmail } from '@features/user/userSlice';
import { openModal } from '@features/swal/slice';
import {
	useParams, Route, Redirect
} from 'react-router-dom';
import LoadingPage from '../../pages/LoadingPage';
import { useTranslation } from 'react-i18next';

interface ParamTypes {
    code: string
}

const Email = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const { code } = useParams<ParamTypes>();
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		async function confirm() {
			try {
				await dispatch(confirmEmail(code));
				dispatch(openModal({ header: t('swal.CONFIRM_EMAIL'), content: t('swal.CONFIRM_EMAIL_TEXT') }));
			} catch (e) {
				dispatch(openModal({ header: 'confirmation error', content: 'invalid code' }));
			} finally {
				setLoading(false);
			}
		}
		confirm();
	}, [dispatch]);

	if (loading) return <LoadingPage />;
	return (
		<div> <Route><Redirect to={'/'} /> </Route>
		</div >);
};

export default Email;
