import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { openModal } from '../swal/slice';
import { KYC, AddressPayload } from './types';
import request from '../../services/request';

const initialState: KYC = {
	token: null
};

const kycSlice = createSlice({
	name: 'status',
	initialState,
	reducers: {
		setToken(state, action: PayloadAction<string>) {
			state.token = action.payload;
		}
	}
});

export const { setToken } = kycSlice.actions;

export const kycSelector = (state: { kycSelector: KYC }) =>
	state.kycSelector;

export const getToken = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/identity/sumsub/accesstoken');
			const { data } = response;
			dispatch(setToken(data.token));
			return data;
		} catch (e) {
			dispatch(openModal({ header: 'Network error', content: e.message }));
		}
	};
};

export const getRefreshedToken = async () => {
	const response = await request.get('/api/identity/sumsub/accesstoken');
	const { data } = response;
	return data.token;
};

export const requestFinalizeReviewStatus = (): AppThunk => {
	return async dispatch => {
		try {
			await request.get('/api/identity/sumsub/finalizeReviewStatus');
		} catch (e) {
			dispatch(openModal({ header: 'Network error', content: e.message }));
		}
	};
};

export const address = (payload: AddressPayload): AppThunk => {
	return async dispatch => {
		try {
			await request.post('/api/identity/address', payload);
		} catch (e) {
			dispatch(openModal({ header: 'Network error', content: e }));
		}
	};
};

export default kycSlice.reducer;
