import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { Client, ClientsStore } from './types';
import { PaginateParams } from '@/helpers/globalTypes';
import { showException } from '../swal/slice';

const initialState: ClientsStore = {
	loading: false,
	list: [],
	client: null,
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 25,
};


const slice = createSlice({
	name: 'clients',
	initialState,
	reducers: {
		setClients(state, action: PayloadAction<Array<Client>>) {
			state.list = action.payload;
		},
		setClient(state, action: PayloadAction<Client>) {
			state.client = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setClients, setClient, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getClients = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api-portal/clients', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setClients(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count /  payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showException(e);
			dispatch(setLoading(false));
		}
	};
};

export const getClient = (clientId: string): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get(`/api-portal/clients/${clientId}`);
			const { data } = response;
			dispatch(setClient(data));
			return data;
		} catch (e) {
			console.log(e);
		}
	};
};

export default slice.reducer;
