/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './Dashboard.css';
import VerticalNavbar from '@/components/Navbar/VerticalNavbar';
import ApiDocs from '@/components/Dashboard/DashboardPages/ApiDocs';
import Statistics from '@/components/Dashboard/DashboardPages/Statistics/Statistics';
import IndividualClients from '@/components/Dashboard/DashboardPages/Clients/IndividualClients';
import BusinessClients from '@/components/Dashboard/DashboardPages/Clients/BusinessClients';
import Accounts from '@/components/Dashboard/DashboardPages/Accounts/Accounts';
import ApiAuthDocs from '@/components/Dashboard/DashboardPages/ApiAuthDocs';
import WebhookDocs from '@/components/Dashboard/DashboardPages/WebhookDocs';
import Invoices from '@/components/Dashboard/DashboardPages/Invoices';
import Cards from '@/components/Dashboard/DashboardPages/Cards';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import LoadingPage from './LoadingPage';
import ClientDetails from '@/components/Dashboard/DashboardPages/Clients/Client';
import Transactions from '@/components/Dashboard/DashboardPages/Transactions';
import AccountDetails from '@/components/Dashboard/DashboardPages/Accounts/Account';
import SettingDocs from '@/components/Dashboard/DashboardPages/SettingDocs';

const Dashboard = (): React.ReactElement => {

	const [kycTierVerified, setKycTierVerified] = useState<boolean>(null);
	const [wlpIdSet, setWlpIdSet] = useState<boolean>(null);
	const { user, status } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (!user || !status) return;
		const kycTier = user.kycTier || null;
		const wlpId = status.wlpId || null;
		setKycTierVerified((kycTier >= 2));
		setWlpIdSet((wlpId != null));
	}, [user]);

	if (!user || !status) return <LoadingPage />;
	if (wlpIdSet === null || kycTierVerified === null) return <LoadingPage />;

	return (
		<div id="dashboard">
			<Container fluid >
				<Grid stackable >
					<Grid.Column width={3}>
						<VerticalNavbar />
					</Grid.Column>
					<Grid.Column width={13}>
						<Switch>
							{!(kycTierVerified && wlpIdSet) && <Route><Redirect to={'/kyb'} /></Route>}
							<Route path={'/docs/api'} >
								<ApiDocs />
							</Route>
							<Route path={'/docs/settings'} >
								<SettingDocs />
							</Route>
							<Route path={'/docs/authentications'} >
								<ApiAuthDocs />
							</Route>
							<Route path={'/docs/webhooks'} >
								<WebhookDocs />
							</Route>
							<Route exact path={'/dashboard'} >
								<Statistics />
							</Route>
							<Route path={'/accounts'} >
								<Accounts />
							</Route>
							<Route path={'/cards'} >
								<Cards />
							</Route>
							<Route path={'/clients/individual'} >
								<IndividualClients />
							</Route>
							<Route path={'/clients/business'} >
								<BusinessClients />
							</Route>
							<Route path={'/invoices'} >
								<Invoices />
							</Route>
							<Route path={'/client/:clientId'} render={(props) => (
								<ClientDetails {...props} />
							)} />
							
							<Route path={'/account/:walletId'} render={(props) => (
								<AccountDetails {...props} />
							)} />
							<Route path={'/transactions'} >
								<Transactions />
							</Route>

							<Redirect exact from='/' to='/dashboard' />
						</Switch>
					</Grid.Column>
				</Grid>
			</Container>
		</div>);
};

export default Dashboard;
