import React from 'react';
import isEmpty from 'is-empty';
import { SubmitButton } from 'formik-semantic-ui-react';
import { useTranslation } from 'react-i18next';

type SubmitProps = {
	toc: boolean,
	aml: boolean,
	dirty: boolean,
	serviceAllowed: boolean,
	isSubmitting: boolean,
	errors: any
}

const RegisterButton = ({ toc, aml, isSubmitting, errors, dirty, serviceAllowed }: SubmitProps) => {
	const { t } = useTranslation('translations');

	return (
		<SubmitButton
			className="registerbutton"
			disabled={isSubmitting || !isEmpty(errors) || !dirty || !toc || !aml ||!serviceAllowed}
			primary size="large" type="submit">
			{t('registration.submit')}
		</SubmitButton>
	);
};

export default RegisterButton;
