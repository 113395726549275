import React from 'react';
import { Grid, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

type ComplianceProps = {
	toc: boolean,
	setToc: (toc: boolean) => void,
	aml: boolean,
	setAml: (aml: boolean) => void,
}

const Compliance = ({ toc, setToc, aml, setAml }: ComplianceProps) => {
	const { t } = useTranslation('translations');

	return (
		<Grid columns={1} padded='vertically'>
			<Grid.Column floated="left">
				<Grid.Column width={12}> <Checkbox fitted
					name="toc"
					label={<label>{t('registration.links.toc.label')} <a href='/'>{t('registration.links.toc.link')}</a></label>}
					checked={toc} onChange={() => { setToc(!toc); }} />
				</Grid.Column>
				<Grid.Column width={12}>  <Checkbox fitted
					name="aml"
					label={<label>{t('registration.links.aml.label')} <a href='/'>{t('registration.links.aml.link')}</a></label>}
					checked={aml} onChange={() => { setAml(!aml); }} />
				</Grid.Column> </Grid.Column>
		</Grid>
	);
};

export default Compliance;
