import React from 'react';
import { Container } from 'semantic-ui-react';

const NotFound = (): React.ReactElement =>
	
	
	<Container fluid>
		<div>Not found</div>
	</Container>;

export default NotFound;
