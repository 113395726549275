import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const ApiDocs = (): React.ReactElement => {

	return (
		<Container fluid>
			<Segment>
				<SwaggerUI url={`${process.env.REACT_APP_API_URL || ''}/api-docs/swagger.json`} />
			</Segment>
		</Container>
	);
};


export default ApiDocs;
