import React, { useCallback, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Dimmer, Loader, Select } from 'semantic-ui-react';

interface Props {
	label: string,
	fetchData: () => void,
}


const BarChart = ({ label, fetchData }: Props): React.ReactElement => {

	const [apiFetch, setApiFetch] = useState<any>(null);
	const currentYear = new Date().getFullYear().toString();
	const [yearData, setYearData] = useState<any[]>(null);
	const [selectOptions, setSelectOptions] = useState<any[]>([]);
	const [selectValue, setSelectValue] = useState<string>(currentYear);

	const fetch = useCallback(() => {
		const get = async () => {
			try {
				const response = await fetchData();
				setApiFetch(response);
			} catch (e) {
				console.log(e);
			}
		};
		get();
	}, []);

	useEffect(() => {
		fetch();
	}, []);

	useEffect(() => {
		if (!apiFetch) return;

		setYearData(apiFetch[currentYear]);
	}, [apiFetch]);

	useEffect(() => {
		if (!apiFetch) return;

		const options = [];
		Object.entries(apiFetch).map(entry => {
			options.push({ value: entry[0], key: entry[0], text: entry[0] });
		});
		setSelectOptions(options);
	}, [apiFetch]);


	const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	const data = {
		labels: labels,
		datasets: [
			{
				label: label,
				data: yearData,
				backgroundColor: [
					'rgba(170, 183, 196, 0.4)',
				],
				borderWidth: 1,
			},
		],
	};



	return (
		<div>
			<Dimmer active={!apiFetch} inverted>
				<Loader />
			</Dimmer>
			<Select
				className="selectYear"
				name="selectYear"
				placeholder="Select year"
				label="Select yaer"
				options={selectOptions}
				value={selectValue}
				onChange={(element, { value }) => {
					setYearData(apiFetch[value.toString()]);
					setSelectValue(value.toString());
				}}
			/>
			<Bar data={data} />
		</div>
	);
};


export default BarChart;
