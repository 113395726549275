/* eslint-disable react/prop-types */
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, Header, List, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { showException } from '@/features/swal/slice';
import Loading from '@/components/Shared/Loading';
import { getTransactions } from '@/features/transactions/slice';
import { formatDateWithPattern } from '@/helpers/date';
import { getAccount } from '@/features/accounts/slice';
import { useHistory } from 'react-router';

const AccountDetails = (props): React.ReactElement => {

	const dispatch = useDispatch();
	const [walletId] = useState<string>(props.match.params.walletId);

	const { account } = useSelector(
		(state: RootState) => state.accounts
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getAccount(walletId));
			} catch (e) {
				showException(e);
			}
		};
		get();
	}, [walletId, dispatch]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	if (!account) {
		return <Loading />;
	}

	return (
		<Container fluid>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Account</Header></Grid.Column>
						<Grid.Column floated='right'><Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button></Grid.Column>
					</Grid.Row>
				</Grid>


				<List>
					<List.Item>
						<List.Header className="color-grey">Client ID</List.Header>
						<List.Description>{account.clientId}</List.Description>
					</List.Item>
				</List>
			</Segment>
			<Segment>
				<Header as='h2'>Transactions</Header>
				<AccountTransactions walletId={walletId} />
			</Segment>
		</Container>
	);
};

interface Props {
	walletId: string
}

const AccountTransactions = ({ walletId }: Props) => {
	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.transactions
	);
	const paginateInitialState: PaginateParams = { orderBy: 'date', sort: 'DESC', skip: 0, filter: `walletId=${walletId}`, take: 5 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);

	const headers: Array<Headers> = [
		{
			field: 'transactionNumber',
			name: 'Reference Number',
			position: 1,
			sortable: true
		},
		{
			field: 'transactionType',
			name: 'Type',
			position: 2,
			sortable: true
		},
		{
			field: 'amountOrigCcy',
			name: 'Amount',
			position: 3,
			sortable: true
		},
		{
			field: 'details',
			name: 'Details',
			position: 4,
			sortable: false
		},
		{
			field: 'date',
			name: 'Date',
			position: 5,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 6,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getTransactions(pagination));
			} catch (e) {
				showException(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const history = useHistory();
	const openUser = (clientId: string) => {
		history.push(`/client/${clientId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry) => {
				return (
					<Table.Row key={`${entry.transactionNumber}$`} >
						<Table.Cell width={3}>{entry.transactionNumber}</Table.Cell>
						<Table.Cell width={3}>{capitalizeFirstLetter(entry.transactionType)}</Table.Cell>
						<Table.Cell width={3}>{`${entry.origCcy} ${entry.amountOrigCcy}`}</Table.Cell>
						<Table.Cell width={3}>{entry.details}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.date, 'dd/MM/yyyy hh:mm:ss')}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='View client' trigger={<Button secondary icon="user" onClick={() => openUser(entry.clientId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={6}>You dont have cards yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<CustomTable
			fetchData={fetchData}
			loading={loading}
			paginateInitialState={paginateInitialState}
			tableBody={tableBody}
			totalPages={totalPages}
			setPagination={setPagination}
			header={headers}
			pagination={pagination}
			hideItemsPerPage={true}
		/>
	);

};

export default AccountDetails;
