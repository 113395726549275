import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from './rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { logout, status, refreshToken, connect, subscribe, unsubscribe, disconnect, clearUser } from '@features/user/userSlice';
import { SocketService } from '@services/socketService';
import LoadingPage from './pages/LoadingPage';
import { connect as connectTicket, subscribe as subscribeTicket, unsubscribe as unsubscribeTicket } from '@features/tickets/slice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ component: Component, ...rest }): React.ReactElement => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(true);
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
	const { token } = useSelector(
		(state: RootState) => state.credentials
	);

	useEffect(() => {
		if (!token) {
			setIsAuthorized(false);
			return setLoading(false);
		}

		async function getAuthorizationData() {
			try {
				await dispatch(refreshToken());
				const authStatus = await dispatch(status());
				const loggedIn = authStatus['loggedIn'];
				if (!loggedIn) {
					await dispatch(logout());
					setIsAuthorized(false);
					return setLoading(false);
				}
				setIsAuthorized(true);
				return setLoading(false);
			} catch (e) {
				await dispatch(logout());
				setIsAuthorized(false);
				return setLoading(false);
			}
		}

		getAuthorizationData();

	}, [dispatch]);

	useEffect(() => {
		let refreshTimeout;
		if (!isAuthorized) return;

		const getRefreshedToken = () => {
			refreshTimeout && clearTimeout();
			refreshTimeout = setTimeout(() => {
				dispatch(refreshToken());
				getRefreshedToken();
			}, 60 * 1000);
		};
		getRefreshedToken();
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
			dispatch(clearUser());
			disconnect();
			//disconnectTicket();
			SocketService.disconnect();
			refreshTimeout && clearTimeout(refreshTimeout);
		};
	}, [dispatch, isAuthorized]);

	useEffect(() => {
		if (!isAuthorized) return;
		connectTicket();
		dispatch(subscribeTicket());
		return () => {
			unsubscribeTicket();
		};
	}, [dispatch, isAuthorized]);


	const handleOnIdle = event => {
		console.log('user is idle', event);
	};

	const handleOnActive = event => {
		console.log('user is active', event);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleOnAction = event => {
		// console.log('user did something', event)
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60 * 15,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnAction,
		debounce: 500
	});

	if (loading) {
		return <LoadingPage />;
	}
	return (
		<Route
			{...rest}
			render={props =>
				isAuthorized ? <Component {...props} /> : <Redirect to='/auth'/>
			}
		/>
	);
};

export default ProtectedRoute;
