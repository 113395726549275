import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import React from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { Button, Container, Divider, Header, List, Segment } from 'semantic-ui-react';
import '../MyAccount/Profile.css';
import './WebhookDocs.css';

const WebhookDocs = (): React.ReactElement => {

	const notificationObject = `{
		"type": "ACCOUNT_CREATED", //possible values - ACCOUNT_CREATED,ACCOUNT_UPDATED,CARD_CREATED,CARD_UPDATED
		"entity": {...}, //object corresponding to the type
		"eventTime": "2007-12-03T10:15:30.234" //date and time in ISO-8601 format
}`;

	const accountCreatedObject = `{
		"status": "ACTIVE", //one of ORDERED, ACTIVE, DELETED, TERMINATED, BLOCKED
		"type": "BANK", //one of CRYPTO, BANK, BANK_AND_CARD, STOCKS, TRUST
		"balance": 11.11,
		"balanceOnHold": 1.11,
		"balanceFrozen": 1.11,
		"currency": "EUR",
		"clientId": "SDF15243",
		"email": "test@test.com",
		"firstName": "John",
		"lastName": "Doe",
		"companyName": "Alpha Co.",
		"companyRegNumber": "EU23425234234",
		"walletId": "GZ343234",
		"integration": "RAILSBANK", // RAILSBANK, CAMBRIDGE etc. Partner code
		"label": "user provided label",
		"createdDate": 1642685678000, //epoch timestamp in milliseconds
		"bank": {
			"bankAddress": "221B Baker Street, London",
			"bankName": "Barclays",
			"iban": "GB32GG345234213423",
			"recipient": "GT2346324",
			"swift": "BARCGB22XXX"
		}
}`;

	const cardCreatedObject = `{
		"walletId": "CR34532",
		"name": "JOHN DOE", //name on card
		"status": "ACTIVE", //one of REQUESTED, PENDING, ORDERED, ORDER_COMPLETED, APPROVED, REJECTED, BLOCKED, SUSPENDED, ACTIVE, ACTIVATING, INACTIVE, LOST, STOLEN, EXPIRED
		"virtual": true, //is this card virtual, or physical otherwise
		"clientId": "SDF15243",
		"firstName": "JOHN",
		"lastName": "DOE",
		"balance": 11.11,
		"balanceOnHold": 1.11,
		"balanceFrozen": 1.11,
		"availableAmount": 1.11,
		"lockedAmount": 1.11,
		"currency": "EUR",
		"expirationDate": "2023-06-30",
		"cardNumberMask": "5352 65** **** 5911",
		"creationDate": 1642685678000, //epoch timestamp in milliseconds
		"integration": "DECTA" //partner code
}`;

	return (
		<Container fluid>
			<Segment id='webhookDocs'>
				<Header as='h2'>Webhooks</Header>
				<Divider hidden />
				<Divider hidden />
				<p>In order to receive notifications from our API Portal, you need to configure by passing these simple  steps:</p>

				<List ordered>
					<List.Item>Go to My Account {'>'} Account Details </List.Item>
					<List.Item>Setup URL and your API Secret</List.Item>
				</List>

				<Segment basic>
					<Formik
						initialValues={{ url: 'https://apiportal.com/callback', secret: 'OACMvXed21SBhKDJNQsjoWAC50aPcb2JSli0otSM' }}
						onSubmit={() => console.log('for test purposes only')}
					>
						{() => (
							<Form
								size="large">
								<Input
									label='Url'
									name="url"
									placeholder='Enter url'
									disabled
									className='inputField'
								/>
								<Input
									label='Secret'
									name="secret"
									placeholder='Enter secret'
									disabled
									className='inputField'
								/>
								<div className='editBTNS'>
									<SubmitButton
										disabled
										primary size="large" type="submit">
										Add
									</SubmitButton>
									<Button className='discardBTN' content='Cancel' disabled />
								</div>
							</Form>)}
					</Formik>
				</Segment>
				<Divider hidden />
				<p>Notification that our system is sending to subscribers are based on this envelope object:</p>
				<CopyBlock
					language='json'
					text={notificationObject}
					theme={dracula}
					wrapLines={true}
					codeBlock
				/>
				<Divider hidden />
				<p>Notification types can be the following:</p>
				<p><strong>ACCOUNT_CREATED</strong> - will be sent after new account is added to the list of user accounts. Entity object:</p>
				<CopyBlock
					language='json'
					text={accountCreatedObject}
					theme={dracula}
					wrapLines={true}
					codeBlock
				/>
				<Divider hidden />
				<p><strong>ACCOUNT_UPDATED</strong> - will be sent after balance or any other account data change. The entity object is the same as for account creation.</p>
				<Divider hidden />
				<p><strong>CARD_CREATED</strong> - will be sent after new card is added to the list of user cards. Entity object:</p>
				<CopyBlock
					language='json'
					text={cardCreatedObject}
					theme={dracula}
					wrapLines={true}
					codeBlock
				/>
				<Divider hidden />
				<p><strong>CARD_UPDATED</strong> - will be sent after balance or card data changes. Entity object is the same as for card creation.</p>
			</Segment>
		</Container>
	);
};


export default WebhookDocs;
