import React, { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import './Profile.css';
import {
	Segment,
	Container,
	Divider,
	Header,
	Grid,
	Button,
	Table,
	Popup,
} from 'semantic-ui-react';
import { addWebhook, deleteWebhook, getWebhooks, putWebhook } from '@/features/security/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import AddWebhookModal from './Profile/AddWebhookModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Webhook } from '@/features/security/types';

const Webhooks = (): React.ReactElement => {
	const { webhooks } = useSelector(
		(state: RootState) => state.profile
	);
	const dispatch = useDispatch();
	const [open, setOpen] = useState<boolean>(false);
	const [edit, setEdit] = useState<Webhook>(null);

	useEffect(() => {
		fetchWebhooks();
	}, [dispatch]);

	const fetchWebhooks = async () => {
		try {
			await dispatch(getWebhooks());
		} catch (e) {
			showException(e);
		}
	};

	const handleOnCloseWebhookModal = async (url?: string, secret?: string) => {
		setOpen(false);
		if (url && secret) {
			try {
				if (!edit) {
					await addWebhook(url, secret);
				} else {
					await putWebhook(edit.uuid, url, secret);
				}
				fetchWebhooks();
			}
			catch (e) {
				showException(e);
			}
		}
		setEdit(null);
	};

	const removeWebhook = async (entry: Webhook) => {
		try {
			await deleteWebhook(entry.uuid);
			showSuccess({ title: 'swal.SUCCESS', text: 'Webhook removed' });
			fetchWebhooks();
		} catch(e) {
			showException(e);
		}
	};

	const editWebhook = (webhook: Webhook) => {
		setEdit(webhook);
		setOpen(true);
	};

	return (
		<Container>
			<AddWebhookModal
				open={open}
				onClose={handleOnCloseWebhookModal}
				edit={edit}
			/>
			<Segment className="profilesegment" padded>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header className="profileHeader" >Webhooks</Header>
						</Grid.Column>
						<Grid.Column>
							<Button floated='right' primary onClick={() => setOpen(true)}>Add new webhook</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Divider hidden />
				<Grid.Column >
					<Table className='teamMemberTable' stackable padded celled textAlign='center'>
						<Table.Header className='teamMemberTableHeader'>
							<Table.Row className="teamMemberTableHeaders">
								<Table.HeaderCell>URL</Table.HeaderCell>
								<Table.HeaderCell>Secret</Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body className="teamMemberTable">
							{webhooks.map((entry) => {
								return (
									<Table.Row key={`${entry.uuid}`} >
										<Table.Cell>{entry.url}</Table.Cell>
										<Table.Cell>
											{entry.secret}
											<CopyToClipboard id="clipboard-button" text={entry.secret}>
												<Button basic primary onClick={(e) => {
													e.stopPropagation();
													e.preventDefault();
												}} icon="clone outline"></Button>
											</CopyToClipboard>
										</Table.Cell>
										<Table.Cell>
											<Popup content='Edit webhook' trigger={<Button secondary icon="edit" onClick={() => editWebhook(entry)} />} />
											<Popup content='Remove webhook' trigger={<Button secondary icon="trash" onClick={() => removeWebhook(entry)} />} />
										</Table.Cell>
									</Table.Row>
								);
							})}
							{webhooks.length === 0 && <Table.Row>
								<Table.Cell textAlign='center' colSpan={3}>You dont have webhooks added yet.</Table.Cell>
							</Table.Row>}
						</Table.Body>
					</Table>
				</Grid.Column>
			</Segment>
			<Divider hidden />
		</Container >
	);
};

export default Webhooks;
