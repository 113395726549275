import React, { useState } from 'react';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import './Profile.css';
import {
	Segment,
	Container,
	Divider,
	Header,
	Icon,
	List,
	Grid
} from 'semantic-ui-react';
import Loading from '../../Shared/Loading';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { usePasswordValidation } from '@/components/Registration/hooks';
import * as Yup from 'yup';
import { changePassword } from '@/features/security/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import { isEmpty } from 'lodash';

const ChangePassword = (): React.ReactElement => {
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	//const [edit, setEdit] = useState<boolean>(false);
	const { t } = useTranslation('translations');
	const [passwordErrors, validatePassword] = usePasswordValidation();
	const individualValidator = Yup.object({
		passwordOld: Yup.string()
			.required(t('form.validator.required')),
		password:
			Yup.string()
				.required(t('form.validator.required')),
		passwordConfirm: //TODO matching
			Yup.string()
				.required(t('form.validator.required'))
				.oneOf([Yup.ref('password'), null], t('form.validator.confirmpassword'))
	});

	const initialValues = {
		password: '',
		passwordConfirm: '',
		passwordOld: ''
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { password, passwordOld } = formData;
		try {
			await changePassword({ newPassword: password, oldPassword: passwordOld });
			showSuccess({ title: 'swal.SUCCESS', text: 'swal.PASSWORD_UPDATED' });
			//setEdit(false);
		} catch (e) {
			showException(e, [
				{
					key: 'unchanged',
					title: 'swal.ERROR',
					message: 'swal.NEW_PASSWORD_UNCHANGED'
				},
				{
					key: 'invalid',
					title: 'swal.ERROR',
					message: 'swal.INVALID_OLD_PASSWORD'
				}
			]);
		} finally { setSubmitting(false); }
	};

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

	if (!user) {
		return <Loading />;
	}
	return (
		<Container>
			<Segment className="profilesegment" padded>
				<Header className="profileHeader" >Change password</Header>
				<Divider hidden />
				<Grid columns={2}>
					<Grid.Column>
						<Formik
							initialValues={initialValues}
							validationSchema={individualValidator}
							onSubmit={submit}
						>{({ errors, isSubmitting, dirty }) => (
								<Form>
									<br />
									<Input icon={<Icon name={showPassword ? 'eye slash' : 'eye'} link onClick={() => {
										setShowPassword(!showPassword);
									}} />} name="passwordOld" label={t('form.validator.currentpassword')}
									type={showPassword ? 'text' : 'password'} errorPrompt />
									<Divider />
									<Input icon={<Icon name={showNewPassword ? 'eye slash' : 'eye'} link onClick={() => {
										setShowNewPassword(!showNewPassword);
									}} />} name="password" label={t('form.fields.newpasswordlabel')}
									type={showNewPassword ? 'text' : 'password'} errorPrompt
									validate={validatePassword}>
									</Input>
									<Input
										icon={<Icon name={showConfirmPassword ? 'eye slash' : 'eye'} link onClick={() => {
											setShowConfirmPassword(!showConfirmPassword);
										}} />} name="passwordConfirm" label={t('form.fields.confirmpassword')}
										type={showConfirmPassword ? 'text' : 'password'} errorPrompt>
									</Input>
									<List className="validation-items">
										<List.Header as="h5"
											className="validationsheader">{t('password.passwordvalidators.title')}</List.Header>
										<List.Item
											className={`${(dirty && passwordErrors.indexOf('len') === -1 ? 'passed' : '')}`}><Icon
												name="check circle outline" /> {t('password.passwordvalidators.len')}</List.Item>
										<List.Item
											className={`${(dirty && passwordErrors.indexOf('spec') === -1 ? 'passed' : '')}`}><Icon
												name="check circle outline" /> {t('password.passwordvalidators.spec')}</List.Item>
										<List.Item
											className={`${(dirty && passwordErrors.indexOf('upper') === -1 ? 'passed' : '')}`}><Icon
												name="check circle outline" /> {t('password.passwordvalidators.upper')}</List.Item>
									</List>
									<SubmitButton type='submit'
										disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(passwordErrors)}
										primary>{t('password.activedata.changepwd')}</SubmitButton>
								</Form>
							)}
						</Formik>
					</Grid.Column>
				</Grid>

			</Segment>
			<Divider hidden />
		</Container >
	);
};

export default ChangePassword;
