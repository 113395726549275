import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/rootReducer';
import isEmpty from 'is-empty';
import {
	Message,
	Grid,
	Segment,
	Divider,
	Container,
	Image,
	Transition,
	Ref
} from 'semantic-ui-react';
import { getDeviceInfo } from '@helpers/browser';
import logo from '@assets/registrationLogo.svg';
import './Login.css';
import { login, logout, resendEmail } from '@features/user/userSlice';
import { showInfo, showException } from '@features/swal/slice';
import { DeviceInfo } from '@features/user/types';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ResendEmail from '@/components/Login/ResendEmail';

const Login = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const history = useHistory();
	const dispatch = useDispatch();
	const testRef = useRef();
	const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(null);
	const [loginError, setError] = useState<boolean>(false);
	const [emailError, setEmailError] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [emailSending, setEmailSending] = useState<boolean>(false);

	const initialValues = {
		email: '',
		password: ''
	};

	const validationSchema = Yup.object({
		email: Yup.string().email(t('form.validator.email')).required(t('form.validator.required')),
		password: Yup.string()
			.required(t('form.validator.required')),
	});

	const { regionRestricted, registrationDisabledReason, registrationEnabled } = useSelector(
		(state: RootState) => state.status
	);


	useEffect(() => {
		async function getBrowserInfo() {
			setDeviceInfo(await getDeviceInfo());
		}
		getBrowserInfo();
	}, []);

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setError(false);
		try {
			await dispatch(logout());
			const { email, password } = formData;
			setEmail(email);
			await dispatch(login({ email, password, deviceInfo }));
			history.push('/');
		}
		catch ({ data }) {
			setSubmitting(false);
			if (data.errors.length > 0 && data.errors[0].error === 'email_not_confirmed') {
				setEmailError(true);
			} else {
				setError(true);
			}
		}
	};

	const resend = async () => {
		setEmailSending(true);
		try {
			await dispatch(resendEmail(email));
		} catch (e) {
			showException(e);
		} finally {
			setEmailSending(false);
			setEmailError(false);
		}
	};

	const onRegistrationClick = (e) => {
		if (!registrationEnabled) {
			e.preventDefault();
			showInfo({ title: 'swal.REGISTRATION_DISABLED_TITLE', text: 'swal.REGISTRATION_DISABLED_TEXT', textParams: { param_value: registrationDisabledReason, timer: null } });
		}
		if (regionRestricted) {
			e.preventDefault();
			showInfo({ title: 'swal.REGION_RESTRICTED_TITLE', text: 'swal.REGION_RESTRICTED_TEXT' });
		}
	};

	return (
		<Container fluid>
			<ResendEmail dimmer={emailSending} open={emailError} onClose={() => { setEmailError(false); }} resend={resend} />
			<Grid id="login" verticalAlign="middle" centered stackable divided="vertically">
				<Grid.Column>
					<Segment>
						<Segment basic style={{ marginBottom: 0 }}>
							<Image src={logo} />
							<div className="ui hidden divider"/>
						</Segment>
						<Segment basic style={{ marginTop: 0 }}>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={submit}
							>
								{({ errors, isSubmitting, dirty }) => (
									<Ref innerRef={testRef}>
										<Form
											size="large"
											error={loginError}>
											<Transition visible={loginError} animation='scale' duration={500}>
												<Message
													error
													content={t('errors.EMAIL_OR_PASSWORD_INCORRECT')} />
											</Transition>
											<Input
												fluid
												name="email"
												icon="envelope"
												iconPosition="left"
												placeholder={t('form.placeholder.email')}
												type="email"
												errorPrompt
											/>
											<Input
												fluid
												name="password"
												icon="lock"
												iconPosition="left"
												placeholder={t('form.placeholder.password')}
												type="password"
												errorPrompt
											/>
											<Divider fitted hidden />
											<Divider hidden /><SubmitButton
												disabled={isSubmitting || !isEmpty(errors) || !dirty}
												primary size="large" type="submit">
												{t('login.submit')}
											</SubmitButton>
										</Form>
									</Ref>)}
							</Formik>
						</Segment>
						<Segment basic>
							<Grid columns={2}>
								<Grid.Column>
									<Link to="/auth/forgot-password">{t('login.resetPassword')}</Link>
								</Grid.Column>
								<Grid.Column textAlign="right">
									<Link to="/auth/signup" onClick={onRegistrationClick}>{t('login.register')}</Link>
								</Grid.Column>
							</Grid>
						</Segment>
					</Segment>
				</Grid.Column>
			</Grid>
		</Container >
	);
};

export default Login;
