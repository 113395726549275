import React from 'react';
import { Button, Container, Divider, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react';
import '../MyAccount/Profile.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SecretList from '../MyAccount/SecretList';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

const SettingDocs = (): React.ReactElement => {
	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const secrets = [
		{
			id: 1,
			secret: 'wokndnsrgTvXxSctFjtpo2O5jfIn2XEoEslgc4Dp',
			token: 'S3E9qXYR7k5vAi9rBsEL0gSxLRQ3MBOrEPBIwnMCpUO02uYUV96gC4iiaDYp',
			label: 'First secret key'
		},
		{
			id: 2,
			secret: 'RkmlhsIJCQkAJtV2FQy28V6yXRQ6pK41HgiSHIse',
			token: 'ZmyxU2e5fnKH41fCpPidxTmbe6roo0RYWoRfDS1fAQzhJE0SnxVNPYXeKNgL',
			label: 'Second secret key'
		}
	];


	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Settings</Header>
				<Divider hidden />
				<Divider hidden />

				<Segment>
					<Header as='h4'>Add team member</Header>
					<p>Invite your team members, developers, system analysts using email invitation.</p>
					<p>Navigate to My Account {'>'} Account details page and fill the following form:</p>
					<Divider hidden />

					<Grid>
						<Grid.Row columns={2}>
							<Grid.Column>
								<Header className="profileHeader" >Team Memebers</Header>
							</Grid.Column>
							<Grid.Column>
								<Button floated='right' primary>Invite team member</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<Divider hidden />
					<Table className='teamMemberTable' stackable padded celled textAlign='center'>
						<Table.Header className='teamMemberTableHeader'>
							<Table.Row className="teamMemberTableHeaders">
								<Table.HeaderCell>Email</Table.HeaderCell>
								<Table.HeaderCell>Invitation Code</Table.HeaderCell>
								<Table.HeaderCell>Registered</Table.HeaderCell>
								<Table.HeaderCell>Role</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body className="teamMemberTable">
							<Table.Row>
								<Table.Cell>developer@yourdomain.com</Table.Cell>
								<Table.Cell>sqjve3YhJJ9MCC5n
									<CopyToClipboard id="clipboard-button" text='sqjve3YhJJ9MCC5n'>
										<Button basic primary onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}} icon="clone outline"></Button>
									</CopyToClipboard>
								</Table.Cell>
								<Table.Cell>
									<Icon className="approvedIcon" color="green" size="large" name="check circle outline"></Icon>
								</Table.Cell>
								<Table.Cell>Developer</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>systemanalyst@yourdomain.com</Table.Cell>
								<Table.Cell>gxYJdUbX9z8gl45J
									<CopyToClipboard id="clipboard-button" text='gxYJdUbX9z8gl45J'>
										<Button basic primary onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}} icon="clone outline"></Button>
									</CopyToClipboard>
								</Table.Cell>
								<Table.Cell>
									<Icon className="pendingIcon" color="red" size="large" name="times circle outline"></Icon>
								</Table.Cell>
								<Table.Cell>Developer</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</Segment>
				<Divider hidden />
				<Segment>
					<Header as='h4'>Generate secret key</Header>
					<p>Navigate to My Account {'>'} Account details page and generate your secret keys via the following form:</p>
					<Divider hidden />
					<Grid>
						<Grid.Row columns={2}>
							<Grid.Column>
								<Header className="profileHeader" >API secrets</Header>
							</Grid.Column>
							<Grid.Column>
								<Button floated='right' primary>Generate New Secret Keys</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<Divider hidden />
					<Grid.Column >
						<SecretList secrets={secrets} onRemove={() => console.log('For test purposes only. Functionality disabled.')} serverAgent={status.wlpId} />
					</Grid.Column>
				</Segment>
			</Segment>
		</Container>
	);
};


export default SettingDocs;
