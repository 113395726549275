import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showException } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Header, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import { getTransactions } from '@/features/transactions/slice';
import { formatDateWithPattern } from '@/helpers/date';



const Transactions = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.transactions
	);

	const paginateInitialState: PaginateParams = { orderBy: 'date', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);

	const filter: FilterParam[] = [
		{
			field: 'walletId',
			label: 'Account Number',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'firstName',
			label: 'First Name',
			type: 'input'
		},
		{
			field: 'lastName',
			label: 'Last Name',
			type: 'input'
		},

	];

	const headers: Array<Headers> = [
		{
			field: 'transactionNumber',
			name: 'Reference Number',
			position: 1,
			sortable: true
		},
		{
			field: 'transactionType',
			name: 'Type',
			position: 2,
			sortable: true
		},
		{
			field: 'amountOrigCcy',
			name: 'Amount',
			position: 3,
			sortable: true
		},
		{
			field: 'details',
			name: 'Details',
			position: 4,
			sortable: false
		},
		{
			field: 'date',
			name: 'Date',
			position: 5,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 6,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getTransactions(pagination));
			} catch (e) {
				showException(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const history = useHistory();
	const openUser = (clientId: string) => {
		history.push(`/client/${clientId}`);
	};

	const openAccount = (walletId: string) => {
		history.push(`/account/${walletId}`);
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry) => {
				return (
					<Table.Row key={`${entry.transactionNumber}$`} >
						<Table.Cell width={3}>{entry.transactionNumber}</Table.Cell>
						<Table.Cell width={3}>{capitalizeFirstLetter(entry.transactionType)}</Table.Cell>
						<Table.Cell width={3}>{`${entry.origCcy} ${entry.amountOrigCcy}`}</Table.Cell>
						<Table.Cell width={3}>{entry.details}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.date, 'dd/MM/yyyy hh:mm:ss')}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='Open account details' trigger={<Button secondary icon="credit card outline" onClick={() => openAccount(entry.walletId)}/>} />
							<Popup content='View client' trigger={<Button secondary icon="user" onClick={() => openUser(entry.clientId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={6}>You dont have cards yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Transactions</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Segment>
		</Container>
	);
};


export default Transactions;
