import React from 'react';
import '../Footer/Footer.css';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AMLPolicy from '@/components/Legal/AMLPolicy/AMLPolicy';
import PrivacyPolicy from '@/components/Legal/PrivacyPolicy/PrivacyPolicy';
import Terms from '@/components/Legal/Terms/Terms';
import './Legal.css';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Agents } from '@/helpers/globalTypes';
import AMLPolicyDTSCB from './AMLPolicy/AMLPolicyDTSCB';
import AMLPolicyTYNCOFEX from './AMLPolicy/AMLPolicyTYNCOFEX';
import PrivacyPolicyDTSCB from './PrivacyPolicy/PrivacyPolicyDTSCB';
import PrivacyPolicyTYNCOFEX from './PrivacyPolicy/PrivacyPolicyTYNCOFEX';
import TermsDTSCB from './Terms/TermsDTSCB';
import TermsTYNCOFEX from './Terms/TermsTYNCOFEX';

const Legal = (): React.ReactElement => {
	const { path } = useRouteMatch();
	const { agent } = useSelector(
		(state: RootState) => state.status
	);


	return (
		<div id='legal'>
			<Switch>
				<Route path={`${path}/amlpolicy`}>
					{agent !== Agents.DTSCB && agent !== Agents.TYNCOFEX && <AMLPolicy />}
					{agent === Agents.DTSCB && <AMLPolicyDTSCB />}
					{agent === Agents.TYNCOFEX && <AMLPolicyTYNCOFEX />}
				</Route>
				<Route path={`${path}/privacypolicy`}>
					{agent !== Agents.DTSCB && agent !== Agents.TYNCOFEX && <PrivacyPolicy />}
					{agent === Agents.DTSCB && <PrivacyPolicyDTSCB />}
					{agent === Agents.TYNCOFEX && <PrivacyPolicyTYNCOFEX />}
				</Route>
				<Route path={`${path}/terms`}>
					{agent !== Agents.DTSCB && agent !== Agents.TYNCOFEX && <Terms />}
					{agent === Agents.DTSCB && <TermsDTSCB />}
					{agent === Agents.TYNCOFEX && <TermsTYNCOFEX />}
				</Route>
			</Switch>
		</div>
	);
};

export default Legal;
