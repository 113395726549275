import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Button, Container, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import './SupportTicket.css';
import * as Yup from 'yup';
import { Form, Input, SubmitButton, TextArea, Select } from 'formik-semantic-ui-react';
import { showSuccess } from '../../../features/swal/slice';
import isEmpty from 'is-empty';

interface Props {
	onBackClick: () => void
	onSend: () => void
}

const SupportTicket = ({ onBackClick, onSend }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [selectedFiles, setSelectedFiles] = useState([]);
	//const [selectedSubType, setSelectedSubType] = useState('');
	const hiddenFileInput = React.useRef(null);

	const fileTypes = [
		'image/jpg',
		'image/jpeg',
		'image/png',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	];

	const subtypeoption = [
		{
			key: 'Account',
			text: 'Account',
			value: 'Account',
		},
		{
			key: 'Cards',
			text: 'Cards',
			value: 'Cards',
		},
		{
			key: 'Other',
			text: 'Other',
			value: 'Other',
		},
	];


	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > 5) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (files[i].size > 5000000) {
				showSuccess({ title: 'swal.ERROR', text: 'swal.FILE_SIZE_TOO_LARGE', textParams: { fileName: files[i].name } });
				continue;
			}
			if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < 5) {
				selectedFiles.push(files[i]);
				setSelectedFiles(selectedFiles);
			}
		}
	};

	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
	};

	const validationSchema = Yup.object({
		subtype: Yup.string().required(t('form.validator.required')),
		subject: Yup.string().required(t('form.validator.required')),
		description: Yup.string().required(t('form.validator.required')),
	});

	return (
		<div id="submitTicket">
			<Container>
				<Breadcrumb size="large">
					<Breadcrumb.Section className="backchevronitem" link onClick={onBackClick}><Icon name="chevron left" className="backIcon" /> {t('inbox.back')}</Breadcrumb.Section>
				</Breadcrumb>
				<Header as='h1' content={t('inbox.sumbitTicket')}></Header>
				<Segment padded>
					<Grid stackable>
						<Grid.Column width={16}>

							<Formik
								initialValues={{
									subtype: '',
									subject: '',
									description: ''
								}}
								onSubmit={onSend}
								validationSchema={validationSchema}
								render={({ setFieldValue, isSubmitting, errors, dirty }) => {
									return (
										<Form>
											<Select
												name="subtype"
												placeholder={t('inbox.selectPlaceholder')}
												label={t('inbox.ticketSubType')}
												fluid
												options={subtypeoption}
												errorPromt
											/>
											<Divider hidden />
											<Input
												fluid
												name="subject"
												label={t('inbox.subject')}
												errorPrompt
											/>
											<Divider hidden />
											<TextArea
												className="descriptiontextarea"
												label={t('inbox.description')}
												name="description"
												style={{ minHeight: 100 }}
												errorPrompt
											/>
											<Divider hidden />
											<p>{t('inbox.fileSize')}</p>

											<input type="file"
												multiple
												accept="image/jpg, image/png, image/jpeg, .pdf, .doc, .docx"
												name="files"
												ref={hiddenFileInput}
												onChange={e => {
													uploadFiles(e.currentTarget.files);
													setFieldValue('files', selectedFiles);
												}}
												style={{ display: 'none' }}
											/>
											<Button type="button" onClick={handleUploadClick} content={t('inbox.uploadButton')} icon="upload" />

											{selectedFiles.length > 0 && (
												<Grid className="uploadedFiles">
													<Divider hidden />
													{selectedFiles.map((file, index) =>
														<Grid.Row className="uploadedFile" key={file.name}>
															<Icon size="large" name="file outline" />
															<div className="fileName">{file.name}</div>
															<Icon className="deleteAttachment"
																onClick={() => {
																	deleteAttachment(index);
																	setFieldValue('files', selectedFiles);
																}}
																size="large"
																name="times" />
														</Grid.Row>
													)}
												</Grid>
											)}

											<Divider section />
											<SubmitButton primary
												fluid
												type="submit"
												disabled={isSubmitting || !isEmpty(errors) || !dirty}>
												{t('inbox.sumbitButton')}
											</SubmitButton>
										</Form>);
								}}
							/>

						</Grid.Column>
					</Grid>
				</Segment>
			</Container>
		</div>
	);
};

export default SupportTicket;
