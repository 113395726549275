import { getAccountsStatistics, getCardsStatistics, getClientStatistics, getTransactionsStatistics } from '@/features/statistics/statisticsSlice';
import { UserType } from '@/features/user/types';
import React from 'react';
import { Container, Divider, Grid, Header, Segment } from 'semantic-ui-react';
import BarChart from './BarChart';

const Statistics = (): React.ReactElement => {


	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Dashboard</Header>
				<Divider hidden />
				<Grid columns={4}>
					{/* <Grid.Row textAlign='center'>
						<Grid.Column ><Statistic label='Active clients' value='400' /></Grid.Column>
						<Grid.Column><Statistic label='Active accounts' value='550' /></Grid.Column>
						<Grid.Column><Statistic label='Active cards' value='150' /></Grid.Column>
						<Grid.Column><Statistic label='Transactions' value='1,987' /></Grid.Column>
					</Grid.Row> */}
				</Grid>
				<Divider />
				<Divider hidden />
				<Divider hidden />
				<Grid columns={2}>
					<Header as='h3'>Clients</Header>
					<Grid.Row>
						<Grid.Column><BarChart fetchData={() => getClientStatistics(UserType.EXTERNAL_INDIVIDUAL)} label='Individual Clients' /></Grid.Column>
						<Grid.Column><BarChart fetchData={() => getClientStatistics(UserType.EXTERNAL_BUSINESS)} label='Business Clients' /></Grid.Column>
					</Grid.Row>
					<Header as='h3'>Accounts and Cards</Header>
					<Grid.Row>
						<Grid.Column><BarChart fetchData={() => getAccountsStatistics()} label='Bank Accounts' /></Grid.Column>
						<Grid.Column><BarChart fetchData={() => getCardsStatistics()} label='Cards' /></Grid.Column>
					</Grid.Row>
					<Header as='h3'>Transactions</Header>
					<Grid.Row>
						<Grid.Column><BarChart fetchData={() =>getTransactionsStatistics()} label='Transactions' /></Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</Container>
	);
};


export default Statistics;
