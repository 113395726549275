import React from 'react';
import {
	Modal,
	Header,
	Grid,
	Button,
	Divider
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';


interface Props {
	open: boolean;
	onClose: (value?: string) => void
}

const EditMobile = ({ open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const initialValues = {
		code: '',
		phone: ''
	};

	const validationSchema = Yup.object({
		code: Yup.string()
			.matches(/(?:\+|00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/, 'Invalid phone code')
			.required(t('form.validator.required')),
		phone: Yup.number()
			.typeError('That doesn\'t look like a phone number')
			.positive('A phone number can\'t start with a minus')
			.integer('A phone number can\'t include a decimal point')
			.required(t('form.validator.required'))

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { phone, code } = formData;
		onClose(code + phone);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						className='editForm'
						size="large">
						<Header content='Edit' />

						<Grid className='editGrid' columns={2}>
							<Grid.Column className='countryCodeCLMN' width={3}>	<Input
								label='Code'
								fluid
								name="code"
								placeholder='+000'
								list="countryCodes"
							/>
							</Grid.Column>
							<Grid.Column className='phoneGrid' width={13}>
								<Input
									label={t('profile.data.phonenumber')}
									fluid
									name="phone"
									placeholder='12 3412 3412'
								/>
							</Grid.Column>
						</Grid>
						<Divider />
						<div className='editBTNS'>
							<SubmitButton
								disabled={isSubmitting || !isEmpty(errors) || !dirty}
								primary size="large" type="submit">
								{t('form.buttons.save')}
							</SubmitButton>
							<Button className='discardBTN' content='Discard' onClick={() => onClose()} />
						</div>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default EditMobile;
