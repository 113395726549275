import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SwalPayload, ErrorTranslate } from './types';
import Swal, { SweetAlertResult, SweetAlertOptions } from 'sweetalert2';
import i18n from '../../i18n';

const t = (value, params?) => {
	return params ? i18n.t(`translations:${value}`, params) : i18n.t(`translations:${value}`);
};

const initialState = {
	header: '',
	content: '',
	open: false
};

const swalSlice = createSlice({
	name: 'swal',
	initialState,
	reducers: {
		openModal(state, action: PayloadAction<SwalPayload>) {
			state.header = action.payload.header;
			state.content = action.payload.content;
			state.open = true;
		},
		closeModal(state) {
			state.header = '';
			state.content = '';
			state.open = false;
		}
	}
});

export const { openModal, closeModal } = swalSlice.actions;

export const swalSelector = (state: { statusStore: SwalPayload }) =>
	state.statusStore;

export const showSuccess = ({ title = 'swal.SUCCESS', titleParams = null, text = null, textParams = null, timer = 3000, toast = false, html = null, htmlParams = null }: { title?: string, titleParams?: any, text?: string, textParams?: any, timer?: number, toast?: boolean, html?: string, htmlParams?: any } = {}): Promise<SweetAlertResult> => {
	return Swal.fire({
		position: toast ? 'top' : 'center',
		icon: 'success',
		title: t(title, titleParams),
		text: text ? t(text, textParams) : null,
		html: html ? t(html, htmlParams) : null,
		timer,
		showConfirmButton: timer == null,
		toast: toast,
		heightAuto: false,
	});
};

export const showException = (err: any, customErrorParams?: ErrorTranslate | ErrorTranslate[]): Promise<SweetAlertResult> => {
	if (err.status === 417) {
		const errorCode: string = err.data ? err.data.error : null;
		const errorParam: string = err.data ? err.data.error_param : null;
		const errorValue: string = err.data ? err.data.param_value : null;
		let errorMessage: string = null;

		if (errorParam === 'CUSTOM_MESSAGE') {
			const options: SweetAlertOptions = {
				position: 'center',
				title: t('swal.' + errorCode.toUpperCase()),
				icon: 'error',
				text: errorValue,
				heightAuto: false
			};
			return Swal.fire(options);
		}

		if (customErrorParams) {
			let customErrors: ErrorTranslate[];
			let params: any = {};

			if (!Array.isArray(customErrorParams)) {
				customErrors = [customErrorParams];
			} else {
				customErrors = customErrorParams;
			}

			for (const customError of customErrors) {
				if (errorCode !== customError.key) {
					continue;
				}
				if (customError.keyParam && errorParam !== customError.keyParam) {
					continue;
				}

				customError.errorParams = customError.errorParams || {};
				params = customError.params || {};

				for (const errorParamKey of Object.keys(customError.errorParams)) {
					if (errorParam === errorParamKey) {
						params.param = t(customError.errorParams[errorParamKey]);
					}
				}

				if (errorValue) {
					params.param_value = errorValue;
				}

				return Swal.fire({
					position: 'center',
					icon: 'error',
					title: t(customError.title || 'errors.SERVER_ERROR'),
					text: t(customError.message, params),
					heightAuto: false,
				});
			}
		}

		if (errorCode === 'error' && errorParam) {
			t('errors.' + errorParam.toUpperCase());
		} else if (errorCode && errorParam) {
			errorMessage = t('errors.FIELD_ERROR', { field: errorParam, message: errorCode });
		} else {
			errorMessage = t('errors.SOMETHING_WENT_WRONG', { message: err.message });
		}

		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: t('errors.SERVER_ERROR'),
			text: errorMessage,
			heightAuto: false,
		});
	}

	if (err.status === 403 && err.data.message === 'two factor auth is disabled') {
		return Swal.fire({
			position: 'center',
			html: `<span>${t('errors.2FA_REQUIRED_TEXT')} </span><a href="/wallet/security">Click here to move to security settings</a>`,
			icon: 'error',
			title: t('errors.2FA_REQUIRED'),
			heightAuto: false,
		});
	}

	if (err.data && err.data.message) {
		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: t('errors.SERVER_ERROR'),
			text: err.data.message,
			heightAuto: false,
		});
	}

	if (err.message) {
		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: t('errors.SERVER_ERROR'),
			text: err.message,
			heightAuto: false,
		});
	}

	return Swal.fire({
		position: 'center',
		icon: 'error',
		title: t('errors.SERVER_ERROR'),
		text: t('errors.SOMETHING_WENT_WRONG', { message: err.statusText }),
		heightAuto: false,
	});
};

export const showInfo = ({ title = 'swal.SUCCESS', titleParams = null, text = null, textParams = null, timer = 3000, toast = false }: { title?: string, titleParams?: any, text?: string, textParams?: any, timer?: number, toast?: boolean } = {}): Promise<SweetAlertResult> => {
	return Swal.fire({
		position: toast ? 'top' : 'center',
		icon: 'info',
		title: t(title, titleParams),
		text: text ? t(text, textParams) : null,
		timer,
		showConfirmButton: timer == null,
		toast: toast,
		heightAuto: false,
	});
};

export default swalSlice.reducer;
