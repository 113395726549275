import React, { useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/rootReducer';
import isEmpty from 'is-empty';
import {
	Grid,
	Header,
	Segment,
	Divider,
	Container,
	Image,
	Ref
} from 'semantic-ui-react';
import logo from '@assets/registrationLogo.svg';
import './Login.css';
import { forgotPassword, logout } from '@features/user/userSlice';
import { showSuccess, showException, showInfo } from '@features/swal/slice';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const ForgotPassword = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const ref = useRef();
	const history = useHistory();
	const dispatch = useDispatch();

	const initialValues = {
		email: ''
	};

	const validationSchema = Yup.object({
		email: Yup.string()
			.email(t('form.validator.email'))
			.required(t('form.validator.required'))
	});

	const { regionRestricted, registrationDisabledReason, registrationEnabled } = useSelector(
		(state: RootState) => state.status
	);

	const onRegistrationClick = (e) => {
		if (!registrationEnabled) {
			e.preventDefault();
			showInfo({ title: 'SWAL.REGISTRATION_DISABLED_TITLE', text: 'SWAL.REGISTRATION_DISABLED_TEXT', textParams: { param_value: registrationDisabledReason, timer: null } });
		}
		if (regionRestricted) {
			e.preventDefault();
			showInfo({ title: 'SWAL.REGION_RESTRICTED_TITLE', text: 'SWAL.REGION_RESTRICTED_TEXT' });
		}
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { email } = formData;
			await dispatch(forgotPassword({ email }));
			await dispatch(logout());
			showSuccess({ title: 'swal.NEW_PASSWORD', text: 'swal.NEW_PASSWORD_TEXT', timer: null });
			history.push('/auth');
		}
		catch (error) {
			setSubmitting(false);
			showException(error);
		}
	};

	return (
		<Container fluid>
			<Grid id="login" verticalAlign="middle" centered stackable divided="vertically">
				<Grid.Column>
					<Segment>
						<Segment basic>
							<Image src={logo} />
							<div className="ui hidden divider"></div>
							<Header size="large">
								{/* <Image src="/img/logo.svg" /> */}
								<Divider fitted hidden />
								<span className="login-content">{t('forgot-password.title')}</span>
							</Header>
						</Segment>
						<Segment basic>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={submit}
							>
								{({ errors, isSubmitting, dirty }) => (
									<Ref innerRef={ref}><Form
										size="large">
										<Input
											fluid
											name="email"
											icon="envelope"
											iconPosition="left"
											placeholder={t('form.placeholder.email')}
											errorPrompt
										/>
										<SubmitButton
											disabled={isSubmitting || !isEmpty(errors) || !dirty}
											primary size="large" type="submit">
											{t('forgot-password.submit')}
										</SubmitButton>
									</Form></Ref>)}
							</Formik>
						</Segment>
						<Segment basic>
							<Grid columns={2}>
								<Grid.Column>
									<Link to="/auth">{t('registration.links.login')}</Link>
								</Grid.Column>
								<Grid.Column textAlign="right">
									<Link to="/auth/signup" onClick={onRegistrationClick}>{t('login.register')}</Link>
								</Grid.Column>
							</Grid>
						</Segment>
					</Segment>
				</Grid.Column>
			</Grid>
		</Container>
	);
};

export default ForgotPassword;
