import { Secret } from '@/features/security/types';
import React, { useState } from 'react';
import { Divider, Accordion, Icon, List, Button } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './Profile.css';

interface Props {
	secrets: Secret[],
	serverAgent: string,
	onRemove: (id: number) => void
}

const SecretList = ({ secrets, serverAgent, onRemove }: Props) => {
	const [state, setState] = useState({ activeIndex: -1 });

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = state;
		const newIndex = activeIndex === index ? -1 : index;

		setState({ activeIndex: newIndex });
	};

	return (
		<div>
			{secrets.map((item, index) => (
				<Accordion fluid styled key={item.id} className="faqAccordian">
					<Accordion.Title
						active={state.activeIndex === index}
						index={index}
						onClick={handleClick}
					>
						<Icon name='dropdown' />
						{item.label}
					</Accordion.Title>
					<Accordion.Content active={state.activeIndex === index}>
						<List>
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">API Key</List.Header>
									<List.Description>
										{item.token}
										<CopyToClipboard id="clipboard-button" text={item.token}>
											<Button basic primary onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
											}} icon="clone outline"></Button>
										</CopyToClipboard>
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">API Secret</List.Header>
									<List.Description>
										{item.secret}
										<CopyToClipboard id="clipboard-button" text={item.secret}>
											<Button basic primary onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
											}} icon="clone outline"></Button>
										</CopyToClipboard>
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">Server agent</List.Header>
									<List.Description>
										{serverAgent}
										<CopyToClipboard id="clipboard-button" text={serverAgent}>
											<Button basic primary onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
											}} icon="clone outline"></Button>
										</CopyToClipboard>
									</List.Description>
								</List.Content>
							</List.Item>
						</List>
						<Button primary onClick={() => onRemove(item.id)}>Remove</Button>
					</Accordion.Content>
				</Accordion>))}
			<Divider hidden />
		</div>
	);
};

export default SecretList;
