import React, { useEffect, useState } from 'react';
import { Dimmer, Divider, Grid, Loader, PaginationProps, Select, Table } from 'semantic-ui-react';
import TableFilters, { FilterParam } from './TableFilters';
import TablePagination, { PaginatorParams } from './TablePagination';
import './Table.css';
import { PaginateParams } from '@/helpers/globalTypes';

export enum SortDirection {
	asc = 'ascending',
	desc = 'descending'
}

export interface Headers {
	field: string,
	name: string,
	position: number,
	sortable: boolean
}

type Props = {
	fetchData: () => void,
	setPagination: (pagination: PaginateParams) => void,
	pagination: PaginateParams,
	tableBody: JSX.Element,
	filter?: FilterParam[],
	paginateInitialState: PaginateParams,
	totalPages: number,
	loading: boolean,
	header: Headers[],
	hideItemsPerPage?: boolean
}


const CustomTable = ({ fetchData, setPagination, tableBody, filter, paginateInitialState, totalPages, loading, header, pagination, hideItemsPerPage = false }: Props): React.ReactElement => {

	const [paginator, setPaginator] = useState<PaginatorParams>({ activePage: 1 });
	const [sorted, setSorted] = useState<any>(
		{
			column: paginateInitialState.orderBy,
			direction: paginateInitialState.sort === 'ASC' ? SortDirection.asc : SortDirection.desc
		});

	useEffect(() => {
		fetchData;
	}, [pagination]);

	const handlePaginationChange = (e, { activePage }: PaginationProps) => {
		setPaginator({ activePage: +activePage });
		const paginationState = { ...pagination };
		paginationState.skip = +activePage - 1;
		setPagination(paginationState);
	};

	const handleFilter = (value, clear) => {
		if (clear) {
			const paginationState = { ...paginateInitialState };
			paginationState.take = pagination.take;
			setPagination(paginationState);
			setSorted({
				column: paginateInitialState.orderBy,
				direction: paginateInitialState.sort === 'ASC' ? SortDirection.asc : SortDirection.desc
			});
			setPaginator({activePage: 1});
			return;
		}
		if (value !== null) {
			const paginationState = { ...pagination };
			paginationState.filter = (paginateInitialState.filter !== null && paginateInitialState.filter !== '') ? paginateInitialState.filter + ';' + value : value;
			setPagination(paginationState);
		}
	};

	const headerElement = (index) => {
		const headerObj = header.find(element => element.position == index + 1);
		if (headerObj.sortable) {
			return (<Table.HeaderCell
				key={headerObj.name + index}
				sorted={headerObj.field === sorted.column ? sorted.direction : undefined}
				onClick={() => handleSort(headerObj.field)}
				className='sortable'
			>{headerObj.name}</Table.HeaderCell>);
		} else {
			return (<Table.HeaderCell
				key={headerObj.name + index}
			>{headerObj.name}</Table.HeaderCell>);
		}

	};

	const handleSort = (column) => {
		const direction = sorted.column !== column ? SortDirection.asc
			: sorted.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
		setSorted({ column, direction });
		const paginationState = { ...pagination };
		paginationState.orderBy = column;
		paginationState.sort = direction === SortDirection.asc ? 'ASC' : 'DESC';
		setPagination(paginationState);
	};

	const handleItemsPerPage = (val) => {
		const paginationState = { ...pagination };
		paginationState.take = val;
		setPagination(paginationState);
	};

	const itemPerPageOptions = [
		{
			key: '5',
			text: '5',
			value: 5
		},
		{
			key: '10',
			text: '10',
			value: 10
		},
		{
			key: '25',
			text: '25',
			value: 25,
		},
		{
			key: '50',
			text: '50',
			value: 50,
		}
	];


	return (
		<div id='table-layout'>
			<Dimmer active={loading} inverted>
				<Loader />
			</Dimmer>
			<Divider hidden />
			{filter && <div>
				<TableFilters filter={filter} handleFilterChange={handleFilter} />
				<Divider hidden />
			</div>}


			<Table id='table' celled sortable textAlign='center' padded='very' striped>
				<Table.Header fullWidth className='tableHeader'>
					<Table.Row className="tableHeaders">
						{header.map((entry, index) => headerElement(index))}
					</Table.Row>
				</Table.Header>
				{tableBody}
				<Table.Footer>
					<Table.Row>
						<Table.HeaderCell textAlign='left' colSpan={header.length}>
							<Grid>
								<Grid.Row columns={3}>
									<Grid.Column verticalAlign='middle' ><div className='totalPages'>Total pages: <strong>{totalPages}</strong></div> </Grid.Column>
									<Grid.Column verticalAlign='middle' floated='right'>
										{!hideItemsPerPage && <div className='totalPages'>
											Items per page:
											<Select
												className='itemsPerPage'
												compact
												value={pagination.take}
												options={itemPerPageOptions}
												onChange={(e, data) => handleItemsPerPage(data.value)}
											/>
										</div>}
									</Grid.Column>
									<Grid.Column floated='right'>
										{totalPages > 1 && <TablePagination
											totalPages={totalPages}
											handlePaginationChange={handlePaginationChange}
											paginator={paginator}
										/>}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>

		</div>
	);
};


export default CustomTable;
