import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getRefreshedToken, requestFinalizeReviewStatus } from '@features/kyc/slice';
import { showException } from '@features/swal/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
	Modal, Message, Button
} from 'semantic-ui-react';
import snsWebSdk from '@sumsub/websdk';
import { RootState } from '@/rootReducer';

interface SumSubProps {
	token: string,
	onClose: () => void
}

const SumSubForm = ({ token, onClose }: SumSubProps) => {
	const { i18n, t } = useTranslation('translations');
	const [errorMessage] = useState<string>('');
	const [submitted, setSubmitted] = useState<boolean>(false);
	const dispatch = useDispatch();

	const { status } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		const callKycForm = async () => {
			const snsWebSdkInstance = snsWebSdk.init(token, () => Promise.resolve(getRefreshedToken()))
				.withConf({
					lang: i18n.language,
				})
				.onMessage(async ($type, $payload) => {
					switch ($type) {
					case 'idCheck.applicantStatus':
						if (($payload as any).reviewStatus === 'pending') {
							try {
								await dispatch(requestFinalizeReviewStatus());
								if (submitted) {
									onClose();
								}
							} catch (e) {
								showException(e);
							}
						}
						break;
					case 'idCheck.onApplicantSubmitted':
						setSubmitted(true);
						break;
					}
				})
				.on('idCheck.onError', (error) => {
					console.log('onError', error);
				})
				.withBaseUrl(status.sumsubUrl)
				.build();
			snsWebSdkInstance.launch('#sumsub-websdk-container');
		};
		callKycForm();
	}, [dispatch]);

	return (
		<Modal.Content>
			{errorMessage && (
				<Message negative>
					<Message.Content>{errorMessage}</Message.Content>
				</Message>
			)}
			<div id="sumsub-websdk-container"></div>
			<Button onClick={onClose}>{t('form.buttons.cancel')}</Button>
		</Modal.Content>
	);
};

export default SumSubForm;
