import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '../../store';
import { UserStore, User, Status, LoginBody, SignupBody, UserError, ForgotBody, ResetBody, KycTier, MaintenanceMode } from './types';
import { SocketService } from '../../services/socketService';
import { setCreditials, clearCreditials } from './credentialsSice';
import isEmpty from 'is-empty';
import { Subscription } from 'rxjs';

const initialState: UserStore = {
	maintenanceMode: { mode: false, reason: null },
	user: null,
	status: null,
	error: null,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setStatus(state, action: PayloadAction<Status>) {
			state.status = action.payload;
		},
		setUser(state, action: PayloadAction<User>) {
			state.user = action.payload;
		},
		updateUser(state, action: PayloadAction<Partial<User>>) {
			state.user = { ...state.user, ...action.payload };
		},
		updateKyc(state, action: PayloadAction<KycTier>) {
			const kyc = state.user.kyc || [];
			let existing = -1;
			if (kyc.length > 0) {
				existing = kyc.findIndex(r => r.tier === action.payload.tier);
			}
			if (existing === -1) {
				kyc.push(action.payload);
			}
			else {
				kyc[existing] = action.payload;
			}
			state.user.kyc = kyc;
		},
		insertKyc(state, action: PayloadAction<KycTier>) {
			const kyc = state.user.kyc || [];
			let existing = -1;
			if (kyc.length > 0) {
				existing = kyc.findIndex(r => r.tier === action.payload.tier);
			}
			if (existing === -1) {
				kyc.push(action.payload);
			}
			state.user.kyc = kyc;
		},
		deleteKyc(state, action: PayloadAction<KycTier>) {
			const index = state.user.kyc.findIndex(k => k.tier === action.payload.tier);
			if (index > -1) {
				state.user.kyc.splice(index, 1);
			}
		},
		updateMaintenance(state, action: PayloadAction<MaintenanceMode>) {
			state.maintenanceMode = action.payload;
		},
		clearUser(state) {
			state.user = null;
		},
		clearStatus(state) {
			state.status = null;
		},
		setError(state, action: PayloadAction<UserError>) {
			state.error = action.payload;
		}
	}
});

export const { updateMaintenance, setStatus, setUser, updateUser, updateKyc, insertKyc, deleteKyc, clearUser, clearStatus, setError } = userSlice.actions;

export const userSelector = (state: { userStore: UserStore }) =>
	state.userStore;

let socketService: SocketService;
let dataSubscriber: Subscription;
let updateSubscriber: Subscription;
let updateKycSubscriber: Subscription;
let insertKycSubscriber: Subscription;
let deleteKycSubscriber: Subscription;
let maintenanceSubscriber: Subscription;

function dataAdapter(data: any): any {
	return data;
}

function updateAdapter(data: Partial<User>): Partial<User> {
	const userUpdate: Partial<User> = {};
	for (const key of Object.keys(data)) {
		if (isEmpty(data[key])) {
			continue;
		}
		userUpdate[key] = data[key];
	}
	return userUpdate;
}

function kycUpdateAdapter(data: KycTier): KycTier {
	const updatedKyc = new KycTier();
	for (const key of Object.keys(data)) {
		if (isEmpty(data[key])) {
			continue;
		}
		if (key === 'final') {
			updatedKyc[key] = Boolean(data[key]);
		} else {
			updatedKyc[key] = data[key];
		}
	}
	return updatedKyc;
}

function kycInsertAdapter(data: KycTier): KycTier {
	return data;
}

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('api-user');
	}
};

export const disconnect = (): void => {
	socketService = null;
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		if (!socketService) return;
		try {
			dataSubscriber = socketService.listen('dataR', []).subscribe((data) => {
				if (isEmpty(data) || data.length === 0) return;
				dispatch(setUser(dataAdapter(data)));
				dispatch(updateUser(updateAdapter(data)));
			});
			updateSubscriber = socketService.listen('update', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateUser(updateAdapter(data)));
			});
			updateKycSubscriber = socketService.listen('update-kyc', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateKyc(kycUpdateAdapter(data)));
			});
			insertKycSubscriber = socketService.listen('insert-kyc', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(insertKyc(kycInsertAdapter(data)));
			});
			
			deleteKycSubscriber = socketService.listen('delete-kyc', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(deleteKyc());
			});
			maintenanceSubscriber = socketService.listen('maintenance', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateMaintenance(data));
			});
			socketService.send('data');
		} catch (error) {
			console.log(error);
			dispatch(setError(error));
		}
	};
};

export const unsubscribe = (): void => {
	dataSubscriber.unsubscribe();
	updateSubscriber.unsubscribe();
	updateKycSubscriber.unsubscribe();
	insertKycSubscriber.unsubscribe();
	deleteKycSubscriber.unsubscribe();
	maintenanceSubscriber.unsubscribe();
};

export const status = (): AppThunk => {
	return async dispatch => {
		const response = await request.get('/api-portal/auth/status');
		const { data } = response;
		dispatch(setStatus(data));
		dispatch(setError(null));
		return data;
	};
};

export const login = (payload: LoginBody): AppThunk => {
	return async dispatch => {
		const response = request.post('/api-portal/auth/login',
			payload
		);
		const { data } = await response;
		dispatch(setCreditials(data));
		dispatch(setError(null));
	};
};

export const refreshToken = (): AppThunk => {
	return async dispatch => {
		const response = await request.post('/api-portal/auth/refreshToken');
		const { data } = response;
		dispatch(setCreditials(data));
		dispatch(setError(null));
	};
};

export const signup = async (payload: SignupBody) => {
	const response = await request.post('/api-portal/auth/register', payload);
	const { data } = response;
	return data;
};

export const resendEmail = (email: string): AppThunk => {
	return async dispatch => {
		await request.post('/api-portal/auth/resend', { email });
		dispatch(setError(null));
	};
};

export const forgotPassword = (payload: ForgotBody): AppThunk => {
	return async dispatch => {
		await request.post('/api-portal/auth/forgot-password', payload);
		dispatch(setError(null));
	};
};

export const resetPassword = (payload: ResetBody): AppThunk => {
	return async dispatch => {
		await request.post('/api-portal/auth/reset-password', payload);
		dispatch(setError(null));
	};
};

export const confirmEmail = (code: string): AppThunk => {
	return async dispatch => {
		await request.get(`/api-portal/auth/confirm-email/${code}`);
		dispatch(setError(null));
	};
};


export const logout = (): AppThunk => {
	return async dispatch => {
		try {
			await request.get('/api-portal/auth/logout');
			dispatch(clearCreditials());
			dispatch(clearUser());
			dispatch(clearStatus());
		} catch (error) {
			//TODO handle error
			console.log(error);
		}
	};
};

export default userSlice.reducer;
