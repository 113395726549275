import React, { useEffect, useState } from 'react';
import { Divider, Grid, Icon, List, } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Input } from 'formik-semantic-ui-react';
import { GetNameOptions, LocalizedCountryNames } from 'i18n-iso-countries';
import localCallingCodes from './callingCodes.json';
import areaCodes from './areaCodeMapping.json';
import { useField } from 'formik';
import { getAllowedCountries } from '@/features/countries/slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

const callingCodes = [];
for (const code of localCallingCodes) {
	if (callingCodes.includes(code) !== true) {
		callingCodes.push(code);
	}
}

type PersonalProps = {
	validatePassword: (value: string) => void,
	setServiceAllowed: (value: boolean) => void,
	passwordErrors: Array<string>,
	dirty: boolean,
	contriesList: LocalizedCountryNames<GetNameOptions>
}

interface Country {
	code: string,
	label: string
}

const Individual = ({ validatePassword, setServiceAllowed, passwordErrors, dirty, contriesList }: PersonalProps): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	const [countryCodes] = useState<Array<string>>(callingCodes);
	const [countryOptions] = useState<Array<Country>>(
		Object.keys(contriesList)
			.map($code => ({
				code: $code,
				label: contriesList[$code],
			})));
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [country, setCountry] = useState(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField('countryCode');
	const { setValue } = helpers;

	const allowedCountries = useSelector(
		(state: RootState) => state.allowedCountries
	);

	const countryCode = Object.keys(contriesList).find(key => contriesList[key] === country);
	const serviceIsAllowdInCountry = allowedCountries.list.includes(countryCode);


	useEffect(() => {
		if (country) {
			const codeMapping = areaCodes.filter(entry => entry.country === country);
			if (codeMapping.length === 1) {
				setValue(codeMapping[0].code);
			}
		}
		setServiceAllowed(serviceIsAllowdInCountry);
	}, [country]);


	useEffect(() => {
		if (allowedCountries.list.length === 0) {
			dispatch(getAllowedCountries());
		}
	}, []);

	return (
		<div>
			<Grid className="name" columns={2} stackable>
				<Grid.Column className="firstname">
					<Input
						label={t('form.fields.firstname')}
						name="firstName"
						errorPrompt />
				</Grid.Column>
				<Grid.Column className="lastname">
					<Input
						label={t('form.fields.lastname')}
						name="lastName"
						errorPrompt />
				</Grid.Column>
			</Grid>
			<Input
				fluid
				name="email"
				label={t('form.fields.email')}
				placeholder={t('form.placeholder.email')}
				errorPrompt
			/>
			<Divider section />
			<div>
				<Input
					icon={<Icon name={showPassword ? 'eye slash' : 'eye'} link onClick={() => { setShowPassword(!showPassword); }} />}
					name="password"
					label={t('form.fields.password')}
					placeholder={t('form.placeholder.password')}
					type={showPassword ? 'text' : 'password'}
					errorPrompt
					validate={validatePassword}
				/>
				<Input
					icon={<Icon name={showConfirmPassword ? 'eye slash' : 'eye'} link onClick={() => { setShowConfirmPassword(!showConfirmPassword); }} />}
					name="passwordConfirm"
					label={t('form.fields.confirmpassword')}
					placeholder={t('form.placeholder.confirmpassword')}
					type={showConfirmPassword ? 'text' : 'password'}
					errorPrompt >
				</Input>
			</div>
			<List className="validation-items">
				<List.Header as="h5" className="validationsheader">{t('registration.passwordvalidators.title')}</List.Header>
				<List.Item className={`${(dirty && passwordErrors.indexOf('len') === -1 ? 'passed' : '')}`}><Icon name="check circle outline" /> {t('registration.passwordvalidators.len')}</List.Item>
				<List.Item className={`${(dirty && passwordErrors.indexOf('spec') === -1 ? 'passed' : '')}`}><Icon name="check circle outline" /> {t('registration.passwordvalidators.spec')}</List.Item>
				<List.Item className={`${(dirty && passwordErrors.indexOf('upper') === -1 ? 'passed' : '')}`}><Icon name="check circle outline" /> {t('registration.passwordvalidators.upper')}</List.Item>
			</List>
			<Divider className="validationsdivider" section />
			<Input
				fluid
				name="country"
				label={t('form.fields.country')}
				placeholder={t('form.placeholder.country')}
				onChange={(e, v) => setCountry(v.value)}
				errorPrompt
				list="countries"
			/><datalist id='countries'>
				{countryOptions.map(c => <option key={c.label} value={c.label}>{c.label}</option>)}
			</datalist>
			{
				serviceIsAllowdInCountry ?
					<p className="color-green">
						Available services
					</p> :
					<p className="color-red">
						No services are available in your country
					</p>
			}
			<Grid className="phone" columns={2} stackable>
				<Grid.Column className="countrycode">
					<Input
						label={t('form.fields.code')}
						name="countryCode"
						placeholder='000'
						icon='plus'
						errorPrompt
						onChange={(e, v) => setValue(v.value)}
						iconPosition='left'
						list="countryCodes"
					/>
					<datalist id='countryCodes'>
						{countryCodes.map(c => <option key={c} value={c}>{c}</option>)}
					</datalist>
				</Grid.Column>
				<Grid.Column className="phonenumber">
					<Input
						fluid
						name="phoneNumber"
						errorPrompt
						label={t('form.fields.phone')} />
				</Grid.Column>
			</Grid>
			<Input
				className="birthdateinput"
				name="birthDate"
				label={t('form.fields.birthdate')}
				type="date"
				placeholder="dd/mm/yyyy"
				errorPrompt
			/>
			<Input
				label={t('form.fields.invitationCode')}
				name="invitationCode"
				errorPrompt />
		</div >);
};

export default Individual;
