import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './rootReducer';
import * as countries from 'i18n-iso-countries';
import Dashboard from './pages/Dashboard';
import Auth from './pages/Auth';
import NotFound from './pages/NotFound';
import LoadingPage from './pages/LoadingPage';
import Confirmation from './pages/Confirmation';
import Swal from './components/Swal';
import Interceptors from './components/Interceptors';
import ProtectedRoute from './ProtectedRoute';
import { getStatus } from '@features/status/statusSlice';
import { useTranslation } from 'react-i18next';
import './App.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BrowserRouter as Router, Redirect, Route, Switch, } from 'react-router-dom';
import Legal from '@/components/Legal/Legal';
import PageTop from '@/components/Shared/PageTop';
import MyAccount from '@/components/Dashboard/MyAccount';
import Support from '@/components/Support/FAQ';
import Terms from '@/components/Legal/Terms/Terms';
import AMLPolicy from '@/components/Legal/AMLPolicy/AMLPolicy';
import PrivacyPolicy from '@/components/Legal/PrivacyPolicy/PrivacyPolicy';
import Inbox from '@/components/Dashboard/Inbox/InboxTab';
import { Container } from 'semantic-ui-react';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Kyb from './components/Kyb/Kyb';

const App = (): React.ReactElement => {
	const { ready } = useTranslation('translations', { useSuspense: false });
	const dispatch = useDispatch();
	const { loading } = useSelector(
		(state: RootState) => state.status
	);

	useEffect(() => {
		dispatch(getStatus());
		// TODO switch between locales
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
	}, [dispatch]);

	if (loading || !ready) return <LoadingPage />;

	return (
		<Container fluid className="app-layout">
			<Router>
				<Navbar />
				<div className="app-body">
					<Interceptors />
					<Swal />
					<PageTop>
						<Switch>
							<Redirect from='/reset-password/:code' to='/auth/reset-password/:code' />
							<Route path="/auth">
								<Auth />
							</Route>
							<Route path='/legal'>
								<Legal />
							</Route>
							<ProtectedRoute exact path={'/support'} component={Support} />
							<ProtectedRoute exact path={'/inbox'} component={Inbox} />
							<ProtectedRoute exact path={'/terms'} component={Terms} />
							<ProtectedRoute exact path={'/amlpolicy'} component={AMLPolicy} />
							<ProtectedRoute exact path={'/privacypolicy'} component={PrivacyPolicy} />
							<ProtectedRoute exact path={'/account'} component={MyAccount} />
							<ProtectedRoute exact path={'/kyb'} component={Kyb} />
							<Route path='/confirm' component={Confirmation} />
							<ProtectedRoute path='/' component={Dashboard} />
							<Route component={NotFound} />
						</Switch>
					</PageTop>
				</div>
				<Footer />
			</Router >
		</Container>

	);
};

export default App;
