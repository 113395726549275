import React from 'react';
import { Container, Grid, Segment, Table, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import './AMLPolicy.css';

function AMLPolicy(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>AML policy</h1></Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<h1><strong>INTRODUCTION, PURPOSE AND RESPONSIBLE
					OFFICERS</strong></h1>
				<p><span>Cospay Limited (CL) is an Authorised Electronic Money Institution providing
					e-commerce and payment services:</span></p>
				<ul>
					<li><span>Multicurrency acquiring of VISA, MasterCard, American Express, UnionPay and other card
						schemes;</span>
					</li>
					<li><span>Authorised e-money;</span></li>
					<li><span>Merchant payouts;</span></li>
					<li><span>Economy multicurrency payments;</span></li>
					<li><span>SEPA direct debit.</span></li>
				</ul>

				<p><span>In particular, it contains the information which all members of staff need to be aware of in
					order to prevent the business being used to launder the proceeds of crime or terrorist
					financing.</span></p>
				<p><span>Cospay is aware that API has in the past been targets of organised crime seeking to launder the
					proceeds of illicit activity.</span></p>

				<p><span>Cospay will always seek to disrupt this activity by cooperating fully with the authorities and
					reporting all suspicious activity to National Crime Agency (NCA).</span></p>

				<p><span>Money laundering is generally defined as engaging in acts designed to conceal or disguise the
					true origins of criminally derived proceeds so that the proceeds appear to have derived from
					legitimate origins or constitute legitimate assets.</span></p>
				<h1><strong>2 RED FLAGS</strong></h1>
				<h2><strong>2.1 Placement Stage</strong></h2>
				<p><span>Cash first enters the financial system at the </span><strong>&quot;placement&quot;
					stage</strong><span>, where the cash generated from criminal activities is converted into monetary
					instruments.</span></p>
				<p><span>Such monetary instruments could be: money orders or traveller’s checks,
					deposited into accounts at financial institutions, dividing the cash into smaller amounts and make
					various
					deposits into one or more accounts at one or more banks; customer opens several accounts in
					different names
					at different institutions; employ or persuade others to deposit funds for them; purchasing goods
					such as
					jewellery, art and other assets with a view to reselling them at a later date; making deposits with
					the help
					of employees of the relevant financial institution.</span></p>

				<p><strong>Red flags:</strong></p>
				<ul>
					<li><span>transactions form multiple accounts for the same receiver;</span></li>
					<li><span>transactions from one account to multiple receivers;</span></li>
					<li><span>transactions coming from accounts created by auction houses, betting sites or e-wallets
						providers mainly used by gambling and betting sites;</span>
					</li>
					<li><span>transactions from pre-paid credit cards.</span></li>
				</ul>
				<h2><strong>2.2 Layering Stage:</strong></h2>
				<p><span>At the </span><strong>&quot;layering&quot; stage</strong>
					<span>, the funds are transferred or moved into other accounts or other financial
						institutions to further separate the money from its criminal origin. As example: Selling assets
						or switching
						to other forms of investment; transferring money to accounts at other financial institutions;
						wiring
						transfers abroad (often using shell companies); depositing cash in overseas banking
						systems.</span></p>

				<p><strong>Red Flags:</strong></p>
				<ul>
					<li><span>Outgoing transactions to countries known as
						“off-shore” banking countries;</span></li>
					<li><span>Customers are using funds of a sales of assets
						like as house or jewellery;</span></li>
					<li><span>Customers are using the funds for purchases of
						real estate, buying stakes in companies, or other large assets;</span></li>
					<li><span>Incoming/outgoing transactions from private
						people to a company;</span></li>
					<li><span>Prepaid credit card transferred funds to bank
						accounts (unusual that the receiver is more financially inclusion than the remitter).</span>
					</li>
				</ul>

				<h2><strong>2.2 Integration Stage:</strong></h2>
				<p><span>At the </span><strong>&quot;integration&quot; stage</strong><span>, the funds are reintroduced
					into the economy and used to purchase legitimate
					assets or to fund other criminal activities or legitimate businesses, for example - an inheritance,
					loan
					payments, asset sales abroad.</span></p>

				<p><span>Terrorist financing may not involve the proceeds of criminal conduct, but
					rather an attempt to conceal either the origin of the funds or their intended use, which could be
					for
					criminal purposes.</span></p>
				<p><span>Legitimate sources of funds are a key difference between terrorist financiers
					and traditional criminal organizations.</span></p>

				<p><span>In addition to charitable donations, legitimate sources include foreign
					government sponsors, business ownership and personal employment. Although the motivation differs
					between
					traditional money launderers and terrorist financiers, the actual methods used to fund terrorist
					operations
					can be the same as or similar to methods used by other criminals to launder funds.&nbsp;</span></p>
				<p><span>Funding for terrorist attacks does not always require large sums of money and
					the associated transactions may not be complex.</span></p>
				<p><span>All members of staff are at risk of committing a criminal offence if they
					assist in a criminal transaction by missing the warning signs.</span></p>
				<h1><strong>3 LEGISLATION</strong></h1>
				<p><span>The legislation in United Kingdom governing money laundering and Terrorist
					Financing and the fight against it is contained in the following:&nbsp;</span></p>

				<ol>
					<li><strong>Proceeds of Crime Act 2017 (as
						amended);</strong></li>
					<li><strong>Act 2000 (as amended by the
						Anti-terrorism, Crime and Security Act 2001);</strong></li>
					<li><strong>Anti-Money Laundering Act 2018;</strong>
					</li>
					<li><strong>UK bribery act 2010;</strong></li>
					<li><strong>Payment Services Regulations
						2017.</strong></li>
					<li><strong>Electronic Money Regulations
						2011</strong></li>
				</ol>
				<p><span>&nbsp;</span></p>
				<p><span>In addition, but no limited, references, guidance and instruction are given
					in HM Treasury Sanctions notices and news releases and the Financial Services
					Authority.&nbsp;</span></p>
				<p><span>It is important to note that United Kingdom legislation in respect of money
					laundering is “all crimes legislation”.</span></p>
				<p><span>As a company involved in the provision of financial services, both the
					company itself and our employees must be aware of the offences in case of violation of the
					legislation. The
					possible offences for violation are described in Annex 2.</span></p>
				<h2><strong>3.1 Roles and responsibilities</strong></h2>
				<h3><strong>3.1.1 Senior Management</strong></h3>
				<p><span>Responsible for overall compliance policy of Cospay and ensuring adequate
					resources are provided for the proper training of staff and the implementing of risk systems. This
					includes
					computer software to assist in oversight.</span></p>
				<p><span>Senior management will be sent monthly updates by the MLRO on compliance.
					They will also receive and consider the annual MLRO report and implement any recommendations made
					within
					it.&nbsp;</span></p>
				<p><span>Assistance may be given to the MLRO in the preparation of the AML
					manual.</span></p>
				<h3><strong>3.1.2 MLRO</strong></h3>
				<p><span>Cospay has appointed a Money Laundering Reporting Officer/Nominated Officer
					(MLRO/NO).</span></p>
				<p><br/><br/></p>
				<p><span>MLRO Is responsible for below listed:</span></p>

				<ul>
					<li><span>To receive disclosures from employees (also
						known as Suspicious Activity Report-SAR’s);</span></li>
					<li><span>To decide if disclosures should be passed on to
						the National Crime Agency (NCA);</span></li>
					<li><span>To review all new laws and deciding how they
						impact on the operational process of the company;</span></li>
					<li><span>To prepare a written procedures manual and
						making it available to all staff and other stakeholders;</span></li>
					<li><span>To make sure appropriate due diligence is
						carried out on customers and business partners;</span></li>
					<li><span>To receive internal Suspicious Activity Reports
						(SARs) from staff;</span></li>
					<li><span>To keep and review records of all decisions
						relating to SARs appropriately;</span></li>
					<li><span>To ensure that staff receive appropriate
						training, when they join and that the receive regular refresher training on annual basis or if
						necessary;</span></li>
					<li><span>To monitor business relationships and record
						reviews and decisions taken;</span></li>
					<li><span>To make a decision to immediately terminate
						business relationship with merchant who found in sanction list;</span></li>
					<li><span>To make a decision on continuing or terminating
						trading activity with particular customer;</span></li>
					<li><span>To make sure that all business records are kept
						for at least five years from the date of the last customer transaction.</span></li>
				</ul>
				<h3><strong>3.1.3 Staff</strong></h3>
				<p><span>Responsible for considering the AML manual and understanding
					responsibilities.&nbsp;</span></p>
				<p><span>Ensure Cospay procedures adhered to and obtain all documentary evidence as
					outlined within AML Manual. Ensure that all suspicious circumstances are reported to MLRO.</span>
				</p>

				<h2><strong>3.2 AML risk assessment</strong></h2>
				<p><span>As per the Money Laundering Regulations Act 2017, each API must exercise a
					‘</span><em><span>risk-based approach</span></em><span>’ to its customers, products and business
					practices.</span></p>

				<p><span>Cospay operates a regimented system based upon processes, our 5-step approach
					is:</span></p>
				<ul>
					<li><span>Identify the money laundering risks that are
						relevant to our business;</span></li>
					<li><span>Carry out periodic risk assessments on various
						parts of our business, focusing on customer behaviour, delivery channels, patterns,
						irregularities;</span>
					</li>
					<li><span>MLRO to design and put in place effective
						controls to manage and reduce the impact of the risks;</span></li>
					<li><span>MLRO/Compliance to monitor the controls and
						improve efficiency;</span></li>
					<li><span>Maintain records of processes/systems that were
						checked and why we checked them.</span></li>
				</ul>

				<p><span>The results of Cospay annual risk assessment should be presented and approved by
					the Board of directors.</span></p>

				<p><span>As a small sized entity, we review ourselves internally and base our
					assessment on our chosen business models, our products and services.&nbsp; Cospay risk assessment
					process is
					described in Annex 3.</span></p>

				<h2><strong>3.3 Risk based approach</strong></h2>
				<p><span>Our policies are formed by using the FATF guidance on the
				</span><strong>Risk-Based Approach, </strong><span>that AEMI should adhere in order
					to effectively combat Money Laundering and Terrorist Financing. The FATF guidance supports Cospay in
					the
					development of:</span></p>

				<p><span>1) &nbsp; A common understanding of what the risk-based approach
					involves;</span></p>
				<p><span>2) &nbsp; Outlining the high-level principles involved in applying a
					risk-based approach;</span></p>
				<p><span>3) &nbsp; Promoting Cospay in the eyes of its partners, as our risk-based
					approach indicates a good public and private sector practice.</span></p>

				<p><span>Cospay applies Due diligence at the start of customer engagement by identifying
					and verifying the customer identity on the basis of documents, data or information obtained from a
					reliable
					and independent source.</span></p>
				<p><span>Cospay identifies where there is a beneficial owner who is not the customer, the
					beneficial owner and taking adequate measures, on a risk sensitive basis to verify his identity
					(including
					in the case of a legal person, trust or similar legal arrangement, measures to understand the
					ownership and
					control structure).</span></p>
				<p><span>Cospay creates policies and procedures that relate to customer due diligence,
					ongoing Monitoring, internal reporting and record keeping.&nbsp;</span></p>
				<p><span>If any suspicions are identified, then these should be raised to the MLRO/NO
					for further investigation by completing the relevant internal Suspicious Activity Report (SAR)
					form.</span></p>
				<h1><strong>4 CUSTOMER DUE DILIGENCE</strong></h1>
				<p><span>The purpose of the Customer Due Diligence (CDD) process is to collect,
					process, verify and keep the information about the Cospay customers, due to minimize the possible and
					potential
					ML/TF risks.</span></p>
				<p><span>Thus, the main question, but not the overall, needs to be asked and
					understood about the Cospay customer:</span></p>

				<p><span>Who is the client?</span></p>
				<ul>
					<li><span>What is the geographical location of the
						client’s?</span></li>
					<li><span>Residence?</span></li>
					<li><span>Assets?</span></li>
					<li><span>Business interests?</span></li>
					<li><span>What is the nature of the client’s
						business interests/occupation?</span></li>
					<li><span>What is the commercial rationale for the
						relationship between the client and the Cospay (what is the client seeking to achieve)?</span></li>
					<li><span>What is the client’s source of
						funds?</span></li>
					<li><span>What is the client’s source of
						wealth?</span></li>
					<li><span>What has been the historical pattern of the
						client’s relationship activity with the business, and has it been consistent with what was
						expected
						at the outset of the relationship?</span></li>
					<li><span>Is the current or proposed activity consistent
						with the client’s profile and commercial objectives?</span></li>
				</ul>

				<p><span>Cospay is required to perform CDD not only prior entering the relationship with
					the customer, but also in case of changes of in the basis of the
					relationship:</span><span>&nbsp;</span></p>
				<p><span>This may be where:&nbsp;</span></p>
				<ul>
					<li><span>new products or services are entered
						into;&nbsp;</span></li>
					<li><span>customer carrying out an ‘occasional
						transaction’ worth €15,000 (MLR&nbsp; 2007);</span></li>
					<li><span>doubts about a customer’s identification
						information that has been obtained previously;</span></li>
					<li><span>when it’s necessary for existing customers
						- for example if their circumstances change</span></li>
					<li><span>a change in a customer&apos;s employment or other
						circumstances takes place;</span></li>
					<li><span>the stated activity or turnover of a customer
						entity changes or increases; or&nbsp;</span></li>
					<li><span>the nature, volume or size of transactions
						increases;</span></li>
					<li><span>when suspect money laundering or terrorist
						financing:</span><span>&nbsp;</span></li>
					<li><span>the ownership, management, residence changed
						etc.</span></li>
					<li><span>constitutes a transfer of funds exceeding €
						1000 or equivalent in other currency (MLR&nbsp; 2018) where transfer is at least partially
						carried out by
						electronic means on behalf of a payer through a payment service provider, with a view to making
						funds
						available to a payee through a payment service provider, irrespective of whether the payer and
						the payee
						are the same person and irrespective of whether the payment service provider of the payer and
						that of the
						payee are one and the same, including:</span></li>
				</ul>

				<ul>
					<li><span>Credit transfer - direct debit transactions
						denominated in euro within the EU where both the payer’s payment service provider and the
						payee’s payment service provider are located in the EU or where the sole payment service
						provider
						involved in the payment transaction is located in the EU;</span></li>
					<li><span>a direct debit - national or cross-border
						payment service for debiting a payer’s payment account, where a payment transaction is initiated
						by
						the payee on the basis of the payer’s consent;</span></li>
					<li><span>a money remittance - a payment service where
						funds are received from a payer, without any payment accounts being created in the name of the
						payer or
						the payee, for the sole purpose of transferring a corresponding amount to a payee or to another
						payment
						service provider acting on behalf of the payee, and/or where such funds are received on behalf
						of and made
						available to the payee whether national or cross border;</span></li>
					<li><span>a transfer carried out using a payment card, an
						electronic money instrument, or a mobile phone, or any other digital or IT prepaid or post-paid
						device
						with similar characteristics.</span></li>
				</ul>
				<h2><strong>4.1 CDD procedure</strong></h2>
				<p><span>For all customers CDD must be completed prior enter into the relationship and
					it is necessary to complete the steps as follows:</span></p>

				<ul>
					<li><span>Perform identification and verification –
						identify and where required verify the identity of the perspective customer and related
						parties;</span>
					</li>
					<li><span>Screen all customers and related parties against
						the HM Treasury sanctions list, EU list and OFAC SDN list, UN list;</span></li>
					<li><span>Screen all customers and related parties to
						determine if there are any PEPs associated with the customer, by using public, trustable and
						opened
						information source;</span></li>
					<li><span>Determine customer risk rating;</span></li>
					<li><span>Complete EDD as required by the risk rating
						(Annex 8. New Customer on-boarding).</span></li>
				</ul>

				<h2><strong>4.2 Customer’s minimum information</strong>
				</h2>

				<h3><strong>4.2.1 Private person</strong></h3>
				<ul>
					<li><span>Name, surname;</span></li>
					<li><span>Original and current identification evidencing
						nationality or residence and bearing a photograph or similar safeguard, such a passport,
						national
						identification card or alien identification card with date of birth and place of
						birth;&nbsp;&nbsp;</span>
					</li>
					<li><span>Residency address and postal code;</span></li>
					<li><span>Officially certified copies of the above
						documents;</span></li>
					<li><span>Disclaimer/questionnaire for the origin of funds
						not being derived from the proceeds of crime;</span></li>
				</ul>
				<ul>
					<li><span>Positive identity verification by
						Jumio/staff:&nbsp;</span></li>
				</ul>
				<ul>
					<li><span>ID Verification: Verifies that a
						government-issued ID document (e.g. driver’s license, ID card or passport) is legitimate and
						authentic.</span></li>
					<li><span>Identity Verification: Leverages biometric
						facial recognition, AI, compliant machine learning, liveness detection, proprietary algorithms
						and
						verification experts to authenticate customers’ real-world identity by comparing the picture on
						a
						valid government-issued ID with a selfie video.</span></li>
					<li><span>Document Verification: Automatically extracts
						key information, including proof of address from utility bills, credit card statements and bank
						statements
						based on pictures taken by a user’s smartphones</span></li>
				</ul>
				<ul>
					<li><span>ComplyAdvantage screening result and put person
						monitoring “ON”- system will constantly and automatically monitor (Every 24 hours) customer in
						the background and receive proactive alerts if a customer ever becomes a potential risk by full
						global
						data base of Sanctions (</span><a
						href="https://www.treasury.gov/"><span>https://www.treasury.gov</span></a><span>, HMT, EU
						Sanctions), PEPs, Adverse Media, Law Enforcement, regulator’s blacklists, asset freezes and
						trading
						suspensions. Data is classified using FATF guidelines to enable to detect risk levels at
						onboarding and
						understand when a client changes risk level – PEP class 1,2,3 &amp; 4.</span></li>
				</ul>
				<ul>
					<li><span>In addition to the information and documentation
						provided by or on behalf of the Client and obtained from the Third parties (where relevant), the
						Company
						has to check the below sources if the provided information is correct or to establish the
						missing
						information regarding the Client:</span></li>
				</ul>
				<ol>
					<li><span>SDN list. List of specially designated nationals
						and blocked persons. SDN list is a publication of OFAC which lists individuals and organizations
						with whom
						United States citizens and permanent residents are prohibited from doing business;</span></li>
					<li><span>https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/default.aspx;</span>
					</li>
					<li><span>EU sanctions list. Consolidated list, containing
						the names and identification details of all persons, groups and entities targeted by financial
						restrictions.
						https://eeas.europa.eu/headquarters/headquarters-homepage/8442/consolidated-list-sanctions_en.</span>
					</li>
				</ol>
				<h3><strong>4.2.2 Legal entities</strong></h3>
				<ul>
					<li><span>Company’ name;&nbsp;</span></li>
					<li><span>Beneficial owner name;</span></li>
					<li><span>Ownership memorandum, article of association
						etc.;</span></li>
					<li><span>Legal and physical address;</span></li>
					<li><span>Other relevant documentation such as
						company’s activity details, expected turnover or expected etc.;&nbsp;</span></li>
					<li><span>Officially certified copies of the above
						documents;</span></li>
					<li><span>Expected type and volume of transaction;</span>
					</li>
					<li><span>Main counterparties and countries;</span></li>
					<li><span>During manual check some key data like Business
						Customer/Merchant name, directors&apos; names, URL address and related phones, emails and
						addresses should be
						checked along with phrases that may occur in regard to the business model (i.e. crime, scam,
						review) to
						narrow search results to the results really interesting in terms of international investigation
						(i.e. if
						merchant&apos;s director is a felon or a convict or known fraudster);</span></li>
					<li><span>Disclaimer/questionnaire for the origin of funds
						not being derived from the proceeds of crime;</span></li>
					<li><span>Positive Jumio/staff identity
						verification;</span></li>
					<li><span>Complyadvantage screening result;</span></li>
					<li><span>G2 web services scoring result.</span></li>
				</ul>
				<h3><strong>4.2.3 Website compliance check</strong></h3>
				<ul>
					<li><span>Cospay Limited implements checks of Business
						Customer/Merchant websites that must comply to the following requirements. Every website that is
						about to
						be used for ecommerce processing must comply to the specific requirements regulated by card
						schemes
						(Visa/MC):</span></li>
					<li><span>Clear posting of the Refund and Return
						Policy;</span></li>
					<li><span>Clear Privacy Policy;</span></li>
					<li><span>Clear statement on website regarding security
						controls used to protect customers;</span></li>
					<li><span>Clear posting of the Terms and
						Conditions;</span></li>
					<li><span>Clear posting of the customer service telephone
						number and email address;</span></li>
					<li><span>Clear posting of delivery methods and delivery
						times (if applicable);</span></li>
					<li><span>Clear posting of the company legal name and
						corporate address;</span></li>
					<li><span>Clear posting of the billing descriptor on the
						payment page;</span></li>
					<li><span>Card Schemes logos visible on the payment
						page.</span></li>
				</ul>
				<p><br/><br/></p>
				<h3><strong>4.2.4 Non face-to-face customers</strong>
				</h3>
				<p><span>Non face-to-face customers present an inherent risk of impersonation fraud
					which Cospay must take account of in framing our internal policies and procedures.</span></p>
				<p><span>The Money Laundering Regulation 2018 requires that we apply enhanced due
					diligence (EDD) measures, on a risk-sensitive basis, when Cospay doesn’t physically meet its
					customers.&nbsp;</span></p>
				<p><span>Therefore, Cospay must apply additional verification checks to mitigate the risk
					of impersonation fraud. These checks may include:</span></p>

				<ul>
					<li><span>Requiring additional documents, data or
						information to verify the customer’s identity;</span></li>
					<li><span>Applying supplementary measures to verify the
						documents supplied;</span></li>
					<li><span>Requiring the first transaction to be carried
						out through an account in the customer’s name with a UK or EU regulated bank or one from a
						comparable jurisdiction;</span></li>
					<li><span>Telephone contact with the customer at a home or
						business number which has already been verified, using it to verify additional aspects of
						personal
						identity information provided during the application process;</span></li>
					<li><span>Communicating with the customer at an address
						which has already been verified, for example by letter.</span></li>
				</ul>

				<p><span>While the documents obtained and seen may be similar to those required in
					normal ‘individual circumstances’ it is important to try and obtain some independent
					corroboration, which may include having them certified by other banks, lawyers, accountants,
					diplomatic
					missions.</span></p>

				<h2><strong>4.3 Customers risk categorization and
					statuses</strong></h2>
				<p><span>Cospay by using risk-based approach is categorized the risks posed by clients on
					the following basis:</span></p>
				<ul>
					<li><span>geographic area of operation;</span></li>
					<li><span>product;</span></li>
					<li><span>customer;</span></li>
					<li><span>delivery channel.</span></li>
				</ul>

				<p><span>Clients of Cospay will be classified according to their risk level:</span></p>
				<ul>
					<li><span>Low Risk;</span></li>
					<li><span>Medium Risk;</span></li>
					<li><span>High Risk.</span></li>
				</ul>

				<p><span>In determining a risk assessment for a customer, the presence of one factor
					that might indicate higher risk does not automatically establish that a customer is higher
					risk.&nbsp;</span></p>
				<p><span>Equally, the presence of one lower-risk factor should not automatically lead
					to a determination that a customer is lower risk.</span></p>

				<p><span>The following is a non-exhaustive list of factors and types of evidence of
				</span><strong>potentially</strong> <strong>low risk:</strong></p>

				<p><span>Customer risk factors:</span></p>
				<ul>
					<li><span>public companies listed on a stock exchange and
						subject to disclosure requirements (either by stock exchange rules or through law or enforceable
						means),
						which impose requirements to ensure adequate transparency of beneficial ownership;</span></li>
					<li><span>public administrations or enterprises;</span>
					</li>
					<li><span>customers that are resident in geographical
						areas of lower risk;</span></li>
					<li><span>certain other regulated firms in the financial
						sector in ‘equivalent jurisdictions’ (those jurisdictions providing a level of regulation
						equivalent to EU/UK standards and relevant for inclusion as a mitigating factor);</span></li>
					<li><span>independent legal professionals;</span></li>
					<li><span>UK/EU public authorities;</span></li>
					<li><span>community institutions;</span></li>
					<li><span>certain life assurance;</span></li>
					<li><span>certain pension funds;</span></li>
					<li><span>certain low risk products;</span></li>
					<li><span>child trust funds.</span></li>
				</ul>

				<p><span>&nbsp;Product, service, transaction or delivery channel risk factors:</span></p>
				<ul>
					<li><span>life insurance policies for which the premium is
						low;</span></li>
					<li><span>insurance policies for pension schemes if there
						is no early surrender option and the policy cannot be used as collateral;</span></li>
					<li><span>a pension, superannuation or similar scheme that
						provides retirement benefits to employees, where contributions are made by way of deduction from
						wages,
						and the scheme rules do not permit the assignment of a member&apos;s interest under the
						scheme;</span>
					</li>
					<li><span>financial products or services that provide
						appropriately defined and limited services to certain types of customers, so as to increase
						access for
						financial inclusion purposes;</span></li>
					<li><span>products where the risks of money laundering and
						terrorist financing are managed by other factors such as purse limits or transparency of
						ownership (e.g.
						certain types of electronic money).</span></li>
				</ul>

				<p><span>Geographical risk factors:</span></p>
				<ul>
					<li><span>EU member states;</span></li>
					<li><span>third countries having effective AML/CFT
						systems;</span></li>
					<li><span>third countries identified by credible sources
						as having a low level of corruption or other criminal activity;</span></li>
					<li><span>third countries which, on the basis of credible
						sources such as mutual evaluations, detailed assessment reports or published follow-up reports,
						have
						requirements to combat money laundering and terrorist financing consistent with the revised FATF
						Recommendations and effectively implement those requirements.</span></li>
				</ul>
				<h2><strong>&nbsp;4.4 Enhanced due diligence</strong>
				</h2>
				<p><span>Cospay’s </span><strong>Enhanced Due Diligence (</strong><span>EDD) policy is designed to obtain
					as much information as possible in order to
					ensure the validity of the transaction and that Cospay complies with ML Regulation (2017), POCA (2002),
					Terrorism Act (2000) and the EU Money Laundering Directives.</span></p>
				<p><span>In practical terms, EDD will include analysis of:</span></p>
				<ul>
					<li><span>taking reasonable measures to establish a
						customer’s source of wealth – source of wealth is distinct from source of funds, and describes
						the activities that have generated the total net worth of a person, i.e. those activities that
						have
						generated a customer’s income and property;</span></li>
					<li><span>considering whether it is appropriate to take
						measures to verify source of funds and wealth from either the customer or independent sources
						(such as the
						Internet, public or commercially available databases);</span></li>
					<li><span>obtaining further CDD information
						(identification information and relationship information);</span></li>
					<li><span>taking additional steps to verify the CDD
						information obtained;</span></li>
					<li><span>commissioning due diligence reports from
						independent experts to confirm the veracity of CDD information held;</span></li>
					<li><span>requiring more frequent reviews of business
						relationships (twice per year);</span></li>
					<li><span>carrying out stricter monitoring of transactions
						and setting lower transaction thresholds for transactions connected with the business
						relationship,
						and</span></li>
					<li><span>setting alert thresholds for automated
						monitoring at a lower threshold for PEPs.</span></li>
				</ul>

				<p><span>The degree of EDD must be determined by MLRO on a case-by-case basis.</span></p>

				<h3><strong>4.4.1 High risk factors</strong></h3>
				<p><span>Customers to who permit one or more below mentioned risk factors are
					considered to be rated as High Risk.</span></p>

				<p><span>Customer risk factors:</span></p>
				<ul>
					<li><span>the business relationship is conducted in
						unusual circumstances;</span></li>
					<li><span>High cash turnover businesses: casinos, bars,
						clubs, taxi firms, launderettes, takeaway restaurants;</span></li>
					<li><span>Money service businesses: cheque encashment
						agencies, bureaux de change, money transmitters;&nbsp;</span></li>
					<li><span>Gaming and gambling businesses;</span></li>
					<li><span>Computer/high technology/telecom/mobile phone
						sales and distribution, noting especially the high propensity of this sector to VAT ‘Carousel’
						fraud;</span></li>
					<li><span>Companies registered in one offshore
						jurisdiction as a non-resident company with no local operations, but managed out of another, or
						where
						beneficial owners with significant interests in the company are resident in a high-risk
						jurisdiction;</span></li>
					<li><span>Unregistered charities based or headquartered
						outside the UK, ‘foundations’, cultural associations and the like, particularly if centred on
						certain target groups, including specific ethnic communities, whether based in or outside the UK
						(see FATF
						Typologies Report 2003/4 under ‘Non-profit organisations’ – at </span><a
						href="http://www.fatf-gafi.org/"><span>www.fatf-gafi.org</span></a><span> );</span></li>
					<li><span>customers that are resident in geographical
						areas of high risk;</span></li>
					<li><span>companies that have nominee shareholders or
						shares in bearer form;</span></li>
					<li><span>companies that registered in a high-risk
						jurisdiction;</span></li>
					<li><span>the ownership structure of the company appears
						unusual or excessively complex given the nature of the company&apos;s business;</span></li>
					<li><span>a Lawyer, an Accountant, an Estate agent or if
						they work in/operate a casino;</span></li>
					<li><span>PEPs.</span></li>
				</ul>

				<p><span>Product, service, transaction or delivery channel risk factors:</span></p>

				<ul>
					<li><span>private banking;</span></li>
					<li><span>products or transactions that might favour
						anonymity;</span></li>
					<li><span>transactions, without certain safeguards, such
						as electronic signatures;</span></li>
					<li><span>payment received from unknown or un-associated
						third parties;</span></li>
					<li><span>new products and new business practices,
						including new delivery mechanism, and&nbsp;</span></li>
					<li><span>the use of new or developing technologies for
						both new and pre-existing products;</span></li>
				</ul>

				<p><span>Geographical risk factors:</span></p>

				<ul>
					<li><span>countries providing funding or support for
						terrorist activities, or that have designated terrorist organisations operating within their
						country;</span></li>
					<li><span>countries subject to sanctions, embargos or
						similar measures issued by, for example, the European Union or the United Nations;</span></li>
					<li><span>countries identified by credible sources as
						having significant levels of corruption or other criminal activity;</span></li>
					<li><span>countries identified by credible sources, such
						as mutual evaluations, detailed assessment reports or published follow-up reports, as not having
						effective
						AML/CTF systems;</span></li>
				</ul>
				<p><span>For easer Geographical risk determination for current country will be
					utilised internet source </span><a
					href="http://www.knowyourcountry.com/index.html"><span>http://www.knowyourcountry.com/index.html</span></a><span>.</span>
				</p>
				<p><span>The determination of the risk level will be the responsibility of the MLRO/NO
					or person nominated.</span></p>

				<h2><strong>4.5 Subjects of EDD</strong></h2>
				<p><span>For some particular customers, products or transactions in the case of higher
					risk situations enhanced due diligence (EDD) measures must be applied on a risk sensitive
					basis:</span></p>

				<ul>
					<li><span>Potential or when an existing customer is
						identified as a PEP’s close relative;</span></li>
					<li><span>Non-face-to-face customers;</span></li>
					<li><span>Customers rated as High Risk;</span></li>
					<li><span>When establishing a correspondent banking
						relationship with an institution in a non-EEA;</span></li>
					<li><span>Customers who make transmissions to
						jurisdictions which may be a risk and/or are more likely to receive funds through a financial
						corridor,
						even if they are not listed on the FATF website. This especially where the transaction is: large
						and /or
						claimed to be for charitable purpose.</span></li>
				</ul>
				<p><span>&nbsp;</span></p>
				<p><strong>All high risk, PEP’s close relatives and “non
					face-to-face” client accounts will
					only be opened on the approval of the MLRO.</strong></p>

				<h2><strong>4.6 Provision of Exemptions</strong></h2>
				<p><span>An exemption may be granted if documents are not available and other reliable
					substitutes can be used. MLRO may only grant an exemption where he is clearly required, or where
					practical
					experience reveals that it is necessary to do so.</span></p>

				<p><span>All exemptions will be considered on a case by case basis.&nbsp;</span></p>

				<p><span>Cospay has adopted a risk-based approach to achieving its regulatory objectives
					and exemptions should not be considered as a way to avoid meeting our regulatory obligations.</span>
				</p>

				<p><span>Careful consideration will be given to issues of transparency, equity and
					competitive neutrality in issuing exemptions. MLRO will assess the potential implications of
					applying an
					exemption and aims to adopt a consistent approach, taking account of the facts and circumstances
					particular
					to each case.&nbsp;</span></p>

				<p><span>Request for Exemptions from standard Customer Identification Process
					requirements may be received from AML and Risk department in circumstances where, taking account of
					the CDD
					which has been obtained, MLRO is satisfied that the ML/TF risk has been adequately
					addressed.&nbsp;</span></p>
				<p><span>AML and Risk department must use the &quot;E-mail Exemption Request&quot; when
					requesting an exemption from the Customer Identification Process. The completed e-mail must be sent
					to MLRO
					and must be approved by return of e-mail by MLRO before any exemption can be provided.</span></p>
				<h1><strong>5 SANCTION SCREENING REQUIRMENTS</strong>
				</h1>
				<p><span>Taking into account the cross-border business of Cospay Limited, it is very
					carefully processing its customers CDD, EDD, ongoing transaction monitoring and other activities to
					prevent
					possible violation of the ML/TF and other limitations/restrictions.&nbsp;</span></p>
				<p><span>If any Sanction/Limitations/Restriction is determined, Cospay immediately
					terminates any relationships with this customer, and perform SAR to UK FIU, not longer then 24
					hours.&nbsp;</span></p>

				<p><span>As Cospay will use automated screening program ComplyAdvantage, and G2 web
					services, with ‘fuzzy matching’ logic and which is calibrated in accordance to Cospay risk
					level,&nbsp;once the integrated screening lists (including OFAC SDN list) will be updated within the
					program, the screening process will be performed using the most recent lists immediately, but in any
					case,
					not later than within 1 week after the screening lists updated.</span></p>

				<h2><strong>5.1 Parties to be screened</strong></h2>
				<ul>
					<li><span>New/prospective customers including all related
						parties (directors, beneficial owners etc.) </span><span>prior to activation of
						account/at a minimum before funds can be are paid/withdrawn;</span><span>&nbsp;</span></li>
					<li><span>Existing customers including all related parties
						(directors, beneficial owners etc.) </span><span>on weekly bases;</span>
					</li>
					<li><span>All parties to cross-border inbound and outbound
						payments in real time bases </span><span>before funds are released</span><span>:</span></li>
				</ul>
				<ul>
					<li><span>The following parties and payments information
						must be screened:</span></li>
				</ul>
				<ul>
					<li><span>Remitter;</span></li>
					<li><span>Beneficiary;</span></li>
					<li><span>Remitter’s bank;</span></li>
					<li><span>Beneficiary’s bank;</span></li>
					<li><span>Ordering bank;</span></li>
					<li><span>Intermediaries/correspondents;</span></li>
					<li><span>All countries involved;</span></li>
					<li><span>Currency;</span></li>
					<li><span>Free text fields.</span></li>
				</ul>
				<ul>
					<li><span>Employee, suppliers and third parties-
						p</span><span>rior to establishing the relationship and further twice a
						year.</span></li>
				</ul>

				<h2><strong>5.2 Matching standards and
					investigation</strong></h2>
				<p><span>Potential match investigation must include techniques to be possible to
					compare names, addresses, strings and partial strings, business names, spelling errors, postal
					codes, tax ID
					numbers, data that sounds similar (such as “John” and “Jon”) and more.</span></p>
				<p><span>ComplyAdvantage has an algorithm in place to determine issues related to
					spelling and misnaming.&nbsp;</span></p>

				<h3><strong>5.2.1 Direct Match or Exact Match</strong>
				</h3>
				<p><span>A matching relationship between the two records is direct when these two
					records are a match by the underlying rule:</span></p>

				<Table>
					<TableBody>
						<TableRow>
							<TableCell>
								<p><strong>Payment String&nbsp;</strong></p>
							</TableCell>
							<TableCell>
								<p><strong>Sanction Target&nbsp;</strong></p>
							</TableCell>
							<TableCell>
								<p><strong>Matching Decision&nbsp;</strong></p>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<p><span>David Carlos&nbsp;</span></p>
							</TableCell>
							<TableCell>
								<p><span>David Carlos&nbsp;</span></p>
							</TableCell>
							<TableCell>
								<p><span>Match&nbsp;</span></p>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<p><span>Osama Bin Laden&nbsp;</span></p>
							</TableCell>
							<TableCell>
								<p><span>Osama Bin Laden&nbsp;</span></p>
							</TableCell>
							<TableCell>
								<p><span>Match&nbsp;</span></p>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<p><span>Fidel Castro&nbsp;</span></p>
							</TableCell>
							<TableCell>
								<p><span>Fidel Castro&nbsp;</span></p>
							</TableCell>
							<TableCell>
								<p><span>Match&nbsp;</span></p>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<p><br/><br/></p>
				<h3><strong>5.2.2 Indirect Match</strong></h3>
				<p><span>A matching relationship between two records is indirect when they do not
					match each other; however, each of these matches may match a third record.</span></p>

				<Table>
					<tbody>
					<TableRow>
						<TableCell>
							<p><strong>Payment String&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><strong>Sanction Target&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><strong>Matching Decision&nbsp;</strong></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>David Carlos&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Not on the list&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Not a match&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>John Peter&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Not on the list&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Not a match&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>David Peter&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>David Peter&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Match to Sanction List&nbsp;</span></p>
						</TableCell>
					</TableRow>
					</tbody>
				</Table>

				<h3><strong>5.2.3 Partial Match</strong></h3>
				<p><span>Possible matches when customer information is the same or similar to the
					watch list entity information. Two records show this type of relationship (very common in real life)
					when
					some (not all) elements of the first record match to some (not all) elements of the second record. A
					typical
					example will be the records corresponding to father and son living at the same address where many
					elements
					such as the last name, address and residence phone number might be matching, but the first name (and
					probably the middle name), mobile numbers, e-mail addresses and other such fields will not match.
					Extended
					families usually share a common last name.</span></p>

				<p><span>Additional information such as address, identification number, gender, height
					and age are needed to help determine the true match through further investigation.</span></p>

				<Table>
					<tbody>
					<TableRow>
						<TableCell>
							<p><strong>Payment String&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><strong>Sanction Target&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><strong>Matching Decision&nbsp;</strong></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>John Paul Castro</span></p>
						</TableCell>
						<TableCell>
							<p><span>John Peter Castro</span></p>
						</TableCell>
						<TableCell>
							<p><span>Partial Match — Paul ≠ Peter</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>David Jol Chung</span></p>
						</TableCell>
						<TableCell>
							<p><span>Daniel Jol Chung</span></p>
						</TableCell>
						<TableCell>
							<p><span>Partial Match — David ≠ Daniel</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>John Longman</span></p>
						</TableCell>
						<TableCell>
							<p><span>Emily Longman</span></p>
						</TableCell>
						<TableCell>
							<p><span>Partial Match — John ≠ Emily</span></p>
						</TableCell>
					</TableRow>
					</tbody>
				</Table>

				<h3><strong>5.2.4 Fuzzy Matching</strong></h3>
				<p><span>Determination the similarity between elements of data such as business name,
					personal name or address information.&nbsp;</span></p>

				<p><span>The fuzzy logic feature allows the algorithm to detect and evaluate near
					matches rather than require exact matching.&nbsp;</span></p>

				<p><span>Depending on the algorithm, it may consider alternate nicknames, such as
					“Mike” or “Mickey.” Names (person, place or entity) would be easy to match if they
					were consistent; however, launderers use different techniques to bypass filter detection.</span></p>

				<Table>
					<tbody>
					<TableRow>
						<TableCell>
							<p><strong>Sanction Target&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><strong>Payment String&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><strong>Matching Decision&nbsp;</strong></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>Peter&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Petr&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Match through fuzzy logic&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>Qadir&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Kadar&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Match through fuzzy logic&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>Rahim&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Raheem&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Match through fuzzy logic&nbsp;</span></p>
						</TableCell>
					</TableRow>
					</tbody>
				</Table>

				<h3><strong>5.2.5 Phonetic Matching</strong></h3>
				<p><span>A phonetic algorithm matches two different words with similar pronunciation
					to the same code, which allows phonetic similarity-based word set comparison and
					indexing.&nbsp;</span></p>

				<p><span>There are words that have different spellings but similar pronunciation and
					should be matched, such as Sofia and Sophia/Reynold and Renauld, etc.</span></p>

				<Table>
					<tbody>
					<TableRow>
						<TableCell>
							<p><strong>Sanction Target&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><strong>Payment String&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><strong>Direct Match&nbsp;</strong></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>Sofia&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Sophia&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Fuzzy Logic&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>Reynolds&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Renaults&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Fuzzy Logic&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><span>Smith&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Smyth&nbsp;</span></p>
						</TableCell>
						<TableCell>
							<p><span>Fuzzy Logic&nbsp;</span></p>
						</TableCell>
					</TableRow>
					</tbody>
				</Table>

				<p><span>This table highlights various name variations.</span></p>

				<Table>
					<tbody>
					<TableRow>
						<TableCell>
							<p><strong>Misspelling in
								names&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>Mike Jackson, Michael Jakson, Michael Jaxon, Mike
								Jaxson,&nbsp;</span></p>
							<p><span>Michael Jakson&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Phonetic spelling
								differences&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>Michel, Michal,Miguel&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Nicknames&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>Mike, Mick, Mikey&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Initials&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>M J Jackson, Michael Joseph Jackson&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Titles&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>Dr., Mr.&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Missing name components&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>Michael Joseph Jackson, Michael Jackson&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Out-of-order name components&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>Joseph Jackson, Michael, Michael Joseph Jackson</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Omission of letters&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>Jackson for Jacson&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Interchanging of vowels&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>Hussein for Hussien&nbsp;</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Doubling of consonants&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>Mohamed for Mohammed</span></p>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p><strong>Cultural variations&nbsp;</strong></p>
						</TableCell>
						<TableCell>
							<p><span>William for Bill, Alexander for Alexi&nbsp;</span></p>
						</TableCell>
					</TableRow>
					</tbody>
				</Table>

				<h2><strong>5.3 Investigation timing of potential
					matches</strong></h2>
				<p><span>All potential matches must be investigated within 24 hours of being
					generated.</span></p>
				<p><span>Real time potential matches for customers and payments must be investigated
					immediately and decision made prior to funds being released.</span></p>
				<h2><strong>5.4 Identification of positive
					match</strong></h2>
				<p><span>If a positive match is discovered, the responsible employee must inform MLRO
					immediately.</span></p>
				<p><span>MLRO must investigate received information and if positive match, inform the
					responsible employee which must block the customer in operational system until consent is given to
					proceed
					or refuse.</span></p>
				<p><span>MLRO makes a disclosure to the relevant instance (see point 9 of the
					Procedure).</span></p>
				<h2><strong>5.5 Sanction Monitoring</strong></h2>
				<p><span>When a search is made using ComplyAdvantage aml solution, customer is
					automatically added to a sanction watch list. If a customer will be added to any sanction list,
					notification
					will be sent to MLRO and compliance department.&nbsp; For more details please see - ComplyAdvantage
					Compliance Data Overview file.&nbsp;</span></p>

				<h1><strong>6 PROHIBITION ON CUSTOMER
					RELATIONSHIP/ACCOUNT&nbsp;</strong></h1>
				<p><span>Cospay in considering money laundering risks, regulations and guidance decided
					that certain types of relationship are unacceptable:</span></p>

				<ul>
					<li><span>shell banks;</span></li>
					<li><span>individuals or entities that are on relevant
						sanctions lists issued by countries in compliance with UN resolutions or to which countries have
						applied
						sanctions unilaterally (UK, US and others);</span></li>
				</ul>
				<ul>
					<li><span>individuals or entity whose identity cannot be
						verified or who refuses to provide information required to verify identity or required for
						account opening
						purposes; or who has provided information that contains inconsistences that cannot be resolved
						after
						further investigation;</span></li>
				</ul>
				<ul>
					<li><span>Where there is suspicion or evidence of found,
						money laundering or other criminal activity or involvement;</span></li>
					<li><span>If falsified documentation or information is
						detected during the account opening/relationship establishment process;</span></li>
					<li><span>Individuals, entities and organizations
						sanctioned by UN, EU, HM Treasury list or OFAC;</span></li>
					<li><span>An account using a pseudonym or number rather
						than the actual name of the customer;</span></li>
					<li><span>Anonymous ownership entity accounts, where the
						ownership of the entity cannot be determined because the entity has a form or structure that
						prevents an
						account accurate identification of the Beneficial Owners;</span></li>
					<li><span>Unlicensed financial institutions, including
						unlicensed currency exchange houses and money transmitters and;</span></li>
					<li><span>Persons involved in unlawful internet gaming
						business;</span></li>
					<li><span>Customers- merchants, whose business Merchant
						Category Code (MCC) is included into the International Card organisations prohibition
						list.</span></li>
				</ul>
				<h1><strong>7 REPORTING PROCEDURE</strong></h1>
				<p><span>Suspicion - a degree of satisfaction beyond mere speculation, which is based
					on some foundation, but which does not necessarily amount to belief.</span></p>

				<p><span>The International Compliance Association has described
					“suspicion” as being the third stage after “comfort” and “concern.</span></p>

				<p><span>Cospay employees could face prosecution if it is proven that nobody did make a
					report to our own MLRO, even though one had reasonable grounds for suspicion.&nbsp;</span></p>

				<p><span>Some of RED FLAGS of possible suspicion is listed in Attachment 9.</span></p>

				<p><span>From the moment a suspicion of money laundering arises no further work will
					be carried out on the matter that gave rise to the suspicion. Neither commercial considerations nor
					the
					difficulty in responding to the client’s enquiries on the matter shall be permitted to take
					precedence
					over the Cospays legal obligations in this regard.</span></p>

				<p><span>In such circumstances the MLRO shall act with all possible speed to enable
					work to continue, and assist staff in any communications with the client affected.</span></p>

				<p><span>As soon as a member of staff forms or becomes aware of a suspicion of money
					laundering, no further work is to be done on the matter giving rise to suspicion.&nbsp; If there is
					any
					likelihood of the client becoming aware that work has stopped, for example because an anticipated
					transaction has not gone through, the member of staff concerned must contact the MLRO for
					instructions on
					how to handle the matter with the client.</span></p>

				<p><span>On receipt (form enclose to this report) of a suspicion report, the MLRO
					shall:</span></p>

				<ul>
					<li><span>instruct the originator of the report and any
						other staff involved to cease work on the matter giving rise to suspicion;</span></li>
					<li><span>decide in the shortest possible time whether all
						work for the client concerned should be stopped, or whether other work that is not the cause of
						suspicion
						may continue, and advise relevant staff accordingly;</span></li>
					<li><span>assist all affected staff in handling the matter
						with the client so that no tipping off offence is committed;</span></li>
					<li><span>When work for a client has been stopped, the
						MLRO shall carry out the evaluation of the suspicion report as quickly as possible to decide
						whether a
						disclosure must be made to the authorities;</span></li>
					<li><span>If the MLRO decides that there are not
						reasonable grounds to suspect money laundering, he will give consent for work to continue on his
						own
						authority;</span></li>
					<li><span>If the MLRO decides that a disclosure must be
						made, he will request consent to continue from NCA as quickly as possible;</span></li>
					<li><span>On giving consent to continue, either on his own
						authority or on receipt of notice of consent or implied consent from NCA, the MLRO will confirm
						this in
						writing to affected staff;</span></li>
					<li><span>If consent is refused by NCA, the MLRO will take
						advice from NCA and consult with the responsible MLRO/NO of the Cospay continuation of or
						withdrawal from the
						client relationship.</span></li>
				</ul>

				<p><span>It is important that all employees and management are properly trained and
					remain vigilant of potential money laundering.&nbsp;</span></p>

				<p><span>The report should be made as soon as reasonably possible – this should
					normally be within the first 24 hours after discovery.</span></p>
				<h1><strong>8 CUSTOMERS ACTIVITY MONITORING</strong>
				</h1>
				<p><span>Cospay is required to monitor business relationships and to apply scrutiny of
					unusual, complex or high-risk transactions or activity so that money laundering or terrorist
					financing may
					be identified or prevented.</span></p>

				<p><span>An unusual and suspicious transaction or activity may be in a form that is
					inconsistent with the expected pattern of activity within a particular business relationship, or
					with the
					normal business activities for the type of product or service offered. This may indicate money
					laundering,
					terrorist financing activity or fraudulent activity, where the transaction or activity has no
					apparent
					economic or visible lawful purpose.&nbsp;</span></p>

				<p><span>Monitoring of client’s transactions and activity is carried out on a
					risk-based method, with high risk clients being subjected to additional and more frequent screening
					and
					observation. Transaction and activity monitoring must be undertaken throughout the course of the
					relationship held with the client to ensure that the transactions and activity being conducted are
					consistent with the clients KYC, their business, source of funds and source of wealth.&nbsp;</span>
				</p>

				<p><span>The monitoring of complex, unusual and large transactions or unusual patterns
					of transactions must be examined and recorded in writing.&nbsp;</span></p>

				<p><span>Possible characteristics to monitor could be changes of:&nbsp;</span></p>

				<ul>
					<li><span>the nature and type of a
						transaction;&nbsp;</span></li>
					<li><span>the frequency and nature of a series or pattern
						of transactions;</span></li>
					<li><span>the amount of any transactions, paying
						particular attention to particularly large transactions;</span></li>
					<li><span>the geographical origin/destination of a payment
						or receipt;&nbsp;</span></li>
					<li><span>the parties concerned with a view to ensuring
						that there are no payments to or from a person on a sanctions list;&nbsp;</span></li>
					<li><span>the customer&apos;s normal activity or
						turnover;</span></li>
					<li><span>In case if customer-merchant accepted average
						ticket, amount was increased by 50% (Average ticket amount for each merchant is mentioned in
						e-merchant
						questionnaire).</span></li>
				</ul>

				<p><span>Where the basis of the relationship changes significantly, Cospay must carry out
					further Customer activity monitoring procedures to ensure that the revised risk and basis of the
					relationship is fully understood.&nbsp;</span></p>

				<p><span>Ongoing monitoring procedures must take account of these
					changes.&nbsp;</span></p>
				<p><span>Cospay must ensure that any updated information obtained through meetings,
					discussions, or other methods of communication with the customer is recorded and retained with the
					customer&apos;s records. That information must be available to the MLRO.&nbsp;</span></p>

				<p><span>Ongoing monitoring of a customer&apos;s activities will allow Cospay to continue to
					build a profile of the customer and will entail the ongoing collection of CDD information.</span>
				</p>
				<p><span>Ongoing monitoring for each merchant includes every cardholder monitoring by
					ComplyAdvantage.&nbsp;</span></p>

				<p><span>As part of our ongoing monitoring requirements, the following review periods
					shall be applied to customers:</span></p>
				<ul>
					<li><span>High Risk Customers: twice a year</span></li>
					<li><span>Medium and low Risk customers: Once a
						year</span></li>
				</ul>
				<h1><strong>9 STAFF TRAINING</strong></h1>
				<p><span>One of Cospay’s key controls in mitigating the threat of being used for
					money laundering is having staff that is aware of and alert to the threat. All staff, whether on a
					full-time, part-time or contract basis, are made aware of our anti-money laundering policy, manual
					and the
					obligations arising from them for both themselves and Cospay provides training on anti-money
					laundering.</span></p>
				<p><span>These training comprising two key elements:&nbsp;</span></p>
				<ol>
					<li><span>Induction Training</span><span> - The MLRO/NO is responsible for identifying relevant new
						staff that are
						required to undertake induction training within 45 days after requirement. The training is
						provided by the
						MLRO or the MLRO will engage external AML Advisors and is face to face training. The content of
						the
						training includes awareness training, covering Money Laundering and Terrorist Financing.</span>
					</li>
				</ol>
				<p><span>Understanding of the subject matter is assessed throughout the training
					through case studies. Until a new member of staff has been signed off as competent no direct
					customer
					contact is allowed.</span></p>
				<ol>
					<li><span>Refresher Training</span><span> - all relevant staff must undertake face to face refresher
						training on annual
						basis. The training is provided by the MLRO or the MLRO will engage AML Advisors and assessment
						of staff
						understanding is carried out throughout the training.</span></li>
				</ol>

				<p><span>Cospay will obtain acknowledgement from staff that they have received the
					necessary training by requesting staff to sign their attendance at training sessions. Overall
					monitoring of
					attendance is recorded manually and stored on the AML file. Certificate will be provided to each
					participant
					on successful completion.</span></p>
				<h1><strong>10 RECORD KEEPING</strong></h1>
				<h2><strong>10.1 CDD and transaction records</strong>
				</h2>
				<p><span>We will store records of all transactions, identification, and all related to
					merchant documents for 5 years from the conclusion of the transaction on behalf of our customers or
					the end
					of the relationship.</span></p>

				<p><span>The records we must keep are:</span></p>
				<ol>
					<li><span> Copies of or references to the evidence of the customers ID obtained
						under our CDD requirements; and</span></li>
					<li><span> The supporting evidence and records in respect of the business
						relationships and occasional transactions, which are subject of CDD or ongoing
						monitoring.</span></li>
				</ol>

				<p><span>All records of CDD documentation are scanned and upload into our operational
					system linked in the customer unique reference number.</span></p>

				<h2><strong>10.2 Internal and External SAR
					records</strong></h2>
				<p><span>As previously indicated, all internal reports will be kept on the SAR file as
					opposed to the customer file. The report will be kept for 5 (five) years.</span></p>
				<p><span>In addition to this all SAR submitted including correspondence with FCA or
					HMRC will be kept for unlimited period of time.</span></p>
				<h2><strong>10.3 Training records</strong></h2>
				<p><span>The company maintains records of all AML training undertaken by staff, the
					date it was provided and the results of any tests if applicable. These records will be kept for 5
					(five)
					years following the end of employment with the company.</span></p>

				<h2><strong>10.4 Audit results</strong></h2>
				<p><span>All audit results must be kept for 5 (five) years following the date of the
					Board of Directors approval of them.</span></p>
				<h1><strong>11 AML PROGRAM AUDIT AND TESTING</strong>
				</h1>
				<p><span>To provide reasonable assurance that Cospay AML program is functioning
					effectively, Cospay conducts an audit of its AML program.</span></p>

				<p><span>The audit is conducted on a regular bases, at least every 12-18 months, if ML
					Risk assessment results will be rated as moderate, high or severe and every 18-24 months is the
					results will
					be rated as low and intermediate.</span></p>

				<p><span>The main actions of audit will cover:</span></p>
				<ul>
					<li><span>Examination of AML processes compliance with
						applicable Law and regulation;</span></li>
					<li><span>Customers files review;</span></li>
					<li><span>Incoming/outgoing transactions review;</span>
					</li>
					<li><span>Examination of representative documents to
						determine whether customer identification and verification procedures are being followed;</span>
					</li>
					<li><span>whether CDD and EDD are being properly
						applied;</span></li>
					<li><span>whether suspicious activity is being properly
						alerted investigated, escalated and reported;</span></li>
					<li><span>Whether severance of a customer
						relationship.</span></li>
					<li><span>Merchant including process into International
						Card Organizations black lists (VMAS/MATCH);</span></li>
					<li><span>Reporting process to International Card
						Organizations;</span></li>
					<li><span>whether complaints process was initiated by the
						customer etc.</span></li>
				</ul>

				<p><span>External audit should be performed by authorized, licensed company.</span></p>
				<p><span>The audit results must be reported and appropriate action plan must be
					established and presented directly to the Board of Directors.</span></p>
			</Segment>
		</Container>);
}

export default AMLPolicy;
