import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import request from '@services/request';
import { logout } from '@features/user/userSlice';

const Interceptors = (): React.ReactElement => {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		// Register intersectors
		request.interceptors.request.use(
			(config) => {
				// Get token and add it to header "Authorization"
				const credentialsKey = process.env.REACT_APP_CREDENTIALS_KEY;
				try {
					const credentials = JSON.parse(sessionStorage.getItem(credentialsKey));
					config.headers.Authorization = credentials.token;
				}
				catch (e) {
					console.log(e);
				}
				config.headers['Content-Type'] = 'application/json';
				return config;
			},
			(error) => Promise.reject(error)
		);
		request.interceptors.response.use(undefined, (err) => {
			try {
				const {
					response: { status },
					response
				} = err;
				

				if (status === 401) {
					history.push('/auth');
					dispatch(logout());
				}
				return Promise.reject(response);
			} catch (e) {
				return Promise.reject(e);
			}
		});
	}, [dispatch, history]);

	return (
		<div id="interceptors"></div>
	);
};

export default Interceptors;
