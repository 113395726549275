/* eslint-disable react/prop-types */
import { UserType } from '@/features/user/types';
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, Header, List, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getAccounts } from '@/features/accounts/slice';
import { showException } from '@/features/swal/slice';
import { getCards } from '@/features/cards/slice';
import { getClient } from '@/features/clients/slice';
import Loading from '@/components/Shared/Loading';
import { useHistory } from 'react-router-dom';

const ClientDetails = (props): React.ReactElement => {

	const dispatch = useDispatch();
	const [clientId] = useState<string>(props.match.params.clientId);

	const { client } = useSelector(
		(state: RootState) => state.clients
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getClient(clientId));
			} catch (e) {
				showException(e);
			}
		};
		get();
	}, [clientId, dispatch]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	if (!client) {
		return <Loading />;
	}

	return (
		<Container fluid>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Client</Header></Grid.Column>
						<Grid.Column floated='right'><Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button></Grid.Column>
					</Grid.Row>
				</Grid>


				<List>
					<List.Item>
						<List.Header className="color-grey">Client ID</List.Header>
						<List.Description>{client.clientId}</List.Description>
					</List.Item>
					<List.Item>
						<List.Header className="color-grey">Email</List.Header>
						<List.Description>{client.email}</List.Description>
					</List.Item>
					<List.Item>
						<List.Header className="color-grey">Type</List.Header>
						<List.Description>{client.type === UserType.EXTERNAL_INDIVIDUAL ? 'Individual' : 'Business'}</List.Description>
					</List.Item>
					{client.type === UserType.EXTERNAL_INDIVIDUAL && <List.Item>
						<List.Header className="color-grey">First name</List.Header>
						<List.Description>{client.firstName}</List.Description>
					</List.Item>}
					{client.type === UserType.EXTERNAL_INDIVIDUAL && <List.Item>
						<List.Header className="color-grey">Last Name</List.Header>
						<List.Description>{client.lastName}</List.Description>
					</List.Item>}
					{client.type === UserType.EXTERNAL_BUSINESS && <List.Item>
						<List.Header className="color-grey">Company name</List.Header>
						<List.Description>{client.companyName}</List.Description>
					</List.Item>}
					{client.type === UserType.EXTERNAL_BUSINESS && <List.Item>
						<List.Header className="color-grey">Company reg number</List.Header>
						<List.Description>{client.companyRegNumber}</List.Description>
					</List.Item>}

				</List>
			</Segment>
			<Segment>
				<Header as='h2'>Bank Accounts</Header>
				<ClientBankAccounts clientId={client.clientId} />
			</Segment>
			<Segment>
				<Header as='h2'>Cards</Header>
				<ClientCards clientId={client.clientId} />
			</Segment>
		</Container>
	);
};

interface Props {
	clientId: string
}

const ClientBankAccounts = ({ clientId }: Props) => {
	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.accounts
	);
	const paginateInitialState: PaginateParams = { orderBy: 'balance', sort: 'DESC', skip: 0, filter: `clientId=${clientId}`, take: 5 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);

	const headers: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Account number',
			position: 1,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 2,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 3,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 4,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 5,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 6,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 7,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getAccounts('BANK', pagination));
			} catch (e) {
				showException(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const history = useHistory();
	const openAccount = (walletId: string) => {
		history.push(`/account/${walletId}`);
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry) => {
				return (
					<Table.Row key={`${entry.walletId}$`} >
						<Table.Cell width={1}>{entry.walletId}</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.integration)}</Table.Cell>
						<Table.Cell width={3}>{`${entry.currency} ${entry.balance}`}</Table.Cell>
						<Table.Cell width={3}>{`${entry.currency} ${entry.balanceOnHold}`}</Table.Cell>
						<Table.Cell width={3}>{`${entry.currency} ${entry.balanceFrozen}`}</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.status)}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='Open account details' trigger={<Button secondary icon="money bill alternate outline"  onClick={() => openAccount(entry.walletId)}/>} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={7}>Client does not have any bank accounts.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<CustomTable
			fetchData={fetchData}
			loading={loading}
			paginateInitialState={paginateInitialState}
			tableBody={tableBody}
			totalPages={totalPages}
			setPagination={setPagination}
			header={headers}
			pagination={pagination}
			hideItemsPerPage={true}
		/>
	);

};


const ClientCards = ({ clientId }: Props) => {
	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.cards
	);

	const paginateInitialState: PaginateParams = { orderBy: 'balance', sort: 'DESC', skip: 0, filter: `clientId=${clientId}`, take: 5 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);


	const headers: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Card ID',
			position: 1,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 2,
			sortable: true
		},
		{
			field: 'name',
			name: 'Card Holder',
			position: 3,
			sortable: false
		},
		{
			field: 'cardNumber',
			name: 'Card Number',
			position: 4,
			sortable: false
		},
		{
			field: 'virtual',
			name: 'Type',
			position: 5,
			sortable: false
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 6,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 7,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 8,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 9,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 10,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getCards(pagination));
			} catch (e) {
				showException(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const history = useHistory();
	const openAccount = (walletId: string) => {
		history.push(`/account/${walletId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry) => {
				return (
					<Table.Row key={`${entry.walletId}$`} >
						<Table.Cell width={2}>{entry.walletId}</Table.Cell>
						<Table.Cell width={1}>{capitalizeFirstLetter(entry.integration)}</Table.Cell>
						<Table.Cell width={3}>{entry.cardholder}</Table.Cell>
						<Table.Cell width={2}>{entry.cardNumberMask}</Table.Cell>
						<Table.Cell width={1}>{entry.virtual ? 'Virtual' : 'Physical'}</Table.Cell>
						<Table.Cell width={2}>{`${entry.currency} ${entry.balance}`}</Table.Cell>
						<Table.Cell width={1}>{`${entry.currency} ${entry.balanceOnHold}`}</Table.Cell>
						<Table.Cell width={1}>{`${entry.currency} ${entry.balanceFrozen}`}</Table.Cell>
						<Table.Cell width={1}>{capitalizeFirstLetter(entry.status)}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='Open account details' trigger={<Button secondary icon="credit card outline" onClick={() => openAccount(entry.walletId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={10}>Client does not have any cards yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (

		<CustomTable
			fetchData={fetchData}
			loading={loading}
			paginateInitialState={paginateInitialState}
			tableBody={tableBody}
			totalPages={totalPages}
			setPagination={setPagination}
			header={headers}
			pagination={pagination}
			hideItemsPerPage={true}
		/>

	);
};


export default ClientDetails;
