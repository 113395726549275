import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import './Navbar.css';


const VerticalNavbar = (): React.ReactElement => {

	return (

		<Menu fluid vertical className="vertical-menu">
			<Menu.Item
				header
				name='Dashboard'
				as={NavLink}
				to="/dashboard"
				icon="chevron right"
			/>
			<Menu.Item
				header
				className='subMenuHeader'>
				Documentation
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='Settings'
					as={NavLink}
					to="/docs/settings"
				/>
				<Menu.Item
					className='submenu first'
					name='Authentications'
					as={NavLink}
					to="/docs/authentications"
				/>
				<Menu.Item
					className='submenu'
					name='API'
					as={NavLink}
					to="/docs/api"
				/>
				<Menu.Item
					className='submenu'
					name='webhooks'
					as={NavLink}
					to="/docs/webhooks"
				/>
			</Menu.Item>
			<Menu.Item
				header
				className='subMenuHeader'>
				Clients
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='individual clients'
					as={NavLink}
					to="/clients/individual"
				/>
				<Menu.Item
					className='submenu'
					name='business clients'
					as={NavLink}
					to="/clients/business"
				/>
			</Menu.Item>
			<Menu.Item
				header
				name='accounts'
				as={NavLink}
				to="/accounts"
				icon="chevron right"
			/>
			<Menu.Item
				header
				name='cards'
				as={NavLink}
				to="/cards"
				icon="chevron right"
			/>
			<Menu.Item
				header
				name='transactions'
				as={NavLink}
				to="/transactions"
				icon="chevron right"
			/>
			<Menu.Item
				header
				name='invoices'
				as={NavLink}
				to="/invoices"
				icon="chevron right"
			/>
		</Menu>
	);
};

export default VerticalNavbar;
