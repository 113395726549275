import { CheckCircleIcon } from '@/icons';
import React, { useState } from 'react';
import { Button, Container, Grid, Header, Segment } from 'semantic-ui-react';
import KYCForm from '../KYC/KYCForm';

const Kyb = (): React.ReactElement => {

	const [open, setOpen] = useState<boolean>(false);


	const onKYCclick = (e) => {
		e.preventDefault();
		setOpen(true);
	};

	return (
		<Container>
			<Segment>
				<Grid>
					<Grid.Column textAlign="center">
						<Header>
							<CheckCircleIcon />
							<div>KYB verification</div>
						</Header>

						<div>Before you can start, you will need to verify your identity via KYB form.</div>
						<br />
						<Button primary onClick={onKYCclick}>Start verification</Button>
					</Grid.Column>
				</Grid>
				{open && <KYCForm open={open} setOpen={setOpen} />}
			</Segment>
		</Container>
	);

};


export default Kyb;
