import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Container, Dimmer, Divider, Grid, Header, Icon, Input, Loader, PaginationProps, Segment } from 'semantic-ui-react';
import { showException } from '../../../features/swal/slice';
import { clearTicket, getTicket, getTickets } from '../../../features/tickets/slice';
import { PaginateParams } from '../../../features/tickets/types';
import { RootState } from '../../../rootReducer';
import Message from './Message';
import MessageList from './MessageList';
import './InboxTab.css';
import CustomPagination, { PaginatorParams } from '../../Shared/Pagination';

const InboxTab = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPage, ticket, error, updateMessages, updateMessage } = useSelector(
		(state: RootState) => state.tickets
	);
	const paginationInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: null };
	const { t } = useTranslation('translations');
	const [pagination, setPagination] = useState<PaginateParams>(paginationInitialState);
	const [paginator, setPaginator] = useState<PaginatorParams>({ activePage: 1 });
	const [searchValue, setSearchValue] = useState('');
	const [messageListLoading, setMessageListLoading] = useState<boolean>(false);
	const [messageLoading, setMessageLoading] = useState<boolean>(false);

	const fetchData = useCallback(() => {
		const get = async () => {
			setMessageListLoading(true);
			try {
				await dispatch(getTickets(pagination));
			} catch (e) {
				showException(e);
			} finally {
				setMessageListLoading(false);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handlePaginationChange = (e, { activePage }: PaginationProps) => {
		setPaginator({ activePage: +activePage });
		const paginationState = { ...pagination };
		paginationState.skip = +activePage - 1;
		setPagination(paginationState);
	};

	const handleSearchChange = e => {
		setSearchValue(e.target.value);
		if (searchValue.length >= 2) {
			return search();
		}
	};

	useEffect(() => {
		if (searchValue.length >= 2) {
			return search();
		}
		if (!searchValue.length) {
			return setPagination(paginationInitialState);
		}
	}, [searchValue]);

	useEffect(() => {
		if (updateMessages) {
			const paginationState = { ...pagination };
			setPagination(paginationState);
		}
	}, [updateMessages]);

	useEffect(() => {
		if (updateMessage) {
			if (!!ticket && ticket.id === updateMessage) {
				fetchTicket(ticket.id);
			}
		}
	}, [updateMessage, dispatch]);

	const search = () => {
		if (searchValue === '') {
			return;
		}
		dispatch(clearTicket());
		const paginationState = { ...paginationInitialState };
		paginationState.filter = 'title=' + searchValue;
		setPagination(paginationState);
	};

	const clearInput = () => {
		setSearchValue('');
		const paginationState = { ...pagination };
		paginationState.filter = null;
		setPagination(paginationState);
	};

	const backToMessages = () => {
		dispatch(clearTicket());
		const paginationState = { ...pagination };
		setPagination(paginationState);
	};

	const fetchTicket = (id: number) => {
		const get = async () => {
			setMessageLoading(true);
			try {
				dispatch(getTicket(id));
			} catch (e) {
				showException(e);
			} finally {
				setMessageLoading(false);
			}
		};
		get();
	};

	if (error) return <div>Error: {error}</div>;
	return (
		<Container>
			<Segment id="InboxTab" padded>
				<Grid className="header">
					<Grid.Column floated='left' computer="8" mobile="16" >
						<Header as="h2" content={t('inbox.messages')} />
					</Grid.Column>
					<Grid.Row className='desktopInboxBTNSRow'>
						{/* <Grid.Column className='desktopInboxBTNS' width={5}>
						<Icon className='deleteInboxIcon' name='trash alternate' />
					</Grid.Column> */}
						<Grid.Column className='inboxSearchCLMN' floated='right' computer="5" mobile='16' >
							<Input
								className="searchInput"
								size="mini"
								icon="search"
								iconPosition="left"
								fluid
								placeholder={t('inbox.searchPlaceholder')}
								onChange={handleSearchChange}
								value={searchValue}
								action={{ icon: 'times', onClick: () => clearInput() }} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />
				<div>
					<div className='desktopPagination'>
						{list.length > 0 && (
							<CustomPagination
								customClassName={ticket ? 'ticketOpened' : ''}
								totalPages={totalPage}
								handlePaginationChange={handlePaginationChange}
								paginator={paginator}
							/>
						)}
					</div>
					{ticket && (
						<Breadcrumb size="large" className="backchevronitem messages">
							<Breadcrumb.Section link onClick={() => backToMessages()}><Icon name="chevron left" className="backIcon" /> {t('faq.back')}</Breadcrumb.Section>
						</Breadcrumb>
					)}
					<Divider hidden />
					<Grid stackable padded>
						{list.length > 0 && (
							<Grid.Column className={ticket ? 'ticketOpened' : ''} width={6}>
								<Dimmer active={messageListLoading} inverted>
									<Loader />
								</Dimmer>
								{list.map(item => (
									<MessageList selectedTicket={ticket} key={item.id} message={item} onClick={() => fetchTicket(item.id)} />
								))}
							</Grid.Column>
						)}
						{ticket && (
							<Grid.Column width={10}>
								<Dimmer active={messageLoading} inverted>
									<Loader />
								</Dimmer>
								<Message message={ticket}></Message>
							</Grid.Column>
						)}
						<div className='mobilePagination'>
							{list.length > 0 && (
								<CustomPagination
									customClassName={ticket ? 'ticketOpened' : ''}
									totalPages={totalPage}
									handlePaginationChange={handlePaginationChange}
									paginator={paginator}
								/>
							)}
						</div>
					</Grid>
					{list.length == 0 && (
						<div>
							<Dimmer active={messageListLoading} inverted>
								<Loader />
							</Dimmer>
							<Header as="h2" className="emptyInbox" content={t('inbox.inboxIsEmpty')} />
						</div>
					)}
				</div>
			</Segment>
		</Container>
	);
};
export default InboxTab;
