import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import status from '@features/status/statusSlice';
import user from '@features/user/userSlice';
import kyc from '@features/kyc/slice';
import swal from '@features/swal/slice';
import credentials from '@features/user/credentialsSice';
import faq from '@features/faq/slice';
import tickets from '@features/tickets/slice';
import profile from '@features/security/slice';
import clients from '@features/clients/slice';
import accounts from '@features/accounts/slice';
import cards from '@features/cards/slice';
import invoices from '@features/invoices/slice';
import transactions from '@features/transactions/slice';
import allowedCountries from '@features/countries/slice';

const appReducer = combineReducers({  user, status, swal, credentials, kyc, faq, tickets, profile, clients, accounts, invoices, cards, transactions, allowedCountries });

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
	if (action.type === 'RESET_APP') {
		const { status } = state;
		state = {} as RootState;
		state = {
			...state,
			status
		};
	}
	if(action.type === 'SWITCH_REPRESENTATION') {
		state = {
			...state,
		};
	}
	return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>

export default rootReducer;
