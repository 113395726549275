import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getAccounts } from '@/features/accounts/slice';
import { showException } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Header, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';



const Accounts = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.accounts
	);

	const paginateInitialState: PaginateParams = { orderBy: 'balance', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);

	const statusOptions = [
		{
			key: 'ACTIVE',
			text: 'Active',
			value: 'ACTIVE',
		},
		{
			key: 'ORDERED',
			text: 'Ordered',
			value: 'ORDERED',
		},
		{
			key: 'BLOCKED',
			text: 'Blocked',
			value: 'BLOCKED',
		},
		{
			key: 'DELETED',
			text: 'Deleted',
			value: 'DELETED',
		},
	];

	const filter: FilterParam[] = [
		{
			field: 'walletId',
			label: 'Account number',
			type: 'input'
		},
		{
			field: 'integration',
			label: 'Integration',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: statusOptions
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Account number',
			position: 1,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 2,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 3,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 4,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 5,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 6,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 7,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getAccounts('BANK', pagination));
			} catch (e) {
				showException(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const history = useHistory();
	const openUser = (clientId: string) => {
		history.push(`/client/${clientId}`);
	};

	const openAccount = (walletId: string) => {
		history.push(`/account/${walletId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry) => {
				return (
					<Table.Row key={`${entry.walletId}$`} >
						<Table.Cell width={1}>{entry.walletId}</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.integration)}</Table.Cell>
						<Table.Cell width={3}>{`${entry.currency} ${entry.balance}`}</Table.Cell>
						<Table.Cell width={3}>{`${entry.currency} ${entry.balanceOnHold}`}</Table.Cell>
						<Table.Cell width={3}>{`${entry.currency} ${entry.balanceFrozen}`}</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.status)}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='Open account details' trigger={<Button secondary icon="money bill alternate outline" onClick={() => openAccount(entry.walletId)} />} />
							<Popup content='View client' trigger={<Button secondary icon="user" onClick={() => openUser(entry.clientId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={7}>You dont have accounts yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Accounts</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Segment>
		</Container>
	);
};


export default Accounts;
