export enum Agents {
	AZURE = 'AZURE',
    COSPAY = 'COSPAY',
	WALLEXCARD = 'WALLEXCARD',
	DTSCB = 'DTSCB',
	TYNCOFEX = 'TYNCOFEX'
}

export interface PaginateParams {
    orderBy: string,
    sort: 'DESC' | 'ASC',
    skip: number,
    filter: string,
    take: 5 | 10 | 25 | 50
}
