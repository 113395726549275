import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '../../store';
import { openModal } from '../swal/slice';
import { Status } from './types';

const initialState: Status = {
	invitationEnabled: false,
	regionRestricted: false,
	registrationDisabledReason: null,
	registrationEnabled: false,
	businessEnabled: false,
	chatAppEnabled: false,
	loading: true,
	agent: null
};

const statusSlice = createSlice({
	name: 'status',
	initialState,
	reducers: {
		setStatus(state, action: PayloadAction<Status>) {
			state.invitationEnabled = action.payload.invitationEnabled;
			state.regionRestricted = action.payload.regionRestricted;
			state.registrationDisabledReason = action.payload.registrationDisabledReason;
			state.registrationEnabled = action.payload.registrationEnabled;
			state.businessEnabled = action.payload.businessEnabled;
			state.chatAppEnabled = action.payload.chatAppEnabled;
			state.loading = false;
			state.agent = action.payload.agent;
		}
	}
});

export const { setStatus } = statusSlice.actions;

export const statusSelector = (state: { statusStore: Status }) =>
	state.statusStore;

export const getStatus = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/status');
			const { data } = await response;
			dispatch(setStatus(data));
		} catch (e) {
			dispatch(openModal({ header: 'Network error', content: e.message }));
		}
	};
};

export default statusSlice.reducer;
