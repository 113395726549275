import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Header, Divider, Container, Message } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './RegistrationIB.css';
import { Formik } from 'formik';
import { Form } from 'formik-semantic-ui-react';
import { signup, logout } from '@features/user/userSlice';
import { showSuccess, showException } from '@features/swal/slice';
import { SignupBody } from '@features/user/types';
import { parse, format, isValid } from 'date-fns';
import { getNames, alpha2ToAlpha3 } from 'i18n-iso-countries';
import { usePasswordValidation, useValidator } from './hooks';
import PersonalInfo from './Parts/PersonalInfo';
import Compliance from './Parts/Compliance';
import RegisterButton from './Parts/RegisterButton';
import Links from './Parts/Links';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function parseDate(birthDate) {
	if (isValid(parse(birthDate, 'yyyy-MM-dd', new Date()))) {
		return format(parse(birthDate, 'yyyy-MM-dd', new Date()), 'MM-dd-yyyy');
	}
	return format(parse(birthDate, 'dd/MM/yyyy', new Date()), 'MM-dd-yyyy');
}

const Individual = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();
	const [registrationError, setError] = useState<boolean>(false);
	const [toc, setToc] = useState<boolean>(false);
	const [aml, setAml] = useState<boolean>(false);
	const [invCode, setInvCode] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [passwordErrors, validatePassword] = usePasswordValidation();
	const individualValidator = useValidator('individual');
	const contriesList = getNames('en');
	const [serviceAllowed, setServiceAllowed] = useState<boolean>(false);


	useEffect(() => {
		const code = query.get('code');
		if (code) {
			setInvCode(code);
		}
		
		const email = query.get('email');
		if (email) {
			setEmail(email);
		}
	}, []);

	const initialValues = {
		firstName: '',
		lastName: '',
		email: email,
		password: '',
		passwordConfirm: '',
		country: '',
		countryCode: '',
		phoneNumber: '',
		birthDate: '',
		invitationCode: invCode
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setError(false);
		try {
			const { firstName, lastName, email, password, country, countryCode, phoneNumber, birthDate, invitationCode } = formData;
			const countyCode = alpha2ToAlpha3(Object.keys(contriesList).find(key => contriesList[key] === country));
			const payload: SignupBody = {
				businessOwnerAccount: false,
				email,
				country: countyCode,
				password,
				firstName,
				lastName,
				birthDate: parseDate(birthDate),
				mobileNumber: `+${countryCode}${phoneNumber}`,
				invitationCode,
			};
			await dispatch(logout());
			await signup(payload);
			showSuccess({ title: 'swal.USER_REGISTERED', text: 'swal.USER_REGISTERED_TEXT' });
			history.push('/auth');
		}
		catch (e) {
			const { data, status } = e;
			if (status === 417) {
				const { errors } = data;
				const duplicatePresent = errors.find(error => error.error === 'duplicate' && error.error_param === 'email');
				const invalidEmail = errors.find(error => error.error === 'invalid' && error.error_param === 'email');
				const invalidInvitationCode = errors.find(error => error.error === 'invalid' && error.error_param === 'invitationCode');
				if (duplicatePresent) {
					showException({
						data: duplicatePresent,
						status: 417
					}, [{
						key: 'duplicate',
						title: 'Error',
						message: 'An account with your provided email has already been created'
					}]);
				}

				if (invalidEmail) {
					showException({
						data: invalidEmail,
						status: 417
					}, [{
						key: 'invalid',
						title: 'Error',
						message: 'Your provided email is invalid'
					}]);
				}

				if (invalidInvitationCode) {
					showException({
						data: invalidInvitationCode,
						status: 417
					}, [{
						key: 'invalid',
						title: 'Error',
						message: 'Your provided invitation code is invalid'
					}]);
				}
			} else {
				showException(e, [{
					key: 'registration_disabled',
					title: 'swal.REGISTRATION_DISABLED_TITLE',
					message: 'swal.REGISTRATION_DISABLED_TEXT'
				}]);
			}
			setSubmitting(false);
		}
	};

	return (
		<Container>
			<Formik
				initialValues={initialValues}
				validationSchema={individualValidator}
				onSubmit={submit}
				enableReinitialize
			>{({ errors, isSubmitting, dirty }) => (
					<Form error={registrationError}>
						<Header className="aboutyoutitle" as="h3">{t('registration.individualtitle')}</Header>
						<Divider hidden />
						<PersonalInfo contriesList={contriesList} validatePassword={validatePassword} passwordErrors={passwordErrors} dirty={dirty} setServiceAllowed={setServiceAllowed}/>
						<Header className="checkboxheader" size="medium">{t('registration.compliancelabel')}</Header>
						<Compliance toc={toc} setToc={setToc} aml={aml} setAml={setAml} />
						{registrationError && (<Message
							error
							header='Action Forbidden'
							content={registrationError} />)}
						<div>
							<RegisterButton
								isSubmitting={isSubmitting}
								toc={toc}
								aml={aml}
								dirty={dirty}
								errors={errors}
								serviceAllowed={serviceAllowed}
							/>
						</div>
						<Divider hidden />
						<Links />
					</Form>
				)}
			</Formik>
		</Container >
	);
};

export default Individual;


