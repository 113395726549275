import React, { useState, useEffect } from 'react';
import { RootState } from '../../rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'is-empty';
import { alpha2ToAlpha3, alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import { Icon, Modal, Message } from 'semantic-ui-react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { getToken, address } from '../../features/kyc/slice';
import { useKYCTier } from './hooks';
import { KycStatus } from './types';
import SumSubForm from './SumSubForm';
import { UserType } from '@/features/user/types';

interface ModalProps {
	open: boolean,
	setOpen: (value: boolean) => void
}

interface Country {
	code: string,
	label: string,
}

const KYCModal = ({ open, setOpen }: ModalProps) => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const contriesList = getNames('en');
	const [countries] = useState<Array<Country>>(
		Object.keys(contriesList)
			.map($code => ({
				code: $code,
				label: contriesList[$code],
			})));
	const [showAddress, setShowAddress] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [userCountry, setUserCountry] = useState<string>(null);
	const { user } = useSelector(
		(state: RootState) => state.user
	);
	const { token } = useSelector(
		(state: RootState) => state.kyc
	);
	const tier = user.type === UserType.INDIVIDUAL ? useKYCTier(user.kyc, 1) : useKYCTier(user.kyc, 2);

	useEffect(() => {
		const { country } = user;
		const countryCode = alpha3ToAlpha2(country);
		setUserCountry(contriesList[countryCode]);
	}, [user, contriesList]);

	useEffect(() => {
		if (tier === null || tier.status === KycStatus.PAID) {
			setShowAddress(true);
		}
		if (tier !== null && tier.status !== KycStatus.PAID) {
			setShowAddress(false);
			dispatch(getToken());
		}
	}, [tier, dispatch]);

	const initialValues = {
		country: userCountry,
		street: '',
		town: '',
		postalCode: ''
	};

	const validationSchema = Yup.object({
		country: Yup.string().required(t('form.validator.required')),
		street: Yup.string().required(t('form.validator.required')),
		town: Yup.string().required(t('form.validator.required')),
		postalCode: Yup.string().required(t('form.validator.required'))
	});

	const next = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setError(false);
		try {
			const { country, street, town, postalCode } = formData;
			const countyCode = alpha2ToAlpha3(Object.keys(contriesList).find(key => contriesList[key] === country));
			await dispatch(address({
				country: countyCode, street, town, postalCode
			}));
			await dispatch(getToken());
			setShowAddress(false);
		} catch (e) {
			setError(true);
			setOpen(false);
		} finally {
			setSubmitting(true);
		}
	};

	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
		>
			<Modal.Header>{t('kyc.form.header', { type: user.type === UserType.BUSINESS ? 'KYB' : 'KYC' })}</Modal.Header>
			{showAddress && (<Modal.Content>
				<Message icon>
					<Icon name='chevron right' />
					<Message.Content>{t('kyc.form.helper')}</Message.Content>
				</Message>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={next}
				>
					{({ errors, isSubmitting, dirty }) => (
						<Form
							size="large"
							error={false}>
							<Input
								fluid
								name="country"
								label={t('form.fields.country')}
								placeholder={t('form.placeholder.country')}
								errorPrompt
								list="countries"
							/><datalist id='countries'>
								{countries.map(c => <option key={c.code} value={c.label}>{c.label}</option>)}
							</datalist>
							<Input
								fluid
								name="street"
								label={t('form.fields.street')}
								errorPrompt
							/>
							<Input
								fluid
								name="town"
								label={t('form.fields.town')}
								errorPrompt
							/>
							<Input
								fluid
								name="postalCode"
								label={t('form.fields.postalcode')}
								errorPrompt
							/>
							{error && (<Message
								error
								header='Action Forbidden'
								content={''} />)}
							<SubmitButton
								disabled={isSubmitting || !isEmpty(errors) || !dirty}
								primary size="large" type="submit">
								{t('form.buttons.continue')}
							</SubmitButton>
						</Form>)}
				</Formik>
			</Modal.Content>)}
			{token && <SumSubForm token={token} onClose={() => setOpen(false)}></SumSubForm>}
		</Modal>
	);
};

export default KYCModal;
