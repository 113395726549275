import React, { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import './Profile.css';
import {
	Segment,
	Container,
	Divider,
	Header,
	Grid,
	Button,
	Table,
	Icon,
} from 'semantic-ui-react';
import InviteTeamMemeber from './Profile/InviteTeamMember';
import { showException } from '@/features/swal/slice';
import { connect, deleteTeamMember, getTeamMembers, inviteTeamMember, revokeTeamMember, subscribe, unsubscribe } from '@/features/security/slice';
import { capitalizeFirstLetter } from '@/helpers/string';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TeamMember } from '@/features/security/types';

const TeamMembers = (): React.ReactElement => {
	const [inviteTeamMemeber, setInviteTeamMemeber] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { teamMembers } = useSelector(
		(state: RootState) => state.profile
	);

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);

	useEffect(() => {
		fetchTeamMembers();
	}, [dispatch]);


	const fetchTeamMembers = async () => {
		try {
			await dispatch(getTeamMembers());
		} catch (e) {
			showException(e);
		}
	};

	const onCloseInviteTeamMemberModal = async (email?: string) => {
		setInviteTeamMemeber(false);
		if (email) {
			try {
				await inviteTeamMember(email);
			}
			catch (e) {
				showException(e);
			}
		}
	};

	const delTeamMember = async (teamMember: TeamMember) => {
		try {
			await deleteTeamMember(teamMember.developerId, teamMember.email);
		} catch (e) {
			showException(e);
		}
	};
	
	const revTeamMember = async (teamMember: TeamMember) => {
		try {
			await revokeTeamMember(teamMember.developerId);
		} catch (e) {
			showException(e);
		}
	};


	return (
		<Container>
			<InviteTeamMemeber
				onClose={onCloseInviteTeamMemberModal}
				open={inviteTeamMemeber}
			/>
			<Segment className="profilesegment" padded>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header className="profileHeader" >Team Memebers</Header>
						</Grid.Column>
						<Grid.Column>
							<Button floated='right' primary onClick={() => setInviteTeamMemeber(true)}>Invite team member</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Divider hidden />
				<Table className='teamMemberTable' stackable padded celled textAlign='center'>
					<Table.Header className='teamMemberTableHeader'>
						<Table.Row className="teamMemberTableHeaders">
							<Table.HeaderCell>Email</Table.HeaderCell>
							<Table.HeaderCell>Invitation Code</Table.HeaderCell>
							<Table.HeaderCell>Registered</Table.HeaderCell>
							<Table.HeaderCell>Role</Table.HeaderCell>
							<Table.HeaderCell></Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body className="teamMemberTable">
						{teamMembers.map((entry) => {
							return (
								<Table.Row key={`${entry.id}`} >
									<Table.Cell disabled={entry.disabled}>{entry.email}</Table.Cell>
									<Table.Cell disabled={entry.disabled}>
										{entry.invitationCode}
										<CopyToClipboard id="clipboard-button" text={entry.invitationCode}>
											<Button basic primary onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
											}} icon="clone outline"></Button>
										</CopyToClipboard>
									</Table.Cell>
									<Table.Cell disabled={entry.disabled}>{entry.approved ?
										<Icon className="approvedIcon" color="green" size="large" name="check circle outline"></Icon>
										: <Icon className="pendingIcon" color="red" size="large" name="times circle outline"></Icon>}</Table.Cell>
									<Table.Cell disabled={entry.disabled}>{capitalizeFirstLetter(entry.role)}</Table.Cell>
									<Table.Cell>
										{entry.approved && !entry.disabled && <Button secondary icon="unlink" onClick={() => delTeamMember(entry)} content="Disable"></Button>}
										{!entry.approved && !entry.disabled && <Button secondary icon="trash alternate outline" onClick={() => delTeamMember(entry)} content="Remove"></Button>}
										{entry.disabled && <Button secondary icon="undo" onClick={() => revTeamMember(entry)} content="Recovery"></Button>}
									</Table.Cell>
								</Table.Row>
							);
						})}
						{teamMembers.length === 0 && <Table.Row>
							<Table.Cell textAlign='center' colSpan={5}>You dont have invited team members.</Table.Cell>
						</Table.Row>}
					</Table.Body>
				</Table>

			</Segment>
			<Divider hidden />
		</Container >
	);
};

export default TeamMembers;
