import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getClients } from '@/features/clients/slice';
import { showException } from '@/features/swal/slice';
import { UserType } from '@/features/user/types';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Header, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router';


const BusinessClients = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.clients
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: `type=${UserType.EXTERNAL_BUSINESS}`, take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);
	const history = useHistory();

	const businessFilter: FilterParam[] = [
		{
			field: 'email',
			label: 'Email',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'companyName',
			label: 'Company Name',
			type: 'input'
		},
		{
			field: 'companyRegNumber',
			label: 'Company Reg Number',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'email',
			name: 'Email',
			position: 2,
			sortable: true
		},
		// {
		// 	field: 'type',
		// 	name: 'Type',
		// 	position: 3,
		// 	sortable: false
		// },
		{
			field: 'companyName',
			name: 'Company Name',
			position: 3,
			sortable: true
		},
		{
			field: 'companyRegNumber',
			name: 'Company Reg Number',
			position: 4,
			sortable: true
		},
		{
			field: 'clientId',
			name: 'Client ID',
			position: 1,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 5,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getClients(pagination));
			} catch (e) {
				showException(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const openUser = (clientId: string) => {
		history.push(`/client/${clientId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry) => {
				return (
					<Table.Row key={`${entry.clientId}${entry.email}`} >
						<Table.Cell width={1}>{entry.clientId}</Table.Cell>
						<Table.Cell width={4}>{entry.email}</Table.Cell>
						<Table.Cell width={4}>{entry.companyName}</Table.Cell>
						<Table.Cell width={4}>{entry.companyRegNumber}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='View client details' trigger={<Button secondary icon="user" onClick={() => openUser(entry.clientId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>You dont have clients yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Business Clients</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={businessFilter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Segment>
		</Container>
	);
};


export default BusinessClients;
