import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Header, Divider, Container, Message } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './RegistrationIB.css';
import { Formik } from 'formik';
import { Form } from 'formik-semantic-ui-react';
import { signup, logout } from '@features/user/userSlice';
import { showSuccess, showException } from '@features/swal/slice';
import { SignupBody } from '@features/user/types';
import { getNames, alpha2ToAlpha3 } from 'i18n-iso-countries';
import { usePasswordValidation, useValidator } from './hooks';
import Compliance from './Parts/Compliance';
import RegisterButton from './Parts/RegisterButton';
import Links from './Parts/Links';
import CompanyInfo from './Parts/CompanyInfo';

const Company = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const [registrationError, setError] = useState<boolean>(false);
	const [toc, setToc] = useState<boolean>(false);
	const [aml, setAml] = useState<boolean>(false);
	const [passwordErrors, validatePassword] = usePasswordValidation();
	const businessValidator = useValidator('businnes');
	const contriesList = getNames('en');
	const [serviceAllowed, setServiceAllowed] = useState<boolean>(false);

	const initialValues = {
		email: '',
		password: '',
		passwordConfirm: '',
		companyName: '',
		companyRegNumber: '',
		companyAddress: '',
		companyCountry: '',
		phoneNumber: '',

	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setError(false);
		try {
			const { email, password, phoneNumber, companyAddress, companyRegNumber, companyName, companyCountry } = formData;
			const countyCode = alpha2ToAlpha3(companyCountry);
			const payload: SignupBody = {
				businessOwnerAccount: true,
				email,
				password,
				companyName,
				companyRegNumber,
				companyAddress,
				companyCountry: countyCode,
				mobileNumber: `+${phoneNumber}`
			};
			await dispatch(logout());
			await signup(payload);
			showSuccess({ title: 'swal.USER_REGISTERED', text: 'swal.USER_REGISTERED_TEXT' });
			history.push('/auth');
		}
		catch (e) {
			const { data, status } = e;
			if (status === 417) {
				const { errors } = data;
				const duplicatePresent = errors.find(error => error.error === 'duplicate' && error.error_param === 'email');
				if (duplicatePresent) {
					showException({
						data: duplicatePresent,
						status: 417
					}, [{
						key: 'duplicate',
						title: 'Error',
						message: 'An account with your provided email has already been created'
					}]);
				}
			} else {
				showException(e, [{
					key: 'registration_disabled',
					title: 'swal.REGISTRATION_DISABLED_TITLE',
					message: 'swal.REGISTRATION_DISABLED_TEXT'
				}]);
			}
			setSubmitting(false);
		}
	};

	return (
		<Container>
			<Formik
				initialValues={initialValues}
				validationSchema={businessValidator}
				onSubmit={submit}
			>{({ errors, isSubmitting, dirty }) => (
				<Form error={registrationError}>
					<Header className="aboutyoutitle" as="h3">{t('registration.businesstitle')}</Header>
					<Divider hidden />
					<CompanyInfo contriesList={contriesList} validatePassword={validatePassword} passwordErrors={passwordErrors} dirty={dirty} setServiceAllowed={setServiceAllowed} />
					<Header className="checkboxheader" size="medium">{t('registration.compliancelabel')}</Header>
					<Compliance toc={toc} setToc={setToc} aml={aml} setAml={setAml} />
					{registrationError && (<Message
						error
						header='Action Forbidden'
						content={registrationError} />)}
					<div>
						<RegisterButton
							isSubmitting={isSubmitting}
							toc={toc}
							aml={aml}
							dirty={dirty}
							errors={errors}
							serviceAllowed={serviceAllowed}
						/>
					</div>
					<Divider hidden />
					<Links />
				</Form>
			)}
			</Formik>
		</Container >
	);
};

export default Company;
